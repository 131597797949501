"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgGps = (props) => (React.createElement("svg", Object.assign({ width: 22, height: 22, viewBox: "0 0 22 22" }, props),
    React.createElement("defs", null,
        React.createElement("path", { d: "M11 7c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0012 2.06V0h-2v2.06A8.994 8.994 0 002.06 10H0v2h2.06A8.994 8.994 0 0010 19.94V22h2v-2.06A8.994 8.994 0 0019.94 12H22v-2h-2.06zM11 18c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z", id: "gps_svg__a" })),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("path", { d: "M11 7c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0012 2.06V0h-2v2.06A8.994 8.994 0 002.06 10H0v2h2.06A8.994 8.994 0 0010 19.94V22h2v-2.06A8.994 8.994 0 0019.94 12H22v-2h-2.06zM11 18c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z", fill: "#000", fillRule: "nonzero" }))));
exports.default = SvgGps;
