"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Entity = void 0;
var Entity;
(function (Entity) {
    Entity["SND_COMPANY"] = "SndCompany";
    Entity["SND_DRIVER"] = "SndDriver";
    Entity["USERS"] = "Users";
    Entity["REGION"] = "Region";
})(Entity = exports.Entity || (exports.Entity = {}));
