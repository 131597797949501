import styled from 'styled-components';
import { FlexView } from '@adac/core-view';

export const TimerStyles = styled(FlexView)`
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
  * {
    font-family: ${p => p.theme.font.face.slab};
    font-weight: normal;
  }
  big {
    font-family: ${p => p.theme.font.face.bold.slab};
  }
`;
