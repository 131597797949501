"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgMail = (props) => (React.createElement("svg", Object.assign({ width: 20, height: 14, viewBox: "0 0 20 14" }, props),
    React.createElement("path", { d: "M19.167 2.011l-8.768 5.142L.833 2.011v-.647c0-.57.473-1.03 1.059-1.03h16.214c.586 0 1.06.46 1.06 1.03v.647zm0 1.656v8.969c0 .57-.475 1.03-1.06 1.03H1.891c-.586 0-1.059-.46-1.059-1.03v-8.97l9.59 5 8.744-5z", fill: "#191919", fillRule: "evenodd" })));
exports.default = SvgMail;
