"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const __1 = require("..");
function useCollectCityNamesForZip({ zip, currentCity }) {
    const [places, setPlaces] = (0, react_1.useState)([currentCity]);
    (0, react_1.useEffect)(() => {
        function isZipValid() {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                const res = yield (0, __1.isZipSupported)(zip);
                setPlaces((res.cityname || [])
                    .concat(((_a = res === null || res === void 0 ? void 0 : res.cityname) === null || _a === void 0 ? void 0 : _a.find(city => city === currentCity)) ? [] : currentCity));
            });
        }
        if ((zip === null || zip === void 0 ? void 0 : zip.length) > 0) {
            isZipValid();
        }
    }, [zip, currentCity]);
    return [places, setPlaces];
}
exports.default = useCollectCityNamesForZip;
