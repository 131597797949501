"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PutStore = exports.PatchStore = exports.DeleteStore = exports.GetStore = exports.PostStore = exports.SingleItemActionStore = exports.GetAllStore = void 0;
const mobx_1 = require("mobx");
const StatefulNetworkingStore_1 = require("./StatefulNetworkingStore");
class GetAllStore extends StatefulNetworkingStore_1.DefaultStatefulNetworkingStore {
    constructor(getAll) {
        super();
        this.items = [];
        this.getAll = getAll;
    }
    getAllItems(param) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            this.items = [];
            try {
                this.items = yield this.getAll(param);
                this.loading = false;
            }
            catch (error) {
                this.errors = [error];
            }
            return this.items;
        });
    }
}
__decorate([
    mobx_1.observable
], GetAllStore.prototype, "items", void 0);
__decorate([
    mobx_1.action
], GetAllStore.prototype, "getAllItems", null);
exports.GetAllStore = GetAllStore;
class SingleItemActionStore extends StatefulNetworkingStore_1.DefaultStatefulNetworkingStore {
    constructor(func) {
        super();
        this.item = null;
        this.func = func;
    }
    get response() {
        return this.item;
    }
    setItem(item) {
        this.item = item;
    }
    action(param, options) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.item = yield this.func(param, options);
                this.loading = false;
            }
            catch (error) {
                this.errors = [error];
            }
            return this.item;
        });
    }
}
__decorate([
    mobx_1.observable
], SingleItemActionStore.prototype, "item", void 0);
__decorate([
    mobx_1.computed
], SingleItemActionStore.prototype, "response", null);
__decorate([
    mobx_1.action
], SingleItemActionStore.prototype, "setItem", null);
__decorate([
    mobx_1.action
], SingleItemActionStore.prototype, "action", null);
exports.SingleItemActionStore = SingleItemActionStore;
class PostStore extends StatefulNetworkingStore_1.DefaultStatefulNetworkingStore {
    constructor(post) {
        super();
        this.item = null;
        this.post = post;
    }
    postItem(param, data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.item = yield this.post(param, data);
                this.loading = false;
            }
            catch (error) {
                this.errors = [error];
            }
            return this.item;
        });
    }
}
__decorate([
    mobx_1.observable
], PostStore.prototype, "item", void 0);
__decorate([
    mobx_1.action
], PostStore.prototype, "postItem", null);
exports.PostStore = PostStore;
class GetStore extends StatefulNetworkingStore_1.DefaultStatefulNetworkingStore {
    constructor(get) {
        super();
        this.item = null;
        this.get = get;
    }
    getItem(item) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getItemById(item.id);
        });
    }
    getItemById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.item = yield this.get(id);
                this.loading = false;
            }
            catch (error) {
                this.errors = [error];
            }
            return this.item;
        });
    }
}
__decorate([
    mobx_1.observable
], GetStore.prototype, "item", void 0);
__decorate([
    mobx_1.action
], GetStore.prototype, "getItem", null);
__decorate([
    mobx_1.action
], GetStore.prototype, "getItemById", null);
exports.GetStore = GetStore;
class DeleteStore extends StatefulNetworkingStore_1.DefaultStatefulNetworkingStore {
    constructor(deleteAction) {
        super();
        this.item = null;
        this.deleteAction = deleteAction;
    }
    deleteActionItem(item) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.item = yield this.deleteAction(item.id);
                this.loading = false;
            }
            catch (error) {
                this.errors = [error];
            }
            return this.item;
        });
    }
}
__decorate([
    mobx_1.observable
], DeleteStore.prototype, "item", void 0);
__decorate([
    mobx_1.action
], DeleteStore.prototype, "deleteActionItem", null);
exports.DeleteStore = DeleteStore;
class PatchStore extends StatefulNetworkingStore_1.DefaultStatefulNetworkingStore {
    constructor(patch) {
        super();
        this.item = null;
        this.patch = patch;
    }
    patchItem(item) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.item = yield this.patch(item.id);
                this.loading = false;
            }
            catch (error) {
                this.errors = [error];
            }
        });
    }
}
__decorate([
    mobx_1.observable
], PatchStore.prototype, "item", void 0);
__decorate([
    mobx_1.action
], PatchStore.prototype, "patchItem", null);
exports.PatchStore = PatchStore;
class PutStore extends StatefulNetworkingStore_1.DefaultStatefulNetworkingStore {
    constructor(put) {
        super();
        this.item = null;
        this.put = put;
    }
    putItem(item) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.item = yield this.put(item.id);
                this.loading = false;
            }
            catch (error) {
                this.errors = [error];
            }
        });
    }
}
__decorate([
    mobx_1.observable
], PutStore.prototype, "item", void 0);
__decorate([
    mobx_1.action
], PutStore.prototype, "putItem", null);
exports.PutStore = PutStore;
