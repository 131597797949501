"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useAutoFillCityname({ callback, cityname }) {
    (0, react_1.useEffect)(() => {
        if (cityname) {
            callback(cityname);
        }
    }, [callback, cityname]);
}
exports.default = useAutoFillCityname;
