"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERROR_CODE_SCOPE_MAX = exports.SERVICE_UNAVAILABLE_CODE = exports.SERVER_ERROR_CODE = exports.VERSION_MISMATCH_ERROR_CODE = exports.VALIDATION_ERROR_CODE = exports.PAGE_NOT_FOUND_CODE = exports.FORBIDDEN_ERROR_CODE = exports.UNAUTHORIZED_ERROR_CODE = exports.BAD_REQUEST_CODE = exports.HTTP_CREATED_CODE = void 0;
exports.HTTP_CREATED_CODE = 201;
exports.BAD_REQUEST_CODE = 400;
exports.UNAUTHORIZED_ERROR_CODE = 401;
exports.FORBIDDEN_ERROR_CODE = 403;
exports.PAGE_NOT_FOUND_CODE = 404;
exports.VALIDATION_ERROR_CODE = 422;
exports.VERSION_MISMATCH_ERROR_CODE = 412;
exports.SERVER_ERROR_CODE = 500;
exports.SERVICE_UNAVAILABLE_CODE = 503;
exports.ERROR_CODE_SCOPE_MAX = 600;
