import React from 'react';
import styled from 'styled-components';
import {
  View, Carousel,
} from '@adac/core-view';
import ImageCarouselItem from './PhotoCarouselItem';
import DoorPhoto from './DoorPhoto';
import NewImage from './NewPhoto';
import NavigatorButton from './CarouselNavigatorButton';
import DoorPhotoStore from '../../../stores/DoorPhotoStore';

const CarouselStyles = styled(View)`
  width: 100%;
  margin: 16px 0;
`;

interface Props {
  doorStore: DoorPhotoStore;
  currentIndex?: number;
  onIndexChange: (index: number) => void;
  onDelete?: (index: number) => void;
  onFullScreen: (isFullScreen: boolean) => void;
}


const ImageCarousel = ({
  doorStore, currentIndex, onIndexChange, onDelete, onFullScreen,
}: Props) => (
  <CarouselStyles>
    <Carousel
      NavigatorButtonStyles={NavigatorButton}
      currentIndex={currentIndex}
      onIndexChange={onIndexChange}
    >
      {doorStore.photosUploadedByUser.map((photo, index) => (
        <ImageCarouselItem key={photo.url}>
          <DoorPhoto
            photo={photo}
            index={index+1}
            onDelete={onDelete && (() => onDelete(index))}
            onFullScreen={() => onFullScreen(true)}
          />
        </ImageCarouselItem>
      )).concat(
        <ImageCarouselItem key={doorStore.photosUploadedByUser.length}>
          <NewImage doorStore={doorStore} />
        </ImageCarouselItem>,
      )}
    </Carousel>
  </CarouselStyles>
);


export default ImageCarousel;
