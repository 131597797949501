"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Flex = exports.FlexFragment = exports.Fragment = void 0;
const styled_components_1 = __importStar(require("styled-components"));
exports.Fragment = styled_components_1.default.div `
  padding: ${props => (props.padding === true ? props.theme.spacing.basicPadding : props.padding) || 0};
  margin: ${props => (props.margin === true ? props.theme.spacing.basicMargin : props.margin) || 0};
  transition: all 0.5 ease;


  ${props => props.color && (0, styled_components_1.css) `
    color: ${props.theme.colors[props.color] || props.color};
  `};
  ${props => props.tiny && (0, styled_components_1.css) `
    font-size: ${props.theme.font.tinyText} !important;
  `};
  ${props => props.small && (0, styled_components_1.css) `
    font-size: ${props.theme.font.smallText} !important;
  `};
  ${props => props.subTitle && (0, styled_components_1.css) `
    font-size: ${props.theme.font.subTitle} !important;
  `};
  ${props => props.title && (0, styled_components_1.css) `
    font-size: ${props.theme.font.title} !important;
  `};
  ${props => props.text && (0, styled_components_1.css) `
  font-size: ${props.theme.font.text} !important;
  `};
  ${props => props.bold && (0, styled_components_1.css) `
    font-weight: ${props.bold === true ? 'bold' : props.bold};
    font-family: ${props.theme.font.face.bold.default};
  `};
  ${props => props.normal && (0, styled_components_1.css) `
    font-weight: normal;
  `};

  ${props => props.marginLeft && (0, styled_components_1.css) `
    margin-left: ${props.marginLeft};
  `};
  ${props => props.marginRight && (0, styled_components_1.css) `
    margin-right: ${props.marginRight};
  `};
  ${props => props.marginTop && (0, styled_components_1.css) `
    margin-top: ${props.marginTop};
  `};
  ${props => props.marginBottom && (0, styled_components_1.css) `
    margin-bottom: ${props.marginBottom};
  `};

  ${props => props.paddingTop && (0, styled_components_1.css) `
    padding-top: ${props.paddingTop};
  `};
  ${props => props.paddingBottom && (0, styled_components_1.css) `
    padding-bottom: ${props.paddingBottom};
  `};
  ${props => props.paddingLeft && (0, styled_components_1.css) `
    padding-left: ${props.paddingLeft};
  `};
  ${props => props.paddingRight && (0, styled_components_1.css) `
    padding-left: ${props.paddingLeft};
  `};

  ${props => props.background && (0, styled_components_1.css) `
    background: ${props.theme.colors[props.background] || props.background};
  `};

  ${props => props.hidden && (0, styled_components_1.css) `
    display: none;
  `};
  ${props => props.overflow && (0, styled_components_1.css) `
    overflow: ${props.overflow};
  `};

  ${props => props.width && (0, styled_components_1.css) `
    width: ${props.width};
  `};
  ${props => props.fullWidth && (0, styled_components_1.css) `
    width: 100%;
  `};
  ${props => props.fullHeight && (0, styled_components_1.css) `
    height: 100%;
  `};
  ${props => (props.autoHeight || props.auto) && (0, styled_components_1.css) `
    height: auto;
  `};
  ${props => props.height && (0, styled_components_1.css) `
    height: ${`${props.height}`.match(/[a-z]+$/gi) ? props.height : `${props.height}px`};
  `};

  ${props => props.border && (0, styled_components_1.css) `
    border: ${`solid 1px ${(props.border !== true && props.border) || 'lightgray'}`};
  `};
  ${props => props.round && (0, styled_components_1.css) `
    border-radius: ${(props.round !== true && props.round) || '1em'};
  `};
  ${props => props.underline && (0, styled_components_1.css) `
    text-decoration: underline;
  `};
  ${props => props.compact === false && (0, styled_components_1.css) `
    line-height: initial;
  `}
  ${props => props.compact && (0, styled_components_1.css) `
  line-height: 0;

  & > figure {
    line-height: 0;
  }

  & > *, p, h1, h2, h3, h4, h5, h6 {
    line-height: normal;
  }
  `};
  ${props => props.nowrap && (0, styled_components_1.css) `
    white-space: ${(props.round !== true && props.round) || 'nowrap'};
  `};

`;
exports.Fragment.displayName = 'Fragment';
exports.FlexFragment = (0, styled_components_1.default)(exports.Fragment) `
  flex: 1 1 auto;
  display: flex;

  ${props => props.row && (0, styled_components_1.css) `
    flex-direction: row;
  `}
  ${props => props.column && (0, styled_components_1.css) `
  flex-direction: column;
  `}
  ${props => props.center && (0, styled_components_1.css) `
    align-items: center;
    justify-content: center;
  `};
  ${props => props.between && (0, styled_components_1.css) `
    justify-content: space-between;
  `};
  ${props => (props.left || props.start) && (0, styled_components_1.css) `
    align-items: center;
    justify-content: flex-start;
    text-align: left !important;
  `};
  ${props => (props.right || props.end) && (0, styled_components_1.css) `
    align-items: center;
    justify-content: flex-end;
    text-align: right !important;
  `};
  ${props => props.wrap && (0, styled_components_1.css) `
    flex-wrap: wrap;

    & > * {
      flex: 0 1 ${props.wrapWidth || 'auto'};
      ${props.wrapWidth && (0, styled_components_1.css) `width: ${props.wrapWidth};`}
    }
  `}
  ${''}
`;
exports.FlexFragment.displayName = 'FlexFragment';
exports.default = exports.Fragment;
exports.Flex = exports.FlexFragment;
