/* eslint-disable no-return-await */
import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';

import {
  Page, SubPage, Title, Text, SubTitle, SignatureView, Button,
  ButtonListStyles, useLocation, UserInfo, useAsyncComponent, Timer, InfoItem,
  useAsyncAction, useReportRequest, Spinner, ApiDataLoaded, ProductType,
} from '@adac/core-view';
import {
  CaseActions, CaseReportDTO, __, formatDate, request,
} from '@adac/core-model';

import StoreContext from '../../stores';
import { routeNames } from '../layout/OverlayRouterContainer';
import { TimerStyles } from './TimerStyles';
import Door from './Door';
import PhotoList from '../composite/PhotoList';
import { useWorkflowDriverAction } from '../../hooks/useWorkflowDriverAction';


const CommissionExtraDamage = observer(() => {
  const doorStore = useContext(StoreContext).beforeExtraDamageStarting;
  const { ui: uiStore, case: caseStore } = useContext(StoreContext);
  const [signatureData, setSignatureData] = useState('');
  const { goBack, goToWithRootId } = useLocation();

  const { requestUrl, isView } = useReportRequest(caseStore.token, caseStore.currentCompanyId);
  const { hasLoaded, resource, isLoading } = useAsyncAction(async () => await request<CaseReportDTO>(requestUrl), [requestUrl]);
  const { extraDamageSignature, extraDamageExtraCost, case: caseObj } = resource || {};

  const extraDamageAction = useWorkflowDriverAction(CaseActions.COMISSION_EXTRA_DAMAGE);

  const onNext = async () => {
    await extraDamageAction({
      signature: signatureData,
      photos: doorStore.photosForPost,
    });
    goToWithRootId(routeNames.comissionAdditional());
  };
  const user = {
    title: caseStore.title,
    firstName: caseStore.customerFirstName,
    lastName: caseStore.customerFamilyName,
    address: caseStore.customerFullAddressString,
  };

  const { Component: ConfirmExtraDamageButton } = useAsyncComponent({
    onClick: onNext,
    disabled: !(!!signatureData && doorStore.photosUploadedByUser.length > 0),
  });

  if (isLoading) {
    return (
      <Page>
        <SubPage>
          <Spinner />
        </SubPage>
      </Page>
    );
  }

  if (!resource) return null;

  return (
    <Page>
      <SubPage>
        <ApiDataLoaded hasLoaded={hasLoaded} />
        {!isView && (
          <TimerStyles>
            <Timer
              startTime={caseStore.getOpeningStartedTime}
              countdown={false}
            >
              <span>{__('In Arbeit')}:&nbsp;</span>
            </Timer>
          </TimerStyles>
        )}

        <Title>{__('Not possible without damage')}</Title>


        {isView && <UserInfo {...user} />}

        <ProductType productType={caseObj?.productType} productNumber={caseObj?.productNumber} damageNumber={caseStore.damageNumber} />

        <InfoItem header="Datum" info={formatDate(new Date())} />

        <SubTitle margin>{__('Photo of door extra damage')}</SubTitle>
        {isView ? <PhotoList doorStore={doorStore} reportPhotos={extraDamageExtraCost && extraDamageExtraCost.photos} /> : <Door doorStore={doorStore} />}

        <SubTitle margin>{__('Signature')}</SubTitle>
        <Text>{__('ConfirmDamage')}</Text>
      </SubPage>

      <SignatureView
        onSignatureChange={setSignatureData}
        uiStore={uiStore}
        signatureData={isView ? extraDamageSignature : undefined}
      />

      {
        !isView && (
          <SubPage>
            <ButtonListStyles>
              <Button info back title={__('Back')} onClick={() => goBack()} />

              <ConfirmExtraDamageButton cta title={__('Next')} />
            </ButtonListStyles>
          </SubPage>
        )
      }

    </Page>
  );
});
export default CommissionExtraDamage;
