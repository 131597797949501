"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mainConfig = exports.mainTheme = void 0;
exports.mainTheme = {
    colors: {},
    font: {
        face: {
            bold: {},
        },
    },
    animation: {},
    spacing: {},
    effects: {},
};
exports.mainConfig = {
    devices: {
        mobileMaxWidth: '400px',
        tabletMaxWidth: '900px',
        desktopWideScreen: '1120px',
    },
    layout: {
        navBarHeight: '60px',
        tabBarHeight: '0px',
    },
};
exports.default = Object.assign(Object.assign(Object.assign({}, exports.mainConfig), exports.mainTheme), { all: {
        main: exports.mainTheme,
    } });
