"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allValidSmsCodes = exports.SmsCodes = void 0;
var SmsCodes;
(function (SmsCodes) {
    SmsCodes["START_MESSAGE"] = "001";
    SmsCodes["SND_NEW_JOB"] = "003";
    SmsCodes["SND_JOB_ALLOCATION"] = "004";
    SmsCodes["DRIVER_ARRIVING"] = "005";
    SmsCodes["FINAL_REPORT_READY"] = "006";
    SmsCodes["SND_JOB_CANCELED"] = "007";
    SmsCodes["CUSTOMER_JOB_CANCELED_NO_DOCUMENT"] = "012";
    SmsCodes["CUSTOMER_JOB_CANCELED_WITH_DOCUMENT"] = "013";
    SmsCodes["OPENING_REPORT_READY"] = "008";
    SmsCodes["EXTRA_DAMAGE_REPORT_READY"] = "009";
    SmsCodes["OPENING_FAILED"] = "010";
    SmsCodes["NO_COMPANY_FOR_CASE"] = "011";
    SmsCodes["EMAIL_REPORT_SUBJECT"] = "021";
    SmsCodes["EMAIL_REPORT_BODY"] = "022";
    SmsCodes["ADAC_EMAIL_REPORT_BODY"] = "023";
    SmsCodes["ADAC_EMAIL_REPORT_SUBJECT"] = "024";
    SmsCodes["ADAC_EMAIL_REPORT_SUBJECT_TO_PREMIUM_MEMBER"] = "025";
    SmsCodes["ADAC_EMAIL_REPORT_SUBJECT_TO_STANDARD_MEMBER"] = "026";
    SmsCodes["ADAC_EMAIL_REPORT_SUBJECT_TO_INSURANCE_MEMBER"] = "027";
    SmsCodes["SMS_INVOICING_INFO"] = "031";
    SmsCodes["SMS_INVOICE_REMINDER"] = "041";
    SmsCodes["SMS_MFA_LOGIN"] = "051";
    SmsCodes["SMS_MFA_PWD_RESET"] = "052";
    SmsCodes["SMS_MFA_PWD_CHANGE"] = "053";
})(SmsCodes = exports.SmsCodes || (exports.SmsCodes = {}));
exports.allValidSmsCodes = Object.values(SmsCodes);
