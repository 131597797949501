"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useView = exports.ViewProvider = void 0;
const react_1 = __importStar(require("react"));
const router_helper_1 = require("../helpers/router-helper");
const ViewContext = (0, react_1.createContext)({ isView: false, viewType: undefined });
const ViewProvider = ({ children }) => {
    var _a;
    const { pathname } = (0, router_helper_1.useLocation)();
    const viewType = (_a = pathname.match(/(opening)|(final)|(breakdoor)/gi)) === null || _a === void 0 ? void 0 : _a[0];
    const isView = !!pathname.match(/\/view/gi) && !!viewType;
    return (react_1.default.createElement(ViewContext.Provider, { value: { isView, viewType } }, children));
};
exports.ViewProvider = ViewProvider;
const useView = () => {
    const { isView, viewType } = (0, react_1.useContext)(ViewContext);
    return { isView, viewType };
};
exports.useView = useView;
