"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var DOOR_PHOTO_SELECTOR = 'doorPhoto';
var REPORT_DATA_SELECTOR = 'apiDataLoaded';
var SIGNATURE_DATA_SELECTOR = 'signaturePhoto';
var getFinalReportSelectors = function (props) { return (__assign(__assign({ REPORT_DATA_SELECTOR: REPORT_DATA_SELECTOR }, (((props === null || props === void 0 ? void 0 : props.photos) && (props === null || props === void 0 ? void 0 : props.photos.length) > 0) ? { DOOR_PHOTO_SELECTOR: DOOR_PHOTO_SELECTOR } : {})), ((props === null || props === void 0 ? void 0 : props.signature) ? { SIGNATURE_DATA_SELECTOR: SIGNATURE_DATA_SELECTOR } : {}))); };
var getExtraDamageSelectors = function () { return ({
    REPORT_DATA_SELECTOR: REPORT_DATA_SELECTOR,
    SIGNATURE_DATA_SELECTOR: SIGNATURE_DATA_SELECTOR,
}); };
var getComissionSelectors = function (props) { return (__assign({ REPORT_DATA_SELECTOR: REPORT_DATA_SELECTOR, SIGNATURE_DATA_SELECTOR: SIGNATURE_DATA_SELECTOR }, (((props === null || props === void 0 ? void 0 : props.photos) && (props === null || props === void 0 ? void 0 : props.photos.length) > 0) ? { DOOR_PHOTO_SELECTOR: DOOR_PHOTO_SELECTOR } : {}))); };
var reportSelectors = {
    getFinalReportSelectors: getFinalReportSelectors,
    getExtraDamageSelectors: getExtraDamageSelectors,
    getComissionSelectors: getComissionSelectors,
    DOOR_PHOTO_SELECTOR: DOOR_PHOTO_SELECTOR,
    REPORT_DATA_SELECTOR: REPORT_DATA_SELECTOR,
    SIGNATURE_DATA_SELECTOR: SIGNATURE_DATA_SELECTOR,
};
exports.default = reportSelectors;
