"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgCar = (props) => (React.createElement("svg", Object.assign({ viewBox: "0 0 452.001 452.001" }, props),
    React.createElement("g", null,
        React.createElement("g", null,
            React.createElement("g", null,
                React.createElement("path", { d: "M85.44,261.093c-20.734,0-37.542,16.808-37.542,37.542s16.808,37.542,37.542,37.542\n\t\t\t\tc20.724-0.023,37.519-16.818,37.542-37.542C122.982,277.901,106.174,261.093,85.44,261.093z" }),
                React.createElement("polygon", { points: "360.94,336.177 360.939,336.177 360.939,336.177 \t\t\t" }),
                React.createElement("path", { d: "M437.143,212.893l-41.844-19.1c-1.426-0.633-2.633-1.675-3.469-2.993L355.57,133\n\t\t\t\tc-6.753-10.781-18.618-17.283-31.339-17.174H176.88c-11.504-0.035-22.368,5.288-29.39,14.4l-46.631,61.09\n\t\t\t\tc-1.043,1.396-2.52,2.406-4.2,2.87l-77.934,21.326c-11.13,3.099-18.802,13.271-18.724,24.824v28.5\n\t\t\t\tc0,14.188,11.089,25.988,25.279,25.988h6.789c2.001-26.366,22.955-47.32,49.321-49.321c29.476-2.237,55.185,19.844,57.422,49.321\n\t\t\t\th168.756c2.001-26.366,22.955-47.319,49.321-49.321c29.476-2.237,55.185,19.844,57.422,49.321h12.411\n\t\t\t\tc14.19,0,25.279-11.8,25.279-25.988v-32.534C452.081,226.256,446.267,217.096,437.143,212.893z" }),
                React.createElement("path", { d: "M360.94,261.093c-20.734,0-37.542,16.808-37.542,37.542c0,20.734,16.808,37.542,37.541,37.542\n\t\t\t\tc20.724-0.023,37.519-16.818,37.543-37.542C398.482,277.901,381.673,261.093,360.94,261.093z" })))),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null),
    React.createElement("g", null)));
exports.default = SvgCar;
