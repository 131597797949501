"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Dispatcher = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 682.667, height: 682.667, viewBox: "0 0 512 512" }, props),
    React.createElement("path", { d: "M235 1.5C185.9 8.7 151 38 140 81c-3.1 12.1-4 23-4 47.1 0 19-.3 23.3-1.7 26.5-2.6 6-2.3 21.4.5 27.9 1.7 4.2 2.1 7.4 2.4 20 .4 13.9.6 15.4 3.1 20.6 3.2 6.7 10.9 14.2 17.4 17.1 3.1 1.4 7.7 2.2 13.4 2.6l8.7.5 8.9 8.6c9.4 9.2 16 13.6 27 18 26.2 10.6 59.7 9.9 85-1.9 24.9-11.5 41.8-34.7 45.6-62.6l1.1-8.2 7.6-.4c11.8-.7 18.2-5 22.6-15.4 2.4-5.4 2.5-20.3.2-26.4-1.4-3.7-1.7-9.5-2.1-32.5-.5-29.1-1.2-35.1-5.9-49-10.8-32.6-38.1-57.9-73.3-67.9C278 .3 253.8-1.3 235 1.5zm49.2 21.4c35.9 8.2 60.5 31.2 68.9 64.3 1.7 6.7 2.2 12.5 2.6 30l.5 21.8h-7.9l-.6-13.3c-1.2-26-6.8-41.6-20.6-56.8-33.9-37.6-108.3-37.6-142.2 0-13.8 15.2-19.4 30.8-20.6 56.8l-.6 13.3h-7.9l.5-21.8c.4-17.5.9-23.3 2.6-30 9.1-36 37.2-59.7 78.6-66.2 9.8-1.5 36.6-.4 46.7 1.9zm33.9 109.7c2.3 2.3 5.5 4.8 7.2 5.5l2.9 1.2-.5 31.6c-.5 35.2-1 38.6-7.5 52.1-4.3 8.9-14.7 20-23.2 24.8-22.8 12.8-59.3 12.9-81.7.1-2.9-1.7-5.5-3.4-5.8-4-.4-.5 4.6-.9 12.4-.9 8.5 0 13.1.4 13.1 1.1 0 .5 1.6 1.7 3.5 2.5 4.2 1.7 29.5 2 34.4.3 4.4-1.5 6.1-5.4 6.1-13.9 0-13.8-2.6-15.3-25.2-14.8-13.3.3-14.9.5-17.7 2.5-2.9 2.2-3.8 2.3-23.9 2.3h-21l-2.1-5.8c-4-10.9-4.3-14.4-4.8-45.3l-.5-30.6 24.4-.6c13.7-.3 28.6-1.2 34.3-2.1 25.7-3.9 45.9-12 56.3-22.4l5.7-5.8 4.7 8.9c2.5 4.9 6.5 10.9 8.9 13.3zm-152.7 70.7c.3 3.5 1.5 9.3 2.5 13 2.1 7.5 1.5 8.1-4 4.4-5-3.3-6.9-7.9-6.9-16.5V197H164.7l.7 6.3zM201.5 311.6c-6 3.6-11.6 6.9-12.4 7.3-1.2.7 1.2 7.5 11.9 35l13.4 34.2 20.8-20.8 20.8-20.8 20.8 20.8 20.8 20.8 13.3-34.1c7.4-18.7 13.2-34.2 13-34.4-1.1-1-24.9-14.6-25.6-14.6-.4 0-10.1 9.3-21.5 20.7L256 346.5l-20.8-20.8c-11.4-11.4-21.2-20.7-21.7-20.6-.6 0-5.9 2.9-12 6.5z" }),
    React.createElement("path", { d: "M139 338.1c-30.7 3.9-57.1 19.4-73.2 43.2-8.6 12.7-14.6 29-16.8 45.7-.5 4.1-1 24.9-1 46.3V512l81.6-.2 81.7-.3 12.3-50c6.8-27.5 12.3-51 12.4-52.2 0-1.9-5-15.9-6.7-18.6-.3-.5-4.8 3.3-9.9 8.4l-9.4 9.3-1-2.9c-.6-1.7-6.8-17.6-13.8-35.5l-12.8-32.5-18.5-.1c-10.1-.1-21.3.2-24.9.7zM329.7 337.6c-.4.7-22.7 57.3-26.7 67.8l-1.1 3-9.3-9.3c-5.2-5.1-9.6-8.9-9.9-8.4-1.7 2.7-6.7 16.7-6.7 18.6.1 1.2 5.6 24.7 12.4 52.2l12.3 50 81.7.3 81.6.2v-38.7c0-21.4-.5-42.2-1-46.3-5.9-45.3-36.5-78.2-81.5-87.7-8-1.6-50.9-3.1-51.8-1.7zM246.7 371.8l-8.8 8.8 4.3 11.2 4.3 11.2h19l4.3-11.2 4.3-11.2-8.8-8.8c-4.8-4.8-9-8.8-9.3-8.8-.3 0-4.5 4-9.3 8.8zM246.1 417.7c-1.4 5.3-23.1 93.4-23.1 93.9 0 .2 14.9.4 33 .4 18.2 0 33-.2 33-.4 0-.4-21.7-88.6-23.1-93.9l-1-3.7h-17.8l-1 3.7z" })));
exports.default = Dispatcher;
