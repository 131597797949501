"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgTwitter = (props) => (React.createElement("svg", Object.assign({ width: 18, height: 15, viewBox: "0 0 18 15" }, props),
    React.createElement("path", { d: "M18 1.736a7.93 7.93 0 01-1.85 1.907c.011.16.011.32.011.48C16.161 9 12.45 14.62 5.665 14.62c-2.09 0-4.032-.605-5.665-1.656.297.034.582.046.89.046a7.388 7.388 0 004.58-1.576 3.696 3.696 0 01-3.448-2.559c.228.035.456.057.696.057a3.9 3.9 0 00.971-.125 3.69 3.69 0 01-2.958-3.62V5.14a3.716 3.716 0 001.667.468A3.686 3.686 0 01.754 2.536c0-.686.183-1.314.502-1.862a10.486 10.486 0 007.607 3.86 4.164 4.164 0 01-.091-.845A3.687 3.687 0 0112.46 0a3.68 3.68 0 012.695 1.165 7.263 7.263 0 002.341-.89 3.68 3.68 0 01-1.621 2.032A7.396 7.396 0 0018 1.736z", fill: "#191919", fillRule: "nonzero" })));
exports.default = SvgTwitter;
