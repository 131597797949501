"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReportRequest = void 0;
const core_model_1 = require("@adac/core-model");
const react_1 = require("react");
const ViewStore_1 = require("../stores/ViewStore");
const useReportRequest = (token, companyId) => {
    const { isView, viewType } = (0, ViewStore_1.useView)();
    const [requestUrl, setRequestUrl] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        if (isView && viewType) {
            setRequestUrl((0, core_model_1.getApiRoutes)().partner.case(token).report.view(viewType));
        }
        else if (companyId) {
            setRequestUrl((0, core_model_1.getApiRoutes)().partner.case(token).report.company(companyId));
        }
    }, [companyId, isView, token, viewType]);
    return {
        requestUrl,
        isView,
    };
};
exports.useReportRequest = useReportRequest;
