"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatStopwatch = exports.formatTime = void 0;
var formatTime = function (ms) {
    var time = {
        days: Math.floor(ms / 86400000),
        hours: Math.floor(ms / 3600000) % 24,
        minutes: Math.floor(ms / 60000) % 60,
        seconds: Math.floor(ms / 1000) % 60,
        milliseconds: Math.floor(ms) % 1000,
    };
    return time;
};
exports.formatTime = formatTime;
var formatStopwatch = function (time, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.hour, hour = _c === void 0 ? false : _c, _d = _b.minute, minute = _d === void 0 ? true : _d, _e = _b.second, second = _e === void 0 ? true : _e;
    if (time === null)
        return '';
    var hourString = (hour || time.hours || time.days) ? "".concat(time.hours + time.days * 24).padStart(2, '0') : '';
    var minuteString = (minute || time.minutes) ? "".concat(time.minutes).padStart(2, '0') : '';
    var secondString = (second || time.seconds) ? "".concat(time.seconds).padStart(2, '0') : '';
    return [hourString, minuteString, secondString]
        .filter(function (str) { return str; })
        .join(':');
};
exports.formatStopwatch = formatStopwatch;
