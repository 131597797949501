"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaseLogOwnerType = void 0;
var CaseLogOwnerType;
(function (CaseLogOwnerType) {
    CaseLogOwnerType["COMPANY"] = "company";
    CaseLogOwnerType["CUSTOMER"] = "customer";
    CaseLogOwnerType["BACKOFFICE"] = "backoffice";
    CaseLogOwnerType["SYSTEM"] = "system";
    CaseLogOwnerType["API"] = "api";
    CaseLogOwnerType["ANY"] = "any";
})(CaseLogOwnerType = exports.CaseLogOwnerType || (exports.CaseLogOwnerType = {}));
