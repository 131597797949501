"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationSettingsFormSchemaPartner = exports.NotificationSettingsFormSchema = void 0;
const core_model_1 = require("@adac/core-model");
const yup = __importStar(require("yup"));
exports.NotificationSettingsFormSchema = yup.object().shape({
    [core_model_1.NotificationSettingTypes.SND_NEW_JOB_DRIVER]: yup.string().matches(core_model_1.disabledMessageTypeRegexp),
    [core_model_1.NotificationSettingTypes.SND_NEW_JOB_DISPATCHER]: yup.string().matches(core_model_1.realMessageTypeRegexp).required(),
    [core_model_1.NotificationSettingTypes.SND_JOB_ALLOCATION_DRIVER]: yup.string().matches(core_model_1.realMessageTypeRegexp).required(),
    [core_model_1.NotificationSettingTypes.SND_JOB_ALLOCATION_DISPATCHER]: yup.string().matches(core_model_1.allMessageTypeRegexp).required(),
    [core_model_1.NotificationSettingTypes.SND_JOB_CANCELLATION_DRIVER]: yup.string().matches(core_model_1.realMessageTypeRegexp).required(),
    [core_model_1.NotificationSettingTypes.SND_JOB_CANCELLATION_DISPATCHER]: yup.string().matches(core_model_1.allMessageTypeRegexp).required(),
});
exports.NotificationSettingsFormSchemaPartner = exports.NotificationSettingsFormSchema.shape({
    [core_model_1.NotificationSettingTypes.SND_NEW_JOB_DRIVER]: yup.string().matches(core_model_1.disabledMessageTypeRegexp, { message: (0, core_model_1.__)('SND_NEW_JOB_DRIVER value is invalid') }),
    [core_model_1.NotificationSettingTypes.SND_NEW_JOB_DISPATCHER]: yup.string().matches(core_model_1.activePartnerMessageTypeRegexp, { message: (0, core_model_1.__)('SND_NEW_JOB_DISPATCHER value is invalid') }).required(),
    [core_model_1.NotificationSettingTypes.SND_JOB_ALLOCATION_DRIVER]: yup.string().matches(core_model_1.activePartnerMessageTypeRegexp, { message: (0, core_model_1.__)('SND_JOB_ALLOCATION_DRIVER value is invalid') }).required(),
    [core_model_1.NotificationSettingTypes.SND_JOB_ALLOCATION_DISPATCHER]: yup.string().matches(core_model_1.allPartnerMessageTypeRegexp, { message: (0, core_model_1.__)('SND_JOB_ALLOCATION_DISPATCHER value is invalid') }).required(),
    [core_model_1.NotificationSettingTypes.SND_JOB_CANCELLATION_DRIVER]: yup.string().matches(core_model_1.activePartnerMessageTypeRegexp, { message: (0, core_model_1.__)('SND_JOB_CANCELLATION_DRIVER value is invalid') }).required(),
    [core_model_1.NotificationSettingTypes.SND_JOB_CANCELLATION_DISPATCHER]: yup.string().matches(core_model_1.allPartnerMessageTypeRegexp, { message: (0, core_model_1.__)('SND_JOB_CANCELLATION_DISPATCHER value is invalid') }).required(),
});
