import React from 'react';
import {
  Page, Text, SubPage, Title, View, Button,
} from '@adac/core-view';
import styled from 'styled-components';
import { __ } from '@adac/core-model';

const LargeText = styled(Text)`
  font-size: ${props => props.theme.font.subTitle};
  margin-top: 8px;
`;

const ButtonContainer = styled(View)`
  margin: 24px auto;
`;

const DispatchToDriverAccepted = () => (
  <Page>
    <SubPage>
      <Title>{__('thankYouForYourInterest')}</Title>
      <LargeText>{__('weWillCompareTheTravelTimes')}</LargeText>

      <ButtonContainer>
        <Button cta title="Schließen" link="/" />
      </ButtonContainer>
    </SubPage>
  </Page>
);

export default DispatchToDriverAccepted;
