"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgHandwriting = (props) => (React.createElement("svg", Object.assign({ width: 20, height: 21, viewBox: "0 0 20 21" }, props),
    React.createElement("path", { fill: "#191919", d: "M1.167 16.234l1.012-4.059 3.035 3.044-4.047 1.015zm5.058-2.03L3.19 11.161l8.093-8.117 3.035 3.044-8.093 8.116zm9.105-9.131l-3.035-3.044L14.318 0l3.035 3.044-2.023 2.029zM.429 19.591a1.016 1.016 0 01-.244-1.414 1.01 1.01 0 011.41-.244l.19.134a3.842 3.842 0 004.878-.364l.219-.21a4.326 4.326 0 016.429.49 2.52 2.52 0 003.63.391l1.388-1.174c1.004-.849 2.314.698 1.312 1.545l-1.397 1.18c-.05.044-1.638.963-2.444 1.05a4.541 4.541 0 01-4.092-1.753 2.307 2.307 0 00-3.429-.261l-.218.209a5.86 5.86 0 01-7.442.556l-.19-.135z", fillRule: "evenodd" })));
exports.default = SvgHandwriting;
