"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgCloseFilled = (props) => (React.createElement("svg", Object.assign({ width: 18, height: 18, viewBox: "0 0 18 18" }, props),
    React.createElement("path", { fill: "#191919", d: "M8.95 7.536L5.414 4 4 5.414 7.536 8.95 4 12.485 5.414 13.9l3.536-3.535 3.535 3.535 1.414-1.414-3.535-3.535 3.535-3.536L12.485 4 8.95 7.536zM9 18A9 9 0 119 0a9 9 0 010 18z", fillRule: "evenodd" })));
exports.default = SvgCloseFilled;
