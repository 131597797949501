import React from 'react';
import styled from 'styled-components';

import { Button, Icon } from '@adac/core-view';

import { __ } from '@adac/core-model';
import PhotoHeader, { PhotoHeaderIconStyles, PhotoTitle } from './PhotoHeader';
import PhotoFooter, { FooterTitle, FooterDescription } from './PhotoFooter';

const Styles = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  bottom: 0;

  img {
    display: block;
    margin-left: 50%;
    transform: translateX(-50%);
    min-height: 100%;
    min-width: 100%;
  }
`;

interface Props {
  index: number;
  photoUrl: string;
  description?: string;
  onCloseClicked?: () => void;
}

export default ({
  index, photoUrl, description, onCloseClicked,
}: Props) => (
  <Styles>
    <PhotoHeader>
      <PhotoTitle>{`#${index}`}</PhotoTitle>
      <PhotoHeaderIconStyles>
        <Button onClick={onCloseClicked}>
          <Icon name="close" color="white" />
        </Button>
      </PhotoHeaderIconStyles>
    </PhotoHeader>
    <img src={photoUrl} alt="door" />
    {description && (
      <PhotoFooter>
        <FooterTitle>
          {__('damageOnPicture')}
          {index}
        </FooterTitle>
        <FooterDescription>
          {description}
        </FooterDescription>
      </PhotoFooter>
    )}
  </Styles>
);
