import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import {
  PageTransitionContainer, Page, StatusRender, findMatchingStatusComponent,
} from '@adac/core-view';
import {
  keyGenerator, CaseStatusList, CaseActions, __, getCaseActionName, ProductTypes,
  isCaseCancelled,
  isCaseWithdrawnByHermes,
} from '@adac/core-model';

import swal from 'sweetalert';
import StoresContext from '../../stores';

import ComissionOpeningForm from '../pages/ComissionOpeningForm';
import IncomingDriverJob from '../pages/IncomingDriverJob';
import DoorOpeningStarted from '../pages/DoorOpeningStarted';
import { CaseCompleted } from '../pages/CaseCompleted';
import AuthorizationRenderer from '../composite/AuthorizationRenderer';
import CommissionExtraCost from '../pages/CommissionExtraCost';
import { useStatusListeners } from '../../hooks/useStatusListener';
import IncomingJobToDispatch, { IncomingJobControls } from '../pages/IncomingJobToDispatch';
import { HubbleCommissionFeedbackRetry } from '../composite/HubbleCommissionFeedbackRetry';


interface RouterProps {
  afterElement?: JSX.Element;
}


const shouldNotifyStates: string[] = [
  CaseStatusList.SND_SELECTED,
  CaseStatusList.COMMISSION_CONFIRMED,
];

export default observer(({ afterElement }: RouterProps): JSX.Element => {
  const { case: caseStore, ui: uiStore } = useContext(StoresContext);

  // eslint-disable-next-line no-alert
  useStatusListeners(caseStore, async (data) => {
    const shouldNotify = shouldNotifyStates.includes(caseStore.statusData.event) && (data as any).event === getCaseActionName(CaseActions.UPDATE_CASE);
    if (shouldNotify) {
      swal({
        title: __('status:UPDATE_CASE'),
        text: __('Case update'),
        icon: 'warning',
        dangerMode: true,
      });
    }
  });


  const statusMessage = (() => {
    if (isCaseWithdrawnByHermes(caseStore.statusData.from)) return 'Case has been withdrawn';
    if (isCaseCancelled(caseStore.statusData.from)) return 'Case has been cancelled';
    if (caseStore.status === CaseStatusList.CASE_COMPLETED && caseStore.productType === ProductTypes.PREMIUM) return 'Job is closed successfully!';
    return 'Job is closed successfully with documents!';
  })();

  const routes: JSX.Element[] = [
    <StatusRender
      status={caseStore.status}
      onStatus={[
        CaseStatusList.SND_SELECTED,
      ]}
      render={<AuthorizationRenderer><IncomingJobToDispatch><IncomingJobControls /></IncomingJobToDispatch></AuthorizationRenderer>}
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[
        CaseStatusList.DRIVER_SELECTED,
      ]}
      render={<AuthorizationRenderer><IncomingJobToDispatch><HubbleCommissionFeedbackRetry><IncomingJobControls /></HubbleCommissionFeedbackRetry></IncomingJobToDispatch></AuthorizationRenderer>}
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[
        CaseStatusList.COMMISSION_CONFIRMED,
        CaseStatusList.DRIVER_APPROACHING,
      ]}
      render={<AuthorizationRenderer><IncomingDriverJob /></AuthorizationRenderer>}
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[
        CaseStatusList.DRIVER_ARRIVED,
      ]}
      render={<AuthorizationRenderer><ComissionOpeningForm /></AuthorizationRenderer>}
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[
        CaseStatusList.OPENING_STARTED,
      ]}
      render={<AuthorizationRenderer><DoorOpeningStarted /></AuthorizationRenderer>}
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[
        CaseStatusList.EDITING_EXTRA_DAMAGE,
        CaseStatusList.EXTRA_DAMAGE_ONGOING,
      ]}
      render={<AuthorizationRenderer><CommissionExtraCost /></AuthorizationRenderer>}
    />,
    <StatusRender
      status={caseStore.status}
      onStatus={[
        CaseStatusList.OPENING_COMPLETED,
        CaseStatusList.CASE_COMPLETED,
      ]}
      render={(
        <AuthorizationRenderer unauthorizedView={<CaseCompleted>{__('Job has already taken')}</CaseCompleted>}>
          <CaseCompleted>{__(statusMessage)}</CaseCompleted>
        </AuthorizationRenderer>
      )}
    />,
  ];

  // TODO: enable pagetransitions again
  const foundMatchingRoute = findMatchingStatusComponent(routes, caseStore.status);
  // const foundMatchingRoute = findMatchingRoute({ status: caseStore.status, routes });
  if (foundMatchingRoute && foundMatchingRoute.props.pageTransition) {
    // NOTE: The reason I don't use pageTranstion just a local reference, using the stores we might be able to have more control, and manage from other place
    uiStore.setPageTransition(foundMatchingRoute && foundMatchingRoute.props.pageTransition);
  } else {
    uiStore.setPageTransition();
  }

  return (
    <PageTransitionContainer
      pageTransition={uiStore.pageTransition}
      pageTransitionDelay={uiStore.pageTransitionDelay}
      routes={routes}
      transitionKey={caseStore.status}
      ContentStyle={Page}
    >
      <>
        {routes.map(RouteConfig => React.cloneElement(RouteConfig, { key: keyGenerator(RouteConfig.props, ['onStatus']) }))}
        {/* After elements */}
        {afterElement}
      </>
    </PageTransitionContainer>
  );
});
