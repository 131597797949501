"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.matchCron = exports.parseCron = void 0;
var cronSegmentPeriod = '((\\d+)-(\\d+))';
var cronSegmentNumber = '\\d+';
var cronSegmentAnything = '[*]';
var cronSegment = "(".concat(cronSegmentPeriod, "|").concat(cronSegmentNumber, "|").concat(cronSegmentAnything, ")");
var delimiter = '\\s*';
var segmentList = [
    'second',
    'minute',
    'hour',
    'dom',
    'month',
    'dow',
];
var segments = segmentList.map(function (key) { return "((?<".concat(key, ">").concat(cronSegment, ")").concat(delimiter, ")"); }).join('');
var optionalCronRegexHourDOW = new RegExp("^".concat(delimiter).concat(segments));
var parseCron = function (cronExpression) {
    var matched = "".concat(cronExpression).match(optionalCronRegexHourDOW);
    if (!matched || !matched.groups)
        return null;
    var groups = matched === null || matched === void 0 ? void 0 : matched.groups;
    return segmentList.reduce(function (result, key) {
        var _a;
        return (__assign(__assign({}, result), (_a = {}, _a[key] = groups[key] || ('*'), _a)));
    }, groups);
};
exports.parseCron = parseCron;
function matchesSegmentPeriod(segment, input) {
    var matcher = segment.match(new RegExp("^".concat(cronSegmentPeriod, "$")));
    return matcher && +matcher[2] <= input && input < +matcher[3];
}
function matchesSegmentNumber(segment, input) {
    var matcher = segment.match(new RegExp("^".concat(cronSegmentNumber, "$")));
    return matcher && +matcher[0] === input;
}
function matchesSegment(segment, input) {
    if (!segment)
        return false;
    if (matchesSegmentPeriod(segment, input))
        return true;
    if (matchesSegmentNumber(segment, input))
        return true;
    if (segment.match(new RegExp("^".concat(cronSegmentAnything, "$"))))
        return true;
    return false;
}
function getDateInput(date, type) {
    switch (type) {
        case 'dom': return date.getDate();
        case 'dow': return date.getDay();
        case 'month': return date.getMonth() + 1;
        case 'minute': return date.getMinutes();
        case 'hour': return date.getHours();
        default: return -1;
    }
}
function isParseResult(cronExpression) {
    return cronExpression ? Object.keys(cronExpression).filter(function (key) { return segmentList.includes(key); }).length === segmentList.length : false;
}
function matchCron(cronExpression, date) {
    if (date === void 0) { date = new Date(Date.now()); }
    var parsed = isParseResult(cronExpression) ? cronExpression : (0, exports.parseCron)(cronExpression);
    if (!parsed)
        return null;
    return Object.entries(parsed).reduce(function (matched, _a) {
        var segment = _a[0], value = _a[1];
        return matched && matchesSegment(value, getDateInput(date, segment));
    }, true);
}
exports.matchCron = matchCron;
