"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgBell = (props) => (React.createElement("svg", Object.assign({ width: 22, height: 25, viewBox: "0 0 22 25" }, props),
    React.createElement("path", { fill: "#191919", d: "M5.498 20.9h10.203a5.411 5.411 0 01-10.203 0zm5.5-20.9a6.757 6.757 0 016.757 6.757V11c0 1.522 1.255 3.475 3.87 5.774.76.67.287 1.926-.727 1.926h-19.8c-1.077 0-1.512-1.388-.628-2.003C3.027 14.918 4.241 13.029 4.241 11V6.757A6.757 6.757 0 0110.998 0z" })));
exports.default = SvgBell;
