"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCaseCompleted = exports.isCaseClosedByBackoffice = exports.isCaseCancelled = exports.isCaseRejectedOrIgnoredByPartner = exports.notActionedOrRejectedCaseActions = exports.isCaseWithdrawnByHermes = exports.isCaseCancelledByHermes = exports.cancelledCaseActions = exports.viewFinalReportStates = exports.draftCasesStates = exports.openCasesStates = exports.filesNotAvailableStates = exports.allowedStatesForClosing = exports.allowedStatesCaseStartForPricing = exports.validateEventName = exports.isCaseStatusEvent = exports.CaseStatusList = exports.isCaseStatusActionCode = exports.getCaseActionName = exports.CaseActions = void 0;
var camelcase_1 = require("camelcase");
var CaseActions;
(function (CaseActions) {
    CaseActions["SND_ACCEPT"] = "120";
    CaseActions["SND_REJECT"] = "130";
    CaseActions["SEND_MESSAGE"] = "170";
    CaseActions["SELECT_SND"] = "200";
    CaseActions["UPDATE_CASE"] = "201";
    CaseActions["DELETE_CASE"] = "202";
    CaseActions["HUBBLE_COMMISSIONING_EXPIRES"] = "203";
    CaseActions["HUBBLE_COMMISSIONING_UPDATE"] = "205";
    CaseActions["HUBBLE_FEEDBACK"] = "206";
    CaseActions["SELECT_DRIVER"] = "210";
    CaseActions["HERMES_CONFIRM_COMMISSION"] = "211";
    CaseActions["CLICK_DISPATCH_LINK"] = "215";
    CaseActions["DRIVER_SHARE_LOCATION"] = "220";
    CaseActions["DRIVER_ARRIVES"] = "230";
    CaseActions["CALL_CUSTOMER"] = "240";
    CaseActions["COMISSION_OPENING"] = "300";
    CaseActions["REJECT_OPENING"] = "305";
    CaseActions["CREATE_OPENING_REPORT"] = "301";
    CaseActions["CREATE_EXTRA_DAMAGE_REPORT"] = "302";
    CaseActions["COMISSION_EXTRA_COST"] = "310";
    CaseActions["COMISSION_EXTRA_DAMAGE"] = "320";
    CaseActions["EDIT_EXTRA_DAMAGE"] = "321";
    CaseActions["GIVE_UP_COMISSION"] = "325";
    CaseActions["COMPLETE_DOOR_OPENING"] = "329";
    CaseActions["CREATE_FINAL_REPORT"] = "330";
    CaseActions["REFUSE_FINAL_REPORT_SIGNATURE"] = "340";
    CaseActions["CLOSE_CASE_MANUAL"] = "801";
    CaseActions["UPLOAD_INVOICE"] = "500";
    CaseActions["SEND_INVOICE_EMAIL"] = "510";
    CaseActions["SEND_EMAIL_CUSTOMER"] = "950";
    CaseActions["CONFIRMATION_SEND_EMAIL"] = "951";
    CaseActions["SEND_SURVEY"] = "1000";
    CaseActions["SEND_INVOICE_REMINDER_SMS"] = "1200";
})(CaseActions = exports.CaseActions || (exports.CaseActions = {}));
function getCaseActionName(type, camelCase, defaultType) {
    var _a;
    if (camelCase === void 0) { camelCase = false; }
    if (defaultType === void 0) { defaultType = "caseAction:".concat(type); }
    var name = ((_a = Object.entries(CaseActions).find(function (a) { return a[1] === type; })) === null || _a === void 0 ? void 0 : _a[0]) || defaultType;
    return camelCase ? (0, camelcase_1.default)(name) : name;
}
exports.getCaseActionName = getCaseActionName;
function isCaseStatusActionCode(action) {
    return !!action && !!getCaseActionName(action, undefined, '');
}
exports.isCaseStatusActionCode = isCaseStatusActionCode;
var CaseStatusList;
(function (CaseStatusList) {
    CaseStatusList["START"] = "initialHubbleCommissioning";
    CaseStatusList["SND_SELECTED"] = "sndSelected";
    CaseStatusList["DRIVER_SELECTED"] = "driverSelected";
    CaseStatusList["COMMISSION_CONFIRMED"] = "commissionConfirmed";
    CaseStatusList["DRIVER_APPROACHING"] = "driverApproaching";
    CaseStatusList["DRIVER_ARRIVED"] = "driverArrived";
    CaseStatusList["OPENING_STARTED"] = "openingStarted";
    CaseStatusList["OPENING_COMPLETED"] = "openingCompleted";
    CaseStatusList["EXTRA_DAMAGE_ONGOING"] = "extraDamageOngoing";
    CaseStatusList["EDITING_EXTRA_DAMAGE"] = "editingExtraDamage";
    CaseStatusList["CASE_COMPLETED"] = "caseCompleted";
})(CaseStatusList = exports.CaseStatusList || (exports.CaseStatusList = {}));
function isCaseStatusEvent(event) {
    return !!event
        && Object.values(CaseStatusList).findIndex(function (e) { return e === event; }) >= 0;
}
exports.isCaseStatusEvent = isCaseStatusEvent;
function validateEventName(event) {
    var isRealState = isCaseStatusEvent(event);
    return {
        valid: isRealState,
        name: isRealState ? event : getCaseActionName(event),
    };
}
exports.validateEventName = validateEventName;
exports.allowedStatesCaseStartForPricing = [
    CaseStatusList.START,
    CaseStatusList.SND_SELECTED,
].map(function (s) { return "".concat(s); });
exports.allowedStatesForClosing = [
    CaseStatusList.SND_SELECTED,
    CaseStatusList.DRIVER_SELECTED,
    CaseStatusList.DRIVER_APPROACHING,
    CaseStatusList.COMMISSION_CONFIRMED,
    CaseStatusList.DRIVER_ARRIVED,
    CaseStatusList.OPENING_STARTED,
    CaseStatusList.OPENING_COMPLETED,
    CaseStatusList.EDITING_EXTRA_DAMAGE,
    CaseStatusList.EXTRA_DAMAGE_ONGOING,
].map(function (s) { return "".concat(s); });
exports.filesNotAvailableStates = [
    CaseStatusList.SND_SELECTED,
    CaseStatusList.DRIVER_SELECTED,
    CaseStatusList.COMMISSION_CONFIRMED,
    CaseStatusList.DRIVER_APPROACHING,
    CaseStatusList.DRIVER_ARRIVED,
].map(function (s) { return "".concat(s); });
exports.openCasesStates = [
    CaseStatusList.SND_SELECTED,
    CaseStatusList.DRIVER_SELECTED,
    CaseStatusList.COMMISSION_CONFIRMED,
    CaseStatusList.DRIVER_APPROACHING,
    CaseStatusList.DRIVER_ARRIVED,
    CaseStatusList.OPENING_STARTED,
    CaseStatusList.OPENING_COMPLETED,
    CaseStatusList.EXTRA_DAMAGE_ONGOING,
    CaseStatusList.EDITING_EXTRA_DAMAGE,
].map(function (s) { return "".concat(s); });
exports.draftCasesStates = [].map(function (s) { return "".concat(s); });
exports.viewFinalReportStates = [
    CaseStatusList.OPENING_COMPLETED,
    CaseStatusList.CASE_COMPLETED,
].map(function (s) { return "".concat(s); });
exports.cancelledCaseActions = [
    +CaseActions.DELETE_CASE,
    +CaseActions.HUBBLE_COMMISSIONING_UPDATE,
];
var isCaseCancelledByHermes = function (caseAction) { return exports.cancelledCaseActions.includes(+(caseAction || -1)); };
exports.isCaseCancelledByHermes = isCaseCancelledByHermes;
var isCaseWithdrawnByHermes = function (caseAction) { return +CaseActions.HUBBLE_COMMISSIONING_UPDATE === (+(caseAction || -1)); };
exports.isCaseWithdrawnByHermes = isCaseWithdrawnByHermes;
exports.notActionedOrRejectedCaseActions = [
    +CaseActions.HUBBLE_COMMISSIONING_EXPIRES,
    +CaseActions.SND_REJECT,
];
var isCaseRejectedOrIgnoredByPartner = function (caseAction) { return exports.notActionedOrRejectedCaseActions.includes(+(caseAction || -1)); };
exports.isCaseRejectedOrIgnoredByPartner = isCaseRejectedOrIgnoredByPartner;
var isCaseCancelled = function (caseAction) { return (0, exports.isCaseCancelledByHermes)(caseAction) || (0, exports.isCaseRejectedOrIgnoredByPartner)(caseAction); };
exports.isCaseCancelled = isCaseCancelled;
var isCaseClosedByBackoffice = function (caseAction) { return +CaseActions.CLOSE_CASE_MANUAL === +(caseAction || -1); };
exports.isCaseClosedByBackoffice = isCaseClosedByBackoffice;
var isCaseCompleted = function (caseAction) { return +CaseActions.CREATE_FINAL_REPORT === +(caseAction || -1); };
exports.isCaseCompleted = isCaseCompleted;
