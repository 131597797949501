"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLegacyReportGeneration = exports.isNewReportGeneration = exports.hasAnyFinalReportValue = exports.isReportGeneration = exports.isCaseStatusDataString = exports.isCaseStatusDataType = exports.isFinalReportEvidence = exports.isJobCommissioned = exports.hasSignature = exports.hasSignatureOrPhotos = exports.isMessageResultData = exports.isJobCancelation = exports.isSndOfferRejected = exports.isSndOfferAccepted = exports.isInvoiceUploadAllowed = void 0;
var isInvoiceUploadAllowed = function (data) { return !!(data === null || data === void 0 ? void 0 : data.allowInvoiceUpload); };
exports.isInvoiceUploadAllowed = isInvoiceUploadAllowed;
function isSndOfferAccepted(data) {
    var dataSndOfferAccepted = data;
    return !!(data && dataSndOfferAccepted.companyId && dataSndOfferAccepted.driverId && dataSndOfferAccepted.estimation);
}
exports.isSndOfferAccepted = isSndOfferAccepted;
function isSndOfferRejected(data) {
    var dataSndOfferAccepted = data;
    return !!(data && dataSndOfferAccepted.companyId);
}
exports.isSndOfferRejected = isSndOfferRejected;
function isJobCancelation(data) {
    var dataSndOfferAccepted = data;
    return !!(data && dataSndOfferAccepted.reason);
}
exports.isJobCancelation = isJobCancelation;
function isMessageResultData(data) {
    var messageResultData = data;
    return !!(data && messageResultData.message);
}
exports.isMessageResultData = isMessageResultData;
function hasSignatureOrPhotos(data) {
    return !!(data && (data.signature || data.photos));
}
exports.hasSignatureOrPhotos = hasSignatureOrPhotos;
function hasSignature(data) {
    return !!(data && data.signature);
}
exports.hasSignature = hasSignature;
function isJobCommissioned(data) {
    var jobCommissionedData = data;
    return !!(data && jobCommissionedData.photos && jobCommissionedData.signature);
}
exports.isJobCommissioned = isJobCommissioned;
function isFinalReportEvidence(data) {
    var finalReportData = data;
    return !!(data && finalReportData.receiveByEmail);
}
exports.isFinalReportEvidence = isFinalReportEvidence;
function isCaseStatusDataType(data) {
    var actionData = data;
    return !!(data && (actionData.address
        || actionData.filename
        || (actionData.longitude || actionData.latitude || actionData.companyId)
        || actionData.sms
        || actionData.signature
        || actionData.additionalCosts
        || actionData.userId
        || actionData.rejectedAt
        || (actionData.commissionReport || actionData.commissionExtraDamage || actionData.finalStatusReport || actionData.triggerEventLogId)
        || actionData.photos
        || (actionData.companyId || actionData.driverId || actionData.estimation)
        || actionData.customerPhone));
}
exports.isCaseStatusDataType = isCaseStatusDataType;
function isCaseStatusDataString(data) {
    return !!data && typeof data === 'string';
}
exports.isCaseStatusDataString = isCaseStatusDataString;
function isReportGeneration(data) {
    return !!data && (!!data.commissionReport
        || !!data.commissionExtraDamage
        || !!data.finalStatusReport);
}
exports.isReportGeneration = isReportGeneration;
function hasAnyFinalReportValue(finalStatusReport) {
    return !!(finalStatusReport === null || finalStatusReport === void 0 ? void 0 : finalStatusReport.customer) || !!(finalStatusReport === null || finalStatusReport === void 0 ? void 0 : finalStatusReport.billing) || !!(finalStatusReport === null || finalStatusReport === void 0 ? void 0 : finalStatusReport.customer);
}
exports.hasAnyFinalReportValue = hasAnyFinalReportValue;
function isNewReportGeneration(data) {
    return !!isReportGeneration(data) && hasAnyFinalReportValue(data.finalStatusReport);
}
exports.isNewReportGeneration = isNewReportGeneration;
function isLegacyReportGeneration(data) {
    return !!isReportGeneration(data) && (typeof data.finalStatusReport === 'string');
}
exports.isLegacyReportGeneration = isLegacyReportGeneration;
