"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.activateZipNames = exports.hasInactiveZipName = exports.getInactiveZipName = exports.splitZipCodes = exports.stripZipCodes = exports.isZipCodeArraySyntaxValid = exports.parseZipCodeArray = exports.isZipActive = exports.isZipNotYetActive = exports.isZipExpired = exports.multipleZipCodeIdPattern = exports.isZipValid = exports.zipCodePattern = void 0;
exports.zipCodePattern = /^(([0-9][1-9][0-9]{3})|([1-9][0-9]{4}))$/;
var isZipValid = function (zipCode) { return !!zipCode.match(exports.zipCodePattern); };
exports.isZipValid = isZipValid;
exports.multipleZipCodeIdPattern = /^((([0-9][1-9][0-9]{3})|([1-9][0-9]{4}))[   \r\n]*,[   \r\n]*)*(([0-9][1-9][0-9]{3})|([1-9][0-9]{4}))$/;
var isZipExpired = function (zip) {
    var today = new Date(Date.now());
    var expired = (zip.validTo && today > new Date(zip.validTo));
    return expired;
};
exports.isZipExpired = isZipExpired;
var isZipNotYetActive = function (zip) {
    var today = new Date(Date.now());
    var notYetActive = (zip.validFrom && new Date(zip.validFrom) > today);
    return notYetActive;
};
exports.isZipNotYetActive = isZipNotYetActive;
var isZipActive = function (zip) { return !((0, exports.isZipNotYetActive)(zip) || (0, exports.isZipExpired)(zip)); };
exports.isZipActive = isZipActive;
var parseZipCodeArray = function (zipCodes) {
    if (!zipCodes)
        return [];
    var cleanZipCodes = zipCodes.replace(/[ \s\r\n]*,[ \s\r\n]*/gi, ',');
    var zipArray = cleanZipCodes.split(',');
    return zipArray;
};
exports.parseZipCodeArray = parseZipCodeArray;
var isZipCodeArraySyntaxValid = function (zipCodes) {
    var zipArray = (0, exports.parseZipCodeArray)(zipCodes);
    if (!zipCodes || zipCodes.length === 0)
        return false;
    var foundInvalid = zipArray.find(function (zip) { return !zip || !(0, exports.isZipValid)(zip); });
    return foundInvalid === undefined;
};
exports.isZipCodeArraySyntaxValid = isZipCodeArraySyntaxValid;
var separator = ', ';
var stripZipCodes = function (zips) {
    var _a;
    return ((_a = zips === null || zips === void 0 ? void 0 : zips.replace(/[^,\d]+/g, '')) === null || _a === void 0 ? void 0 : _a.replace(/,/g, separator)) || '';
};
exports.stripZipCodes = stripZipCodes;
var splitZipCodes = function (zips) { var _a; return ((_a = (0, exports.stripZipCodes)(zips)) === null || _a === void 0 ? void 0 : _a.split(separator)) || []; };
exports.splitZipCodes = splitZipCodes;
function getInactiveZipName(name) {
    return name ? "[ -- ".concat(name, "]") : name;
}
exports.getInactiveZipName = getInactiveZipName;
var inactiveMatcher = /\[\s*--\s*(\d{5})\s*\]/gi;
function hasInactiveZipName(zipCodes) {
    return !!(zipCodes === null || zipCodes === void 0 ? void 0 : zipCodes.match(inactiveMatcher));
}
exports.hasInactiveZipName = hasInactiveZipName;
function activateZipNames(zipCodes) {
    return zipCodes === null || zipCodes === void 0 ? void 0 : zipCodes.replace(inactiveMatcher, '$1');
}
exports.activateZipNames = activateZipNames;
