import styled from 'styled-components';

import { View, Text } from '@adac/core-view';

export default styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;

  background-color: ${props => props.theme.colors.textWithHalfOpacity};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;

  z-index: 1;
`;

export const PhotoTitle = styled(Text)`
  font-weight: bold;
  color: ${props => props.theme.colors.background};  
`;

export const PhotoHeaderIconStyles = styled(View)`
  display: flex;  
`;
