"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeSuggestionAction = exports.newCompanyTypes = exports.allCompanyTypes = exports.complexCaseAllowedCompanyTypes = exports.informOtherDispacherCompanyTypes = exports.activeCompanyTypes = exports.companyStatuses = exports.CompanyTypes = void 0;
var CompanyTypes;
(function (CompanyTypes) {
    CompanyTypes["EXT"] = "ASP";
    CompanyTypes["ADAC"] = "SWA";
})(CompanyTypes = exports.CompanyTypes || (exports.CompanyTypes = {}));
exports.companyStatuses = Object.freeze({
    inactive: 'INACTIVE',
    active: 'ACTIVE',
});
exports.activeCompanyTypes = [
    CompanyTypes.EXT,
    CompanyTypes.ADAC,
];
exports.informOtherDispacherCompanyTypes = [
    CompanyTypes.ADAC,
];
exports.complexCaseAllowedCompanyTypes = [
    CompanyTypes.EXT,
];
exports.allCompanyTypes = exports.activeCompanyTypes;
exports.newCompanyTypes = exports.allCompanyTypes;
var TimeSuggestionAction;
(function (TimeSuggestionAction) {
    TimeSuggestionAction["REJECT"] = "reject";
    TimeSuggestionAction["ACCEPT"] = "accept";
})(TimeSuggestionAction = exports.TimeSuggestionAction || (exports.TimeSuggestionAction = {}));
