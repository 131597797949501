import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';

import { useParams, View } from '@adac/core-view';
// import { decipherTokenIfNeeded } from '@adac/core-model';

import StoresContext from '../../stores';
import UnauthorizedPage from '../pages/UnauthorizedPage';

const DefaultLoadingView = () => <View>Loading...</View>;

const DefaultUnauthorizedView = () => <UnauthorizedPage />;

// interface Params {
//   root_path?: string;
//   rootId?: string;
// }

interface Props {
  children: JSX.Element | JSX.Element[];
  loadingView?: JSX.Element;
  unauthorizedView?: JSX.Element;
}

export default observer(({
  children,
  loadingView,
  unauthorizedView,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorizedToView, setIsAuthorizedToView] = useState(false);

  const params = useParams();

  const { case: caseStore } = useContext(StoresContext);
  // TS is lying, companyId is actually a number, not a string...
  const expectedCompanyId = caseStore.selectedCompanyId ? `${caseStore.selectedCompanyId}` : undefined;
  const currentCompanyId = caseStore.currentCompanyId ? `${caseStore.currentCompanyId}` : undefined;

  useEffect(() => {
    // const decypheredParams = decipherTokenIfNeeded(`${(params as Params).root_path}`);
    // console.log('decypheredParams', decypheredParams);
    // const companyId = (decypheredParams && decypheredParams[1]) // try to decrypt from ciphered SND when url /+ciphered
    // || (params as Params).rootId; // fall back in case if url matches /token/companyId
    if (expectedCompanyId !== undefined && currentCompanyId === expectedCompanyId) setIsAuthorizedToView(true);
    else setIsAuthorizedToView(false);
    setIsLoading(false);
  }, [params, expectedCompanyId, currentCompanyId]);

  const getView = () => {
    if (isLoading) return loadingView || <DefaultLoadingView />;
    if (!isAuthorizedToView) return unauthorizedView || <DefaultUnauthorizedView />;
    return children;
  };

  return <>{getView()}</>;
});
