"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingAddressSchema = exports.AddressSchema = void 0;
const yup = __importStar(require("yup"));
const core_model_1 = require("@adac/core-model");
const zip_helpers_1 = require("../../helpers/zip-helpers");
var ZipErrors;
(function (ZipErrors) {
    ZipErrors["CUSTOMER_ZIP_NOT_PROVIDED"] = "CustomerZipNotProvided";
    ZipErrors["CUSTOMER_ZIP_NOT_SUPPORTED"] = "CustomerZipNotSupported";
    ZipErrors["CUSTOMER_ZIP_INCORRECTLY_ENTERED"] = "CustomerZipIncorrectlyEntered";
})(ZipErrors || (ZipErrors = {}));
exports.AddressSchema = yup.object().shape({
    zip: yup
        .string()
        .required(ZipErrors.CUSTOMER_ZIP_NOT_PROVIDED)
        .test('len', ZipErrors.CUSTOMER_ZIP_INCORRECTLY_ENTERED, val => (val === null || val === void 0 ? void 0 : val.length) === 5)
        .test('serviceAvailable', ZipErrors.CUSTOMER_ZIP_NOT_SUPPORTED, (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (value) {
            const valid = yield (0, zip_helpers_1.isZipSupported)(value);
            return valid.isSupported;
        }
        return false;
    })),
    place: yup
        .string()
        .required(),
    street: yup
        .string()
        .required(),
    houseNumber: yup
        .string()
        .required(),
    latitude: yup
        .number()
        .required(),
    longitude: yup
        .number()
        .required(),
    confidence: yup
        .number()
        .min(core_model_1.config.location().acceptDoorConfidence)
        .required(),
});
exports.BillingAddressSchema = yup.object().shape({
    zip: yup
        .string()
        .required(),
    place: yup
        .string()
        .required(),
    street: yup
        .string()
        .required(),
    houseNumber: yup
        .string()
        .required(),
    latitude: yup
        .number(),
    longitude: yup
        .number(),
    confidence: yup
        .number()
        .min(core_model_1.config.location().acceptBilingConfidence)
        .required(),
    accuracyType: yup
        .string()
        .oneOf(['city', 'street', 'houseNumber'])
        .required(),
});
