"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.customerCancellationReasons = exports.backofficeCancellationReasons = exports.noSNDAvailableCancellationReason = exports.otherCancellationReason = exports.commonCancellationReasons = void 0;
var i18n_1 = require("../helpers/i18n");
exports.commonCancellationReasons = [
    {
        id: 'Could help myself',
        title: (0, i18n_1.default)('Could help myself'),
        value: 'Could help myself',
        fee: true,
    },
    {
        id: 'Found key',
        title: (0, i18n_1.default)('Found key'),
        value: 'Found key',
        fee: true,
    },
    {
        id: 'Waittime',
        title: (0, i18n_1.default)('Waittime'),
        value: 'Waittime',
        fee: true,
    },
    {
        id: 'Price',
        title: (0, i18n_1.default)('Price'),
        value: 'Price',
        fee: true,
    },
];
exports.otherCancellationReason = {
    id: 'Other',
    title: (0, i18n_1.default)('Other'),
    value: 'Other',
    fee: true,
};
exports.noSNDAvailableCancellationReason = {
    id: 'No SND available',
    title: (0, i18n_1.default)('No SND available'),
    value: 'No SND available',
};
exports.backofficeCancellationReasons = __spreadArray(__spreadArray([], exports.commonCancellationReasons, true), [exports.otherCancellationReason, exports.noSNDAvailableCancellationReason], false);
exports.customerCancellationReasons = __spreadArray(__spreadArray([], exports.commonCancellationReasons, true), [exports.otherCancellationReason], false);
