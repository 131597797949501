"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.catchError = void 0;
var Sentry = require("@sentry/browser");
function catchError(error, customError, extraMessages) {
    Sentry.withScope(function (scope) {
        scope.setExtra('Original Error: ', error);
        if (customError)
            scope.setExtra('Custom Error: ', customError);
        if (extraMessages)
            scope.setExtra('Extra messages: ', extraMessages);
        Sentry.captureException(customError || error);
    });
}
exports.catchError = catchError;
