import React from 'react';

import { SubPage, Title } from '@adac/core-view';
import { __ } from '@adac/core-model';

export default () => (
  <SubPage>
    <Title>{__('Job has already taken')}</Title>
  </SubPage>
);
