"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomInt = exports.getNumber = exports.formatNumber = void 0;
function formatNumber(val, decimal, decimalPoint, thousandsSeparator) {
    if (decimal === void 0) { decimal = 2; }
    if (decimalPoint === void 0) { decimalPoint = ','; }
    if (thousandsSeparator === void 0) { thousandsSeparator = '.'; }
    if (Number.isNaN(val))
        return val;
    var n = val;
    var c = Number.isNaN(Math.abs(decimal)) ? 2 : decimal;
    var d = decimalPoint === undefined ? '.' : decimalPoint;
    var t = thousandsSeparator === undefined ? ',' : thousandsSeparator;
    var s = n < 0 ? '-' : '';
    var sign = n < 0 ? -1 : 1;
    var i = String(parseInt(Math.abs(Number(n) || 0).toFixed(c), 10));
    var j = (i.length) > 3 ? i.length % 3 : 0;
    var decimals = Math.abs(n - (sign * Number(i))).toFixed(c).slice(2);
    return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1".concat(t)) + (c ? d + decimals : '');
}
exports.formatNumber = formatNumber;
function getNumber(numberinput, defaultNumber) {
    if (defaultNumber === void 0) { defaultNumber = -1; }
    var number = "".concat(numberinput).replace(/[,]/g, '.').replace(/[ ]+/g, '').replace(/^([+-])?[0]+/, '0');
    try {
        if (!Number.isNaN(number)) {
            return parseFloat(number);
        }
    }
    catch (e) {
        console.warn('getNumber error', numberinput, number, Number.isNaN(number), e);
    }
    return defaultNumber;
}
exports.getNumber = getNumber;
var getRandomInt = function (min, max) {
    var minRounded = Math.ceil(min);
    var maxRounded = Math.floor(max);
    return Math.floor(Math.random() * (maxRounded - minRounded)) + minRounded;
};
exports.getRandomInt = getRandomInt;
