"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isZipSupported = exports.flattenSupportedZipList = void 0;
const core_model_1 = require("@adac/core-model");
const { apiTimeout } = core_model_1.config.location();
function flattenFields(item) {
    var _a, _b;
    const ret = Object.assign(Object.assign({}, item), { cityname: ((_a = item === null || item === void 0 ? void 0 : item.cityname) === null || _a === void 0 ? void 0 : _a.length) > 0 && typeof (item === null || item === void 0 ? void 0 : item.cityname) === 'object' ? (_b = item === null || item === void 0 ? void 0 : item.cityname) === null || _b === void 0 ? void 0 : _b.join() : JSON.stringify(item === null || item === void 0 ? void 0 : item.cityname), validToString: core_model_1.config.date(item.validTo), validFromString: core_model_1.config.date(item.validFrom) });
    return ret;
}
function flattenSupportedZipList(list) {
    return list.map(item => flattenFields(item));
}
exports.flattenSupportedZipList = flattenSupportedZipList;
const isZipSupportedByBackend = (zipCode) => __awaiter(void 0, void 0, void 0, function* () {
    const url = (0, core_model_1.getApiRoutes)().zip.isSupported(zipCode);
    let result;
    try {
        result = yield (0, core_model_1.request)(url, undefined, undefined, undefined, undefined, apiTimeout);
        return result;
    }
    catch (e) {
        console.error('isZipSupportedByBackend ERROR', e);
        return { cityname: [], isSupported: false };
    }
});
const isZipSupported = (zipCode) => __awaiter(void 0, void 0, void 0, function* () {
    if ((0, core_model_1.isZipValid)(zipCode)) {
        return isZipSupportedByBackend(zipCode);
    }
    return { cityname: [], isSupported: false };
});
exports.isZipSupported = isZipSupported;
