"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkError = exports.getErrorText = exports.getFirstErrorMessage = void 0;
var utils_1 = require("xstate/lib/utils");
function getFirstErrorMessage(error) {
    return (error
        && error.data
        && (((0, utils_1.isArray)(error.data)
            && error.data[0]
            && (error.data[0].error
                || Object.values(error.data[0])[0])) || ("".concat(Object.keys(error.data)[0], ": ").concat(Object.values(error.data)[0])))) || '';
}
exports.getFirstErrorMessage = getFirstErrorMessage;
function getErrorText(error) {
    return getFirstErrorMessage(error);
}
exports.getErrorText = getErrorText;
var NetworkError = (function (_super) {
    __extends(NetworkError, _super);
    function NetworkError(error) {
        var _this = _super.call(this, getErrorText(error)) || this;
        _this.status = (error && error.status) || 500;
        return _this;
    }
    Object.defineProperty(NetworkError.prototype, "getText", {
        get: function () {
            var matches = "".concat(this.stack).match(/^(.*)\n/gi);
            return (matches && matches[0]);
        },
        enumerable: false,
        configurable: true
    });
    return NetworkError;
}(Error));
exports.NetworkError = NetworkError;
