"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultCreateCustomError = void 0;
var HTTPError_1 = require("./HTTPError");
var catchError_1 = require("../helpers/catchError");
var __1 = require("..");
var ValidationError_1 = require("./ValidationError");
function handleOurBackendError(error) {
    var errors = error.response.data;
    if (errors && errors.length >= 1) {
        var customError = errors[0].field
            ? new ValidationError_1.default(errors[0].field.error, errors[0].field.name, errors[0].field.limit, errors[0].field.zipcodeList)
            : new HTTPError_1.default("".concat(errors[0].message || errors[0].error), __1.SERVER_ERROR, error.stack, error.response.status);
        (0, catchError_1.catchError)(error, customError, {
            requestFunc: 'Server responded, but not with 2xx, Server was our service',
            response: JSON.stringify(error.response),
        });
        return customError;
    }
    return null;
}
function handleExternalServiceError(error) {
    var _a, _b;
    var customError = new HTTPError_1.default(((_b = (_a = error.response.data) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message) || JSON.stringify(error.response.data), __1.SERVER_ERROR, error.stack, error.response.status);
    (0, catchError_1.catchError)(error, customError, {
        requestFunc: 'Server responded, but not with 2xx, Server was external service',
        response: JSON.stringify(error.response),
    });
    return customError;
}
function handleNoResponse(error, url) {
    console.log('handleNoResponse', error);
    var customError = new HTTPError_1.default('Server did not respond', __1.SERVICE_UNAVAILABLE, error.stack, undefined, { url: url });
    (0, catchError_1.catchError)(error, customError, 'request: Server did not respond');
    return customError;
}
function handleClientError(error, url, method) {
    console.log('handleClientError', error);
    var customError = new HTTPError_1.default('Setting up request failed', __1.SERVER_ERROR, error.stack, undefined, { url: url, method: method });
    (0, catchError_1.catchError)(error, customError, 'request: An error happened while setting up the request');
    return customError;
}
function defaultCreateCustomError(error, url, method) {
    var customError;
    if (error.response) {
        var ourBackendError = handleOurBackendError(error);
        if (ourBackendError) {
            customError = ourBackendError;
        }
        else {
            customError = handleExternalServiceError(error);
        }
    }
    else if (error.request) {
        customError = handleNoResponse(error, url);
    }
    else {
        customError = handleClientError(error, url, method);
    }
    return customError;
}
exports.defaultCreateCustomError = defaultCreateCustomError;
