"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteFromLocalStorage = exports.loadFromLocalStorage = exports.saveToLocalStorage = void 0;
var saveToLocalStorage = function (key, item) {
    try {
        var storage = window.localStorage;
        storage.setItem(key, item);
    }
    catch (e) {
        console.error(e);
    }
};
exports.saveToLocalStorage = saveToLocalStorage;
var loadFromLocalStorage = function (key) {
    try {
        var storage = window.localStorage;
        return storage.getItem(key);
    }
    catch (e) {
        console.error(e);
    }
    return null;
};
exports.loadFromLocalStorage = loadFromLocalStorage;
var deleteFromLocalStorage = function (key) {
    try {
        var storage = window.localStorage;
        storage.removeItem(key);
    }
    catch (e) {
        console.error(e);
    }
};
exports.deleteFromLocalStorage = deleteFromLocalStorage;
