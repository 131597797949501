"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSettingsValue = void 0;
var parseSettingsValue = function (item) {
    switch (item.type.toLowerCase()) {
        case 'boolean':
            return {
                parsedType: 'boolean',
                parsedValue: String(item.value) === 'true',
            };
        case 'number':
            return {
                parsedType: 'number',
                parsedValue: Number(item.value) || item.defaultValue,
            };
        default:
            return {
                parsedType: 'string',
                parsedValue: item.value || item.defaultValue,
            };
    }
};
exports.parseSettingsValue = parseSettingsValue;
