import React from 'react';
import { AdditionalCost, __ } from '@adac/core-model';
import styled from 'styled-components';
import {
  AmountComponentProps, CostAmount, CostLayout, CostTitle, Dots,
} from './CostLayout';

const paddingLeft = '8px';

const CostDetailLayout = styled.div`
  display: grid;
  width: 100%;
  align-items: end;
`;

const MultilineLayout = styled(CostDetailLayout)`
  grid-template-rows: max-content max-content;
  grid-template-columns: minmax(min-content, 300px) minmax(5px, 1fr) max-content;
  grid-template-areas: "title . ."
                       "details dots price";

  > ${Dots} {
    grid-column: 1/3;
    padding-left: ${paddingLeft};
  }
`;

const InlineLayout = styled(CostDetailLayout)`
  grid-template-rows: max-content;
  grid-template-columns: max-content 1fr auto max-content;
  grid-template-areas: "title details dots price";

  > ${Dots} {
    grid-column: 2/3;
    padding-left: ${paddingLeft};
  }
`;

const CostDetail = styled.span`
  grid-area: details;
  align-self: start;
  padding-left: ${paddingLeft};
  font-size: ${(props) => props.theme.font.subTitle};
  word-wrap: break-word;

  text-align: left;
`;

const CostDetailText = styled.span`
  background-color: white;
  position: relative;
  z-index: 5;
`;

const getFieldValues = (props: Record<string, string>): string => {
  const fieldWriters = [
    ({ manufacturer }: Record<string, string>) => manufacturer && `${manufacturer}`,
    ({ type }: Record<string, string>) => type && ` ${type}`,
    ({ width, height }: Record<string, string>) => (width && height) && ` (${width}mm/${height}mm)`,
  ];

  return fieldWriters.map(writer => writer(props)).join('');
};

export type CostWithDetailsProps = Required<Pick<AdditionalCost, 'details'>> & AdditionalCost & AmountComponentProps;

export const CostWithDetails = ({
  cost, description, details: costDetails, amountComponent: AmountComponent,
}: CostWithDetailsProps) => {
  const details = getFieldValues(costDetails) && `(${getFieldValues(costDetails)})`;
  const shortDescription = __(`short:${description}`);
  const translatedDescription = __(`desc:${description}`);

  const isDescriptionLong = details.length + shortDescription.length > 40 && details;

  if (isDescriptionLong) {
    return (
      <MultilineLayout>
        <CostTitle>{translatedDescription}</CostTitle>
        <CostDetail>
          <CostDetailText>{details}</CostDetailText>
        </CostDetail>
        <Dots />
        <CostAmount amountComponent={AmountComponent}>{cost} €</CostAmount>
      </MultilineLayout>
    );
  }

  if (details && !isDescriptionLong) {
    return (
      <InlineLayout>
        <CostTitle>{shortDescription}</CostTitle>
        <CostDetail>
          <CostDetailText>{details}</CostDetailText>
        </CostDetail>
        <Dots />
        <CostAmount amountComponent={AmountComponent}>{cost} €</CostAmount>
      </InlineLayout>
    );
  }

  return (
    <CostLayout>
      <CostTitle>{translatedDescription}</CostTitle>
      <Dots />
      <CostAmount amountComponent={AmountComponent}>{cost} €</CostAmount>
    </CostLayout>
  );
};
