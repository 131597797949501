"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.darkTheme = exports.mainConfig = exports.mainTheme = void 0;
const colors = {
    background: '#fff',
    backgroundLight: '#f3f3f4',
    backgroundDark: '#000',
    border: '#b2b2b2',
    divider: '#e5e5e5',
    cta: '#ffcc00',
    info: '#282828',
    disabled: '#e5e5e5',
    text: '#191919',
    textWithHalfOpacity: 'rgba(25, 25, 25, 0.5)',
    ready: '#2e7d2b',
    overlay: 'rgba(25,25,25,0.5)',
    progress: '#2E7D2B',
    info2: '#33A3DC',
    warning: '#CC3330',
    gray: '#666666',
};
function hex2RGBA(hexString, opacity = 0.5) {
    const matches = hexString.match(new RegExp(`[A-F0-9]{${hexString.length === 4 ? 1 : 2}}`, 'gi'));
    if (!matches)
        return hexString;
    return `rgba(${matches.map(c => parseInt(c, 16)).join(',')}, ${opacity})`;
}
exports.mainTheme = {
    colors: Object.assign(Object.assign({}, colors), Object.entries(colors)
        .filter(([, value]) => value.match(/^#[A-F0-9]{3,6}$/i))
        .reduce((o, [key, value]) => (Object.assign(Object.assign({}, o), { [`${key}O10`]: hex2RGBA(value, 0.1), [`${key}O50`]: hex2RGBA(value), [`${key}100`]: hex2RGBA(value, 1) })), {})),
    font: {
        text: '1.8rem',
        tinyText: '1.4rem',
        smallText: '1.6rem',
        subTitle: '1.8rem',
        subTitleBig: '2.0rem',
        title: '2.4rem',
        link: '1.8rem',
        sizeButton: '1.8rem',
        face: {
            office: "'Milo ADAC Offc', MiloADACOffc, 'Milo ADAC', MiloADAC, sans-serif",
            default: "'Milo ADAC Offc', MiloADACOffc, 'Milo ADAC', MiloADAC, sans-serif",
            normal: "'Milo ADAC', MiloADAC, sans-serif",
            slab: "'Milo Slab ADAC', MiloSlabADAC, 'Milo ADAC', MiloADAC, sans-serif",
            bold: {
                office: "'Milo ADAC Offc Bold', MiloADACOffc-Bold, 'Milo ADAC Bold', MiloADAC-Bold, sans-serif",
                default: "'Milo ADAC Offc Bold', MiloADACOffc-Bold, 'Milo ADAC Bold', MiloADAC-Bold, sans-serif",
                normal: "'Milo ADAC Bold', MiloADAC-Bold, sans-serif",
                slab: "'Milo Slab ADAC Bold', MiloSlabADAC-Bold, 'Milo ADAC Bold', MiloADAC-Bold, sans-serif",
            },
        },
    },
    animation: {
        time: '0.2s',
        transformTransition: '0.2s transform ease-out',
        heightTransition: '0.8s max-height ease-out',
        topTransition: '0.8s top ease-out',
    },
    spacing: {
        basicPadding: '14px',
        paragraphPadding: '14px 20px',
        basicMargin: '14px',
        cookieBannerHeight: '400px',
    },
    effects: {
        bigShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.4)',
        mediumShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.14)',
        smallShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
    },
};
exports.mainConfig = {
    devices: {
        mobileMaxWidth: '400px',
        tabletMaxWidth: '900px',
        desktopWideScreen: '1120px',
    },
    layout: {
        navBarHeight: '60px',
        tabBarHeight: '0px',
    },
};
exports.darkTheme = {
    colors: {
        main: 'black',
        cta: 'gray',
        info: 'lightgray',
        normal: 'black',
        disabled: 'gray',
    },
};
exports.default = Object.assign(Object.assign(Object.assign({}, exports.mainConfig), exports.mainTheme), { all: {
        main: exports.mainTheme,
        dark: exports.darkTheme,
    } });
