import styled from 'styled-components';

import { View } from '@adac/core-view';

export default styled(View)`
  width: 100%;
  height: auto;
  overflow: hidden;

  img, svg {
    width: 100%;
    display: block;
  }

  position: relative;
`;
