"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgSmiley5 = (props) => (React.createElement("svg", Object.assign({ width: 40, height: 40, viewBox: "0 0 40 40" }, props),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("circle", { cx: 20, cy: 20, r: 19, fill: "#FC0", stroke: "#FC0", strokeWidth: 2 }),
        React.createElement("path", { fill: "#191919", fillRule: "nonzero", d: "M11.11 25.35c-.657-.903.437-2.04 1.365-1.419 1.71 1.145 4.215 1.736 7.525 1.736 3.31 0 5.815-.59 7.524-1.736.93-.622 2.025.518 1.364 1.421C26.17 29.07 23.207 30.99 20.003 31c-3.208.01-6.174-1.912-8.893-5.65z" }),
        React.createElement("path", { fill: "#191919", d: "M11.45 18.534a4.394 4.394 0 01-.021-.439c0-2.104 1.492-3.81 3.333-3.81 1.84 0 3.333 1.706 3.333 3.81 0 .149-.007.295-.022.439-.786-.85-1.977-1.391-3.311-1.391-1.334 0-2.526.542-3.312 1.39zm10.477 0a4.394 4.394 0 01-.022-.439c0-2.104 1.492-3.81 3.333-3.81 1.841 0 3.333 1.706 3.333 3.81 0 .149-.007.295-.021.439-.786-.85-1.978-1.391-3.312-1.391-1.334 0-2.525.542-3.311 1.39z" }))));
exports.default = SvgSmiley5;
