"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgTwilio = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 500, height: 500, viewBox: "0 0 500 500", fill: "none" }, props),
    React.createElement("path", { fill: "#CF272D", d: "M249.988 434.129c-101.686 0-184.117-82.431-184.117-184.117 0-101.685 82.431-184.117 184.117-184.117 101.685 0 184.116 82.432 184.116 184.117 0 101.686-82.431 184.117-184.116 184.117Zm0-434.129C111.919 0 0 111.943 0 250.012 0 388.081 111.919 500 250.012 500 388.081 500 500 388.081 500 250.012 500 111.943 388.057 0 249.988 0Z" }),
    React.createElement("path", { fill: "#CF272D", d: "M260.27 187.75c0-28.696 23.283-51.98 51.98-51.98 28.696 0 51.98 23.284 51.98 51.98 0 28.721-23.284 51.98-51.98 51.98-28.697 0-51.98-23.283-51.98-51.98Zm0 124.524c0-28.696 23.283-51.979 51.98-51.979 28.696 0 51.98 23.283 51.98 51.979 0 28.722-23.284 51.98-51.98 51.98-28.697 0-51.98-23.283-51.98-51.98Zm-124.524 0c0-28.696 23.283-51.979 51.98-51.979 28.721 0 51.979 23.283 51.979 51.979 0 28.722-23.283 51.98-51.979 51.98-28.697 0-51.98-23.283-51.98-51.98Zm0-124.524c0-28.721 23.283-51.98 51.98-51.98 28.721 0 51.979 23.284 51.979 51.98 0 28.721-23.283 51.98-51.979 51.98-28.697 0-51.98-23.283-51.98-51.98Z" })));
exports.default = SvgTwilio;
