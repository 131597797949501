import React from 'react';
import { AdditionalCost, __ } from '@adac/core-model';
import styled, { StyledComponent } from 'styled-components';
import { Text, themeConfig } from '@adac/core-view';

export const CostLayout = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: max-content max-content;
  grid-template-columns: max-content auto max-content;
  grid-template-areas: "title dots price";
`;

export const CostTitle = styled(Text)`
  grid-area: title;
  font-size: ${(props) => props.theme.font.subTitle};
`;

export const Dots = styled.span`
  grid-area: dots;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  font-size: ${themeConfig.font.subTitle};

  :after {
    content: "........................................................................................................................................................................................................................................................................................................................................................................................................................................";
  }
`;


export type AmountComponentProps = {
  amountComponent: StyledComponent<any, any> | (() => JSX.Element);
}

type CostProps = AdditionalCost & AmountComponentProps;

export const Cost = ({ description, cost, amountComponent: AmountComponent }: CostProps) => (
  <CostLayout>
    <CostTitle>
      {__(`desc:${description}`)}
    </CostTitle>
    <Dots />
    <CostAmount amountComponent={AmountComponent}>{cost} €</CostAmount>
  </CostLayout>
);

type CostAmountProps = AmountComponentProps & React.PropsWithChildren<object>;
export const CostAmount = ({ amountComponent: AmountComponent, children }: CostAmountProps) => <AmountComponent style={{ gridArea: 'price' }}>{children}</AmountComponent>;
