import React from 'react';

import { Divider, Button, Icon } from '@adac/core-view';
// import { reportSelectors } from '@adac/core-model';
import { PhotoUploadedByUser } from '../../../stores/DoorPhotoStore';

import Photo from './Photo';
import PhotoHeader, { PhotoHeaderIconStyles, PhotoTitle } from './PhotoHeader';

interface DoorPhotoProps {
  photo: PhotoUploadedByUser;
  index: number;
  onDelete?: () => void;
  onFullScreen?: () => void;
  onLoaded?: () => void;
  // onError?: (e: any) => void;
}

// type ImageEvent = ((event: React.SyntheticEvent<HTMLImageElement, Event>) => void) | undefined;

export default (props: DoorPhotoProps) => {
  const {
    photo, index, onDelete, onFullScreen, onLoaded,
    // onError,
  } = props;

  const onImageLoad = () => onLoaded && onLoaded();

  // const onImageError = (e: any) => onError && onError(e);

  return (
    <Photo>
      <PhotoHeader>
        <PhotoTitle>{`#${index}`}</PhotoTitle>
        <PhotoHeaderIconStyles>
          {
            typeof onDelete === 'function' && (
              <Button onClick={onDelete}>
                <Icon name="delete" color="white" />
              </Button>
            )
          }
          <Divider vertical opacity={0.25} />
          <Button onClick={onFullScreen}>
            <Icon name="expand" color="white" />
          </Button>
        </PhotoHeaderIconStyles>
      </PhotoHeader>
      <img
        src={photo.url}
        alt="Door"
        onLoad={onImageLoad}
        // onError={onImageError}
      />
    </Photo>
  );
};
