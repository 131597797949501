"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleArrayItem = exports.updateAtIndex = exports.keyGenerator = void 0;
var util_1 = require("util");
function keyGenerator(props, keys) {
    if (keys === void 0) { keys = []; }
    var defaultKeys = ['key', 'id', 'component', 'path'];
    var allKeys = defaultKeys.concat(keys);
    var value = (Object.entries(props)
        .find(function (_a) {
        var key = _a[0];
        return allKeys.includes(key);
    }) || [null, props])[1];
    if ((0, util_1.isArray)(value))
        return JSON.stringify(value);
    return value;
}
exports.keyGenerator = keyGenerator;
var updateAtIndex = function (array, newValue, index) { return __spreadArray(__spreadArray(__spreadArray([], array.slice(0, index), true), [newValue], false), array.slice(index + 1), true); };
exports.updateAtIndex = updateAtIndex;
var toggleArrayItem = function (items, item) { return (items.includes(item)
    ? items.reduce(function (newItems, currItem) { return __spreadArray(__spreadArray([], newItems, true), (currItem === item ? [] : [currItem]), true); }, [])
    : __spreadArray(__spreadArray([], items, true), [item], false)); };
exports.toggleArrayItem = toggleArrayItem;
