"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitializeCostFormValues = void 0;
const extraDamageTypes_1 = require("../../../content/extraDamageTypes");
const costFormKeys = {
    [extraDamageTypes_1.CostItemID.PROFILE_CYLINDER_SPECIAL]: {},
    [extraDamageTypes_1.CostItemID.LOCK_SPECIAL]: {},
    [extraDamageTypes_1.CostItemID.SECURITY_FITTING_SPECIAL]: {},
};
function useInitializeCostFormValues(storeItems) {
    const valueKeys = Object.keys(costFormKeys);
    const initialValues = storeItems.reduce((acc, curr) => {
        const { radioItems } = curr;
        const radioItem = radioItems === null || radioItems === void 0 ? void 0 : radioItems.find(({ id }) => valueKeys.includes(id));
        if (!radioItem)
            return acc;
        return Object.assign(Object.assign({}, acc), { [radioItem.id]: {
                count: radioItem.count,
                value: radioItem.value,
                details: radioItem.details,
            } });
    }, {});
    return initialValues;
}
exports.useInitializeCostFormValues = useInitializeCostFormValues;
