"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractLocationCoordsWithConfidence = exports.extractLocationCoords = exports.getCustomerCoordsType = exports.isLocationCoordsValid = exports.defaultLocationDisplayOnError = exports.defaultLocationCoordsOnError = exports.defaultLocationCoords = void 0;
exports.defaultLocationCoords = {
    latitude: 48.133520,
    longitude: 11.528310,
};
exports.defaultLocationCoordsOnError = {
    latitude: 0,
    longitude: 0,
};
exports.defaultLocationDisplayOnError = {
    latitude: 53.681265,
    longitude: 8.362691,
};
function isLocationCoordsValid(coords) {
    return coords
        && typeof coords.latitude === 'number' && typeof coords.latitude === 'number'
        && !(coords.latitude === exports.defaultLocationCoordsOnError.latitude && coords.longitude === exports.defaultLocationCoordsOnError.longitude)
        && !(coords.latitude === exports.defaultLocationDisplayOnError.latitude && coords.longitude === exports.defaultLocationDisplayOnError.longitude);
}
exports.isLocationCoordsValid = isLocationCoordsValid;
function getCustomerCoordsType(coords) {
    if ((coords === null || coords === void 0 ? void 0 : coords.longitude) === undefined || (coords === null || coords === void 0 ? void 0 : coords.longitude) === null)
        return 'init';
    return isLocationCoordsValid(coords) ? 'home' : 'error';
}
exports.getCustomerCoordsType = getCustomerCoordsType;
function extractLocationCoords(object) {
    return {
        latitude: Number(object === null || object === void 0 ? void 0 : object.latitude) || exports.defaultLocationCoordsOnError.latitude,
        longitude: Number(object === null || object === void 0 ? void 0 : object.longitude) || exports.defaultLocationCoordsOnError.longitude,
    };
}
exports.extractLocationCoords = extractLocationCoords;
function extractLocationCoordsWithConfidence(object) {
    var coords = extractLocationCoords(object);
    return __assign(__assign({}, coords), { confidence: coords.latitude ? 1 : 0 });
}
exports.extractLocationCoordsWithConfidence = extractLocationCoordsWithConfidence;
