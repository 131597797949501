import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { __, CaseStatusList, getClaimReference } from '@adac/core-model';
import {
  FlexView, SubTitle, TextProps, useLocation,
} from '@adac/core-view';
import styled from 'styled-components';
import StoresContext from '../../stores';

interface HeaderCaseInfoStylesProps extends TextProps {
  show: boolean;
}

const HeaderCaseInfoStyles = styled(SubTitle)<HeaderCaseInfoStylesProps>`
  padding-left: 12px;

  overflow: hidden;
  transition: all 0.7s ease;
  text-indent: -200%;


  ${props => props.show && `
    text-indent: 0;
  `}
`;

export const HeaderCaseInfoContainer = observer(() => {
  const { case: caseStore } = useContext(StoresContext);

  const showCaseInfoStates = [
    CaseStatusList.DRIVER_ARRIVED,
    CaseStatusList.OPENING_STARTED,
    CaseStatusList.EDITING_EXTRA_DAMAGE,
    CaseStatusList.EXTRA_DAMAGE_ONGOING,
    CaseStatusList.OPENING_COMPLETED,
    CaseStatusList.CASE_COMPLETED,
  ].map(s => `${s}`);
  const { pathname } = useLocation();
  const isView = !!pathname.match(/\/view/gi);

  // on initial page we display as bigger text inline with title

  return (
    <FlexView column style={{ justifyContent: 'center' }}>
      <HeaderCaseInfoStyles tiny show={showCaseInfoStates.includes(caseStore.status)}>{__('Hermes Id')}: {getClaimReference(caseStore.commissioningReference)}</HeaderCaseInfoStyles>
      {isView && <HeaderCaseInfoStyles tiny show={showCaseInfoStates.includes(caseStore.status)}>{caseStore.companyInfo?.type || __('Firma')}: {caseStore.companyInfo?.contactManagerId}</HeaderCaseInfoStyles>}
    </FlexView>
  );
});
