import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  Formik, FormikProps, Form, Field, FieldProps,
} from 'formik';
import { observer } from 'mobx-react';
import {
  Page, SubPage, Title, SubTitle, View,
  useLocation, ButtonStyles, ExpandableWidget, ExpandableProps,
  Checkbox, CheckboxContainer, CheckboxItem, LinkAutoPrefixer, extractConfirmedAddressProps, Button,
} from '@adac/core-view';
import { __, config } from '@adac/core-model';
import * as yup from 'yup';
import Door from './Door';
import { routeNames } from '../layout/OverlayRouterContainer';
import StoreContext from '../../stores';
import { ConfirmedAddressFormValues, ConfirmedAddressForm } from './ConfirmedAddressForm';

const CheckboxSubPage = styled(SubPage)`

  ${CheckboxContainer} + ${ButtonStyles} {
    margin-top: 20px;
  }
`;

export const PhotoContainer = styled(View)<ExpandableProps>`
  opacity: 0.2;
  transition: all 0.3s ease;

  ${p => p.open && `
    opacity: 1;
  `}
`;

interface FinalReportImagesFormValues extends ConfirmedAddressFormValues {
  idChecked: boolean;
  photosLength: number;
}


const ConfirmedAddressFormSchema = yup.object().shape({
  addressConfirmed: yup
    .boolean()
    .required(),
  zip: yup
    .string()
    .when('addressConfirmed', {
      is: false,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  place: yup
    .string()
    .when('addressConfirmed', {
      is: false,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  street: yup
    .string()
    .when('addressConfirmed', {
      is: false,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  houseNumber: yup
    .string()
    .when('addressConfirmed', {
      is: false,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
  confidence: yup
    .number()
    .when('addressConfirmed', {
      is: false,
      then: schema => schema.min(config.location().acceptBilingConfidence).required(),
      otherwise: schema => schema.optional(),
    }),
  accuracyType: yup
    .string()
    .when('addressConfirmed', {
      is: false,
      then: schema => schema.oneOf(['city', 'street', 'houseNumber']).required(),
      otherwise: schema => schema.optional(),
    }),
});

const FinalReportImagesSchema = ConfirmedAddressFormSchema.concat(
  yup.object().shape({
    idChecked: yup
      .boolean()
      .oneOf([true], 'The Id check must be performed!')
      .required(),
  }),
);

export default observer(() => {
  const doorStore = useContext(StoreContext).afterOpeningDoorPhotos;
  const { case: caseStore } = useContext(StoreContext);
  const { goToWithRootId } = useLocation();

  const {
    addressConfirmed,
    confirmedAddressZip, confirmedAddressCity, confirmedAddressStreet, confirmedAddressNumber,
  } = caseStore;

  const arePhotosValid = doorStore.photosUploadedByUser.length > 0;

  const initialValues = {
    idChecked: false,
    latitude: 0,
    longitude: 0,
    confidence: 0,
    addressConfirmed,
    zip: confirmedAddressZip,
    place: confirmedAddressCity,
    street: confirmedAddressStreet,
    houseNumber: confirmedAddressNumber,
    photosLength: doorStore.photosUploadedByUser.length, // We need to have a reference for DoorStore, in order to have the form validation triggered for any change in photos(quantity).
  };

  const onSubmit = (values: FinalReportImagesFormValues) => {
    caseStore.setConfirmedAddress(extractConfirmedAddressProps(values));
    goToWithRootId(routeNames.finalStatusReport('active'));
  };

  return (
    <Page>
      <Formik
        initialValues={initialValues}
        isInitialValid={false}
        validationSchema={FinalReportImagesSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting, isValid, values,
        }: FormikProps<FinalReportImagesFormValues>) => (
          <CheckboxSubPage>
            <LinkAutoPrefixer to="/" prefix="rootId">
              <SubTitle>&lt;{__('Back')}</SubTitle>
            </LinkAutoPrefixer>

            <Title margin>{__('Progress report')}</Title>
            <Form>
              <ExpandableWidget open contentHeightMin={50} contentHeight={490} container={PhotoContainer}>
                <SubTitle color="#666666">{__('Fotodokumentation nach Türöffnung')}</SubTitle>
                <Door doorStore={doorStore} />
              </ExpandableWidget>

              <Field
                name="idChecked"
                type="checkbox"
                render={({ field }: FieldProps<'idChecked', FinalReportImagesFormValues>) => (
                  <Checkbox
                    {...field}
                    id="idChecked"
                    labelTitle={__('have you checked Id?')}
                    checked={values.idChecked}
                    render={CheckboxItem}
                  />
                )}
              />

              <ConfirmedAddressForm />

              <Button
                type="submit"
                cta
                title={__('Weiter')}
                disabled={!isValid || !arePhotosValid}
                isLoading={isSubmitting}
              />
            </Form>

          </CheckboxSubPage>
        )}
      </Formik>
    </Page>
  );
});
