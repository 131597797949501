"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultStatefulNetworkingStore = void 0;
const mobx_1 = require("mobx");
class DefaultStatefulNetworkingStore {
    constructor() {
        this.loading = false;
        this.errors = [];
    }
    get hasError() {
        return this.errors && this.errors.length > 0;
    }
    clearErrors() {
        this.errors = [];
    }
    setErrors(errors) {
        if (errors && errors.length > 0) {
            console.error(errors);
        }
        this.errors = errors;
    }
    addErrors(error) {
        console.error(error);
        this.errors = this.errors.concat(error);
    }
    get lastErrorMessage() {
        return this.errors.length > 0 ? this.errors[0] : null;
    }
}
__decorate([
    mobx_1.observable
], DefaultStatefulNetworkingStore.prototype, "loading", void 0);
__decorate([
    mobx_1.observable
], DefaultStatefulNetworkingStore.prototype, "errors", void 0);
__decorate([
    mobx_1.computed
], DefaultStatefulNetworkingStore.prototype, "hasError", null);
__decorate([
    mobx_1.action
], DefaultStatefulNetworkingStore.prototype, "clearErrors", null);
__decorate([
    mobx_1.action
], DefaultStatefulNetworkingStore.prototype, "setErrors", null);
__decorate([
    mobx_1.action
], DefaultStatefulNetworkingStore.prototype, "addErrors", null);
__decorate([
    mobx_1.computed
], DefaultStatefulNetworkingStore.prototype, "lastErrorMessage", null);
exports.DefaultStatefulNetworkingStore = DefaultStatefulNetworkingStore;
