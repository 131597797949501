import { ComponentType, __ } from '@adac/core-model';
import {
  FlexView, Icon, View,
} from '@adac/core-view';
import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { HeaderCaseInfoContainer } from './HeaderCaseInfoContainer';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';

const AdacLogo = () => <Icon name="adac" type="ico.logo" size="24px" />;

const SndDriverLogo = () => <>{__('SND Driver portal')}</>;

const WithLink = observer(({ children }: {children: ComponentType}) => {
  const isInsurance = useIsInsuranceProduct();
  if (isInsurance) return children;
  return <Link to="/">{children}</Link>;
});

const Header = () => (
  <FlexView start center>
    <View padding><WithLink><AdacLogo /></WithLink></View>
    <View padding="5px 0px"><View background="white" padding="4px" compact={false}><WithLink><SndDriverLogo /></WithLink></View></View>
    <HeaderCaseInfoContainer />
  </FlexView>
);

export default Header;
