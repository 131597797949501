"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nullableI18n = exports.i18n = exports.selectObject = exports.getLang = void 0;
var getLang = function (language, _currentLang) { return (language || _currentLang || '').toLowerCase(); };
exports.getLang = getLang;
var findEntry = function (key, localization) { return Object.entries(localization).find(function (item) { return item[0].toLowerCase() === key.toLowerCase(); }); };
var flattenArray = function (object) { return (object && ((Array.isArray(object) && object.join('\n')) || String(object))); };
var selectObject = function (found, language, defaultLang) { return ((found && found[(0, exports.getLang)(language)])
    || (found && defaultLang && found[defaultLang])); };
exports.selectObject = selectObject;
function getStringFromLocalization(title, language, myLocalization) {
    if (!myLocalization)
        return '';
    if (typeof title !== 'string')
        return title;
    var key = (title.indexOf('\n') && title.split('\n') && title.split('\n')[0].trim()) || title;
    var defaultLang = myLocalization.keys[0];
    var a = findEntry(key, myLocalization);
    var found = (myLocalization[key] || ((a) && (a)[1]));
    return (!Array.isArray(found)
        && (0, exports.selectObject)(found, language, defaultLang)
        && flattenArray((0, exports.selectObject)(found, language, defaultLang)));
}
function i18n(title, language, myLocalization) {
    var retString = getStringFromLocalization(title, language, myLocalization);
    return retString || title;
}
exports.i18n = i18n;
function nullableI18n(title, language, myLocalization) {
    var retString = getStringFromLocalization(title, language, myLocalization);
    return retString || null;
}
exports.nullableI18n = nullableI18n;
