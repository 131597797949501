"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormikAuth = exports.useTokenMatcher = void 0;
const react_1 = require("react");
const useTokenMatcher = () => {
    const [tokenKey, setTokenKey] = (0, react_1.useState)('');
    const [tokenValue, setTokenValue] = (0, react_1.useState)('');
    const state = (() => {
        if (!!tokenKey && !!tokenValue)
            return 'validated';
        if (tokenKey)
            return 'validating';
        return 'idle';
    })();
    return {
        tokenKey,
        setTokenKey,
        tokenValue,
        setTokenValue,
        state,
    };
};
exports.useTokenMatcher = useTokenMatcher;
const useFormikAuth = (validate, onError, captureSecret) => {
    const [result, setResult] = (0, react_1.useState)(null);
    const onSubmit = (values, actions) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!values.id)
                throw new Error('missing input');
            const result = yield validate(values.id, values.secret);
            setResult(result);
            if (captureSecret)
                captureSecret(values.secret);
        }
        catch (err) {
            if (onError)
                onError(err);
            else
                console.warn('Error not handled:', err);
            actions.setStatus(err);
        }
        finally {
            actions.setSubmitting(false);
        }
    });
    return {
        onSubmit,
        result,
        setResult,
    };
};
exports.useFormikAuth = useFormikAuth;
