"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgContrast = (props) => (React.createElement("svg", Object.assign({ width: 31, height: 20, viewBox: "0 0 31 20" }, props),
    React.createElement("path", { d: "M10.186 16.729L8.696 20h4.33l.07-.162.07.162h3.356l-1.831-3.909.22-.517h9.224L26.02 20h4.416L21.323 0h-3.515l-5.254 11.53-2.957-6.313H6.925L0 20h3.292l1.432-3.271h5.462zm.458-2.474h-4.83l2.415-5.536 2.415 5.536zm12.057-2.028h-6.356l3.178-7.49 3.178 7.49z", fill: "#282828", fillRule: "nonzero" })));
exports.default = SvgContrast;
