"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.findMatchingRoute = exports.useLocation = exports.useQueryParam = exports.useParams = exports.useRouter = void 0;
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const query_string_1 = require("query-string");
const __1 = require("..");
function useRouter() {
    return (0, react_1.useContext)(__1.RouterContext);
}
exports.useRouter = useRouter;
function useParams() {
    const { match } = useRouter();
    return match.params;
}
exports.useParams = useParams;
const useQueryParam = (key, defaultValue = '') => {
    const { location: { search }, history } = useRouter();
    const set = (value) => {
        const newQueryObj = Object.assign(Object.assign({}, (0, query_string_1.parse)(search)), { [key]: value });
        const newQueryString = (0, query_string_1.stringify)(newQueryObj);
        history.push(`?${newQueryString}`);
    };
    const value = (0, query_string_1.parse)(search)[key] ? `${(0, query_string_1.parse)(search)[key]}` : defaultValue;
    return [value, set];
};
exports.useQueryParam = useQueryParam;
function useLocation() {
    const { location, history, match } = useRouter();
    const { pathname } = location;
    function rootPath() {
        return match.params.root_path;
    }
    function rootId() {
        return match.params.rootId;
    }
    function getPrefixedLink(to, condition, prefixRootId = false) {
        if (condition === false)
            return '____NOT_TO_MATCH____';
        return rootPath() && to.match(/^\?/gi) === null ? `/${rootPath()}${prefixRootId ? `/${rootId()}` : ''}${!to.match(/^\//) ? '/' : ''}${to}` : to;
    }
    function goTo(to, { replace = false, prefixed = true, prefixRootId = false } = {}) {
        const adjustedTo = prefixed ? getPrefixedLink(to, undefined, prefixRootId) : to;
        if (pathname !== adjustedTo) {
            if (replace) {
                history.replace(adjustedTo);
            }
            else {
                history.push(adjustedTo);
            }
        }
    }
    function goToWithRootId(to) {
        goTo(to, { replace: false, prefixed: true, prefixRootId: true });
    }
    function back() {
        history.goBack();
    }
    function goBack() {
        history.goBack();
    }
    function goN(number) {
        history.go(number);
    }
    function prefixByDepth(depth) {
        return match.url.split('/').filter((_v, i) => i < depth).join('/');
    }
    function mainPath(depth = 1) {
        const path = pathname.replace(/^\/+|\/+$/g, '').split('/');
        return `/${path.slice(0, depth).join('/')}/`;
    }
    const getUpLink = mainPath(-1);
    function goUp() {
        goTo(getUpLink);
    }
    const pathWithoutRoot = pathname.replace(new RegExp(`^/${rootPath()}`), '');
    return Object.assign(Object.assign({}, location), { pathWithoutRoot,
        goTo,
        goToWithRootId,
        back,
        goBack,
        goN,
        goUp,
        prefixByDepth,
        mainPath,
        getPrefixedLink,
        rootPath,
        rootId,
        getUpLink });
}
exports.useLocation = useLocation;
const findMatchingRoute = ({ location, routes }) => routes.find((_a) => {
    var _b;
    var { path } = _a, child = __rest(_a, ["path"]);
    const pathParam = (_b = child.props.path) !== null && _b !== void 0 ? _b : (path || '');
    const exact = !!child.props.exact;
    const strict = !!child.props.strict;
    return (0, react_router_dom_1.matchPath)(location.pathname, { path: pathParam, exact, strict });
});
exports.findMatchingRoute = findMatchingRoute;
