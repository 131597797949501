"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pathJoin = exports.isStringData = exports.urlifyFileName = exports.bufferFile = void 0;
var fs = require('fs');
var path = require('path');
function bufferFile(dirName, relPath) {
    return new Promise(function (resolve, reject) {
        var filePath = path.join(dirName, relPath);
        fs.readFile(filePath, { encoding: 'utf8' }, function (error, data) {
            if (error)
                reject(error);
            else
                resolve(data);
        });
    });
}
exports.bufferFile = bufferFile;
var urlifyFileName = function (fileName, fileFormat, fileServerUrl) {
    if (fileFormat === void 0) { fileFormat = ''; }
    if (fileServerUrl === void 0) { fileServerUrl = process.env.FILE_SERVER_URL; }
    if (!fileServerUrl)
        throw new Error('File server url env var is missing.');
    if (fileName === null || fileName === void 0 ? void 0 : fileName.match(new RegExp("^".concat(fileServerUrl))))
        return fileName;
    return fileName ? "".concat(fileServerUrl, "/").concat(fileName).concat(fileFormat ? ".".concat(fileFormat) : '') : undefined;
};
exports.urlifyFileName = urlifyFileName;
var isStringData = function (str) { return str.startsWith('data:'); };
exports.isStringData = isStringData;
var pathJoin = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return path.join.apply(path, args);
};
exports.pathJoin = pathJoin;
