"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateConfig = exports.isProd = exports.isEnvironment = exports.getPollingInterval = void 0;
const Joi = __importStar(require("joi"));
const core_model_1 = require("@adac/core-model");
function getPollingInterval(type) {
    const envName = (type && `REACT_APP_${type}_POLLING_INTERVAL`) || 'REACT_APP_POLLING_INTERVAL';
    const defaultInterval = (() => {
        switch (type) {
            case 'STATUS':
                return 300000;
            case 'ESCALATION':
                return 45000;
            default:
                return 30000;
        }
    })();
    return (Number.isNaN(process.env[envName]) && Number(process.env[envName])) || defaultInterval;
}
exports.getPollingInterval = getPollingInterval;
const isEnvironment = (env) => ((!process.env.REACT_APP_SERVER_ENV || process.env.REACT_APP_SERVER_ENV === env) && (!!process.env.NODE_ENV && process.env.NODE_ENV === env));
exports.isEnvironment = isEnvironment;
const isProd = () => (0, exports.isEnvironment)('production');
exports.isProd = isProd;
const validateConfig = (portal) => (envVars) => {
    const envSchema = Joi.object().keys({
        REACT_APP_API_BASE_URL: Joi.string().uri().required(),
        REACT_APP_SOCKET_BASE_URL: Joi.string().uri().optional(),
        REACT_APP_CUSTOMER_PORTAL_URL: Joi.string().uri().required(),
        REACT_APP_DEV_TIMEOUT_STATUS: Joi.number().optional(),
        REACT_APP_LOCALE: Joi.string().regex(/de/i).required(),
        REACT_APP_SENTRY_LEVELS: Joi.string().regex(core_model_1.logLevelPattern).optional().allow(null, ''),
        REACT_APP_ESCALATION_POLLING_INTERVAL: Joi.number().optional(),
        REACT_APP_FAILURE_POLLING_INTERVAL: Joi.number().optional(),
        REACT_APP_TAKEN_POLLING_INTERVAL: Joi.number().optional(),
        REACT_APP_STATUS_POLLING_INTERVAL: Joi.number().optional(),
        REACT_APP_CAPTCHA_DISABLED_FOR_NODE_ENV: Joi.string().regex(/^development$/).optional().allow(''),
        REACT_APP_CASE_ID_DISPLAY: Joi.string().regex(/^token|id$/).optional(),
        REACT_APP_BROWSER_NOTIFICATION_TIMEOUT: Joi.number().required(),
        REACT_APP_DUMMY_SND_MOVING: Joi.string().regex(/^true|false$/).optional().allow(null, ''),
        REACT_APP_DUMMY_SND_SLOWING: Joi.number().optional(),
        REACT_APP_ENABLE_EXTRA_PHONE_NUMBER_VALIDATION: Joi.string().valid('true', 'false').optional().allow(null, ''),
        REACT_APP_ADDITIONAL_VALID_PHONE_NUMBERS: Joi.string().optional().allow(null, ''),
        [`REACT_APP_SENTRY_URL_${portal}`]: Joi.string().uri().optional(),
        REACT_APP_SND_ADMIN_PORTAL_URL: Joi.string().uri().required(),
        REACT_APP_COMPANY_ID_CIPHER: Joi.string().optional(),
        REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE: Joi.string().regex(core_model_1.isoDatePattern).required(),
    }).unknown();
    const { error } = envSchema.validate(envVars);
    if (error) {
        console.error(`Env var config validation error: ${error}`);
        throw new Error(`${error}`);
    }
    return true;
};
exports.validateConfig = validateConfig;
