"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgCallin = (props) => (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24" }, props),
    React.createElement("path", { fill: "#191919", d: "M17.318 5.229l2.878-2.878 2.834 2.834-2.859 2.858 1.626 1.603h-6.094v-6.01l1.615 1.593zm.398 16.57c-.964-.018-2.721-.647-4.53-1.565C9.643 18.441 3.51 11.367 2.649 8.105c-.527-1.475-.73-2.586-.617-3.212.02-.114.07-.211.327-.513.42-.491 1.204-1.235 2.462-2.301.466-.343 1.132-.407 1.619.033.253.227.707.831 1.265 1.663.378.563.728 1.115 1.051 1.637.364.63.573 1.443.238 1.995-.233.385-.632 1.135-1.08 2.006l-.124.242.15.226c1.267 1.922 3.033 4.006 5.201 5.693l.23.178 2.037-1.072c.377-.157.948-.224 1.626.038l.1.057 3.49 2.497c.199.175.452.556.473.977.016.3-.08.603-.35.9-.12.129-.235.262-.507.583-1.186 1.387-1.72 1.903-2.139 2.025-.099.03-.23.044-.385.041z", fillRule: "evenodd" })));
exports.default = SvgCallin;
