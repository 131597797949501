"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCaseDetails = void 0;
const core_model_1 = require("@adac/core-model");
const location_helpers_1 = require("../helpers/location-helpers");
const useAsyncAction_1 = require("./useAsyncAction");
const useCaseDetails = (args, getRequest) => {
    var _a, _b, _c, _d;
    const { resource: caseDetails, isLoading, iocAction: refreshCaseData, } = (0, useAsyncAction_1.useAsyncAction)(() => __awaiter(void 0, void 0, void 0, function* () { return getRequest(...args); }), [...args]);
    const activeEvent = ((_b = (_a = caseDetails === null || caseDetails === void 0 ? void 0 : caseDetails.logs) === null || _a === void 0 ? void 0 : _a.find((log) => log.active)) === null || _b === void 0 ? void 0 : _b.event) || '';
    const doorCompleted = (_d = (_c = caseDetails === null || caseDetails === void 0 ? void 0 : caseDetails.logs) === null || _c === void 0 ? void 0 : _c.find((log) => log.event === core_model_1.CaseStatusList.OPENING_COMPLETED)) === null || _d === void 0 ? void 0 : _d.data;
    const customerCoords = (0, location_helpers_1.extractDisplayLocationCoords)(caseDetails === null || caseDetails === void 0 ? void 0 : caseDetails.case);
    const driverCoords = (0, location_helpers_1.extractDisplayLocationCoords)(caseDetails === null || caseDetails === void 0 ? void 0 : caseDetails.driverInfo);
    return {
        caseDetails,
        isLoading,
        confirmedAddress: doorCompleted === null || doorCompleted === void 0 ? void 0 : doorCompleted.confirmedAddressDetails,
        billingAddress: doorCompleted === null || doorCompleted === void 0 ? void 0 : doorCompleted.differentBillingAddress,
        refreshCaseData,
        activeEvent,
        customerCoords,
        driverCoords,
    };
};
exports.useCaseDetails = useCaseDetails;
