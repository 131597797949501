"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoCaseTypes = exports.ProductTypes = exports.ComplexityTypes = void 0;
var ComplexityTypes;
(function (ComplexityTypes) {
    ComplexityTypes["CLOSED"] = "closed";
    ComplexityTypes["LOCKED"] = "locked";
    ComplexityTypes["INFO"] = "info";
    ComplexityTypes["FAILURE"] = "failure";
})(ComplexityTypes = exports.ComplexityTypes || (exports.ComplexityTypes = {}));
var ProductTypes;
(function (ProductTypes) {
    ProductTypes["PREMIUM"] = "premium";
    ProductTypes["STANDARD"] = "standard";
    ProductTypes["INSURANCE"] = "insurance";
})(ProductTypes = exports.ProductTypes || (exports.ProductTypes = {}));
var InfoCaseTypes;
(function (InfoCaseTypes) {
    InfoCaseTypes["CAR_OPENING"] = "carOpening";
    InfoCaseTypes["WANTED_DIFFERENT_SERVICE"] = "wantedDifferentService";
    InfoCaseTypes["PRICE_TOO_HIGH"] = "priceTooHigh";
    InfoCaseTypes["CUSTOMER_HUNG_UP"] = "customerHungUp";
    InfoCaseTypes["WRONG_TYPE_OF_DOOR"] = "wrongTypeOfDoor";
    InfoCaseTypes["ZIP_NOT_SUPPORTED"] = "zipNotSupported";
    InfoCaseTypes["OTHER"] = "other";
})(InfoCaseTypes = exports.InfoCaseTypes || (exports.InfoCaseTypes = {}));
