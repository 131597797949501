"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCompanyById = exports.flattenCompanyList = exports.flattenCompanyFieldsWithBoss = exports.flattenCompanyFields = exports.extractCompanyAddressObject = exports.getCompanyTypeText = exports.getCompanyStatusText = void 0;
const core_model_1 = require("@adac/core-model");
const useAsyncAction_1 = require("../hooks/useAsyncAction");
const getCompanyStatusText = (active) => (0, core_model_1.__)(`CompanyStatus:${`${active ? core_model_1.companyStatuses.active : core_model_1.companyStatuses.inactive}`.toUpperCase()}`);
exports.getCompanyStatusText = getCompanyStatusText;
const getCompanyTypeText = (type) => (0, core_model_1.__)(`CompanyType:${`${type}`.toUpperCase()}`);
exports.getCompanyTypeText = getCompanyTypeText;
function findDispatcher(drivers, matchRole = /dispatcher/gi, fallbackRole = /company/gi) {
    var _a, _b;
    const found = (drivers === null || drivers === void 0 ? void 0 : drivers.find(driver => { var _a; return (_a = driver.user) === null || _a === void 0 ? void 0 : _a.roles.find(role => role.name.match(matchRole)); }))
        || (drivers === null || drivers === void 0 ? void 0 : drivers.find(driver => { var _a; return (_a = driver.user) === null || _a === void 0 ? void 0 : _a.roles.find(role => role.name.match(fallbackRole)); }));
    if (!found)
        return null;
    const dispatcher = Object.assign(Object.assign({}, found), { dispatcherName: `${found.firstName} ${found.familyName}`, dispatcherPrivatePhoneUrl: `tel:${found.privatePhone}`, dispatcherpublicPhoneUrl: `tel:${found.publicPhone}`, dispatcherEmail: `${(_a = found.user) === null || _a === void 0 ? void 0 : _a.username}`, dispatcherRole: (_b = found.user) === null || _b === void 0 ? void 0 : _b.roles.map(role => (0, core_model_1.__)(`Role:${role.name}`)).join(',') });
    return dispatcher;
}
const extractCompanyAddressObject = (companyMeta) => ({
    zip: (companyMeta === null || companyMeta === void 0 ? void 0 : companyMeta.companyAddressZip) ? `${companyMeta === null || companyMeta === void 0 ? void 0 : companyMeta.companyAddressZip}` : '',
    place: (companyMeta === null || companyMeta === void 0 ? void 0 : companyMeta.companyAddressCity) || '',
    street: (companyMeta === null || companyMeta === void 0 ? void 0 : companyMeta.companyAddressStreet) || '',
    houseNumber: (companyMeta === null || companyMeta === void 0 ? void 0 : companyMeta.companyAddressNumber) || '',
});
exports.extractCompanyAddressObject = extractCompanyAddressObject;
function getDispatchDestination(companyFields) {
    const { dispatchTarget, dispatcherPrivatePhoneUrl, dispatcherEmail } = companyFields;
    switch (dispatchTarget) {
        case core_model_1.AvailableNotificationSettingValues.SMS:
            return dispatcherPrivatePhoneUrl;
        case core_model_1.AvailableNotificationSettingValues.SMS_VONAGE:
            return dispatcherPrivatePhoneUrl;
        default:
            return dispatcherEmail;
    }
}
function flattenCompanyFields(company, matchRole, fallbackRole) {
    var _a;
    const dispatcher = findDispatcher(company === null || company === void 0 ? void 0 : company.drivers, matchRole, fallbackRole);
    const boss = findDispatcher(company === null || company === void 0 ? void 0 : company.drivers, /(company)/gi, fallbackRole);
    const companyFields = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, company.user), boss), dispatcher), { dispatcher }), company), (0, exports.extractCompanyAddressObject)(company)), { activeFromString: company.activeFrom && core_model_1.config.date(company.activeFrom), activeToString: company.activeTo && core_model_1.config.date(company.activeTo), type: company === null || company === void 0 ? void 0 : company.type, typeName: (0, exports.getCompanyTypeText)(company === null || company === void 0 ? void 0 : company.type), familyName: boss === null || boss === void 0 ? void 0 : boss.familyName, firstName: boss === null || boss === void 0 ? void 0 : boss.firstName, privatePhone: boss === null || boss === void 0 ? void 0 : boss.privatePhone, publicPhone: boss === null || boss === void 0 ? void 0 : boss.publicPhone, suggestionAction: (0, core_model_1.__)(((_a = company === null || company === void 0 ? void 0 : company.suggestion) === null || _a === void 0 ? void 0 : _a.action) || 'No response') });
    const companyFieldsWithDispatchInfo = Object.assign(Object.assign({}, companyFields), { dispatchTarget: (0, core_model_1.__)(company.dispatchTarget), dispatchDestination: getDispatchDestination(companyFields) });
    return companyFieldsWithDispatchInfo;
}
exports.flattenCompanyFields = flattenCompanyFields;
function flattenCompanyFieldsWithBoss(company) {
    return flattenCompanyFields(company, /(company)/gi);
}
exports.flattenCompanyFieldsWithBoss = flattenCompanyFieldsWithBoss;
function flattenCompanyList(list) {
    return list.map(company => flattenCompanyFields(company));
}
exports.flattenCompanyList = flattenCompanyList;
function getCompanyById(id) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!id)
            throw new Error(`Company fo the id ${id} were not found`);
        const url = (0, core_model_1.getApiRoutes)().companyAdmin.getById(id);
        return (0, core_model_1.request)(url);
    });
}
function useCompanyById(id) {
    const _a = (0, useAsyncAction_1.useAsyncAction)(() => __awaiter(this, void 0, void 0, function* () { return getCompanyById(id); })), { resource } = _a, result = __rest(_a, ["resource"]);
    return Object.assign(Object.assign({}, result), { company: resource || null });
}
exports.useCompanyById = useCompanyById;
