"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyCaseReportDTO = void 0;
var EmptyCaseReportDTO = (function () {
    function EmptyCaseReportDTO() {
        this.addressConfirmed = false;
    }
    return EmptyCaseReportDTO;
}());
exports.EmptyCaseReportDTO = EmptyCaseReportDTO;
