"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllowedNotificationSettingValue = exports.getAllNotificationSettingValues = exports.activeNotificationSettingsWithTwilio = exports.activeNotificationSettings = exports.defaultNotificationSettingValues = exports.isNotificationSettingValue = exports.NotificationSettingFieldNames = exports.NotificationSettingTypes = exports.isSmsNotificationValue = exports.AvailableNotificationSettingValues = void 0;
var Company_1 = require("./Company");
var AvailableNotificationSettingValues;
(function (AvailableNotificationSettingValues) {
    AvailableNotificationSettingValues["SMS"] = "sms";
    AvailableNotificationSettingValues["SMS_VONAGE"] = "sms_vonage";
    AvailableNotificationSettingValues["EMAIL"] = "email";
    AvailableNotificationSettingValues["OFF"] = "off";
})(AvailableNotificationSettingValues = exports.AvailableNotificationSettingValues || (exports.AvailableNotificationSettingValues = {}));
var isSmsNotificationValue = function (notificationSetting) { return notificationSetting === AvailableNotificationSettingValues.SMS || notificationSetting === AvailableNotificationSettingValues.SMS_VONAGE; };
exports.isSmsNotificationValue = isSmsNotificationValue;
var NotificationSettingTypes;
(function (NotificationSettingTypes) {
    NotificationSettingTypes["SND_NEW_JOB_DRIVER"] = "SND_NEW_JOB_DRIVER";
    NotificationSettingTypes["SND_NEW_JOB_DISPATCHER"] = "SND_NEW_JOB_DISPATCHER";
    NotificationSettingTypes["SND_JOB_ALLOCATION_DRIVER"] = "SND_JOB_ALLOCATION_DRIVER";
    NotificationSettingTypes["SND_JOB_ALLOCATION_DISPATCHER"] = "SND_JOB_ALLOCATION_DISPATCHER";
    NotificationSettingTypes["SND_JOB_CANCELLATION_DRIVER"] = "SND_JOB_CANCELLATION_DRIVER";
    NotificationSettingTypes["SND_JOB_CANCELLATION_DISPATCHER"] = "SND_JOB_CANCELLATION_DISPATCHER";
})(NotificationSettingTypes = exports.NotificationSettingTypes || (exports.NotificationSettingTypes = {}));
exports.NotificationSettingFieldNames = [
    NotificationSettingTypes.SND_NEW_JOB_DRIVER,
    NotificationSettingTypes.SND_NEW_JOB_DISPATCHER,
    NotificationSettingTypes.SND_JOB_ALLOCATION_DRIVER,
    NotificationSettingTypes.SND_JOB_ALLOCATION_DISPATCHER,
    NotificationSettingTypes.SND_JOB_CANCELLATION_DRIVER,
    NotificationSettingTypes.SND_JOB_CANCELLATION_DISPATCHER,
];
function isNotificationSettingValue(data) {
    var notificationSettingValue = data;
    return !!(data && (notificationSettingValue === AvailableNotificationSettingValues.EMAIL || notificationSettingValue === AvailableNotificationSettingValues.SMS || notificationSettingValue === AvailableNotificationSettingValues.OFF));
}
exports.isNotificationSettingValue = isNotificationSettingValue;
exports.defaultNotificationSettingValues = (_a = {},
    _a[Company_1.CompanyTypes.EXT] = (_b = {},
        _b[NotificationSettingTypes.SND_NEW_JOB_DRIVER] = AvailableNotificationSettingValues.OFF,
        _b[NotificationSettingTypes.SND_NEW_JOB_DISPATCHER] = AvailableNotificationSettingValues.SMS_VONAGE,
        _b[NotificationSettingTypes.SND_JOB_ALLOCATION_DRIVER] = AvailableNotificationSettingValues.SMS_VONAGE,
        _b[NotificationSettingTypes.SND_JOB_ALLOCATION_DISPATCHER] = AvailableNotificationSettingValues.OFF,
        _b[NotificationSettingTypes.SND_JOB_CANCELLATION_DRIVER] = AvailableNotificationSettingValues.SMS_VONAGE,
        _b[NotificationSettingTypes.SND_JOB_CANCELLATION_DISPATCHER] = AvailableNotificationSettingValues.OFF,
        _b),
    _a[Company_1.CompanyTypes.ADAC] = (_c = {},
        _c[NotificationSettingTypes.SND_NEW_JOB_DRIVER] = AvailableNotificationSettingValues.OFF,
        _c[NotificationSettingTypes.SND_NEW_JOB_DISPATCHER] = AvailableNotificationSettingValues.EMAIL,
        _c[NotificationSettingTypes.SND_JOB_ALLOCATION_DRIVER] = AvailableNotificationSettingValues.SMS_VONAGE,
        _c[NotificationSettingTypes.SND_JOB_ALLOCATION_DISPATCHER] = AvailableNotificationSettingValues.EMAIL,
        _c[NotificationSettingTypes.SND_JOB_CANCELLATION_DRIVER] = AvailableNotificationSettingValues.SMS_VONAGE,
        _c[NotificationSettingTypes.SND_JOB_CANCELLATION_DISPATCHER] = AvailableNotificationSettingValues.EMAIL,
        _c),
    _a);
exports.activeNotificationSettings = [
    AvailableNotificationSettingValues.EMAIL,
    AvailableNotificationSettingValues.SMS_VONAGE,
];
exports.activeNotificationSettingsWithTwilio = __spreadArray(__spreadArray([], exports.activeNotificationSettings, true), [
    AvailableNotificationSettingValues.SMS,
], false);
var getAllNotificationSettingValues = function (aditionalSettingValues) { return (__spreadArray(__spreadArray([], aditionalSettingValues, true), [
    AvailableNotificationSettingValues.OFF,
], false)); };
exports.getAllNotificationSettingValues = getAllNotificationSettingValues;
function getAllowedNotificationSettingValue(companyType, settingsName, settingValueString) {
    switch (settingValueString) {
        case AvailableNotificationSettingValues.EMAIL: return AvailableNotificationSettingValues.EMAIL;
        case AvailableNotificationSettingValues.SMS: return AvailableNotificationSettingValues.SMS;
        case AvailableNotificationSettingValues.SMS_VONAGE: return AvailableNotificationSettingValues.SMS_VONAGE;
        default: return exports.defaultNotificationSettingValues[companyType][settingsName];
    }
}
exports.getAllowedNotificationSettingValue = getAllowedNotificationSettingValue;
