"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CostItemID = void 0;
var CostItemID;
(function (CostItemID) {
    CostItemID["PROFILE_CYLINDER"] = "profilecylinder";
    CostItemID["PROFILE_CYLINDER_STANDARD"] = "profilecylinder-standard";
    CostItemID["PROFILE_CYLINDER_SPECIAL"] = "profilecylinder-special";
    CostItemID["LOCK"] = "lock";
    CostItemID["LOCK_STANDARD"] = "lock-standard";
    CostItemID["LOCK_SPECIAL"] = "lock-special";
    CostItemID["SECURITY_FITTING"] = "security-fitting";
    CostItemID["SECURITY_FITTING_STANDARD"] = "security-fitting-standard";
    CostItemID["SECURITY_FITTING_SPECIAL"] = "security-fitting-special";
    CostItemID["HARDWARE"] = "hardware";
    CostItemID["MILLING"] = "milling";
    CostItemID["INCIDENTAL_ITEMS"] = "incidentalItems";
    CostItemID["EXTRA_TIME"] = "extraTime";
    CostItemID["OTHER"] = "other";
})(CostItemID = exports.CostItemID || (exports.CostItemID = {}));
