"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.yearMonth = exports.time = exports.timestampEditFormat = exports.timestampFormat = exports.iso = exports.date = exports.dateEditFormat = exports.dateFormat = exports.isDate = void 0;
var moment = require("moment");
function isMoment(d) {
    return !!(d && d.format !== undefined);
}
function isDate(d) {
    return !!(d && d.toISOString !== undefined);
}
exports.isDate = isDate;
function makeMoment(m) {
    return isMoment(m) ? m : (moment && moment(m));
}
exports.dateFormat = process.env.REACT_APP_SIMPLE_DATE_FORMAT || 'D/M/YYYY';
exports.dateEditFormat = exports.dateFormat
    .replace(/(\W?)D+(\W?)/, '$1DD$2')
    .replace(/(\W?)M+(\W?)/, '$1MM$2')
    .replace(/(\W?)Y+(\W?)/, '$1YYYY$2');
var date = function (m, format) {
    if (format === void 0) { format = exports.dateFormat; }
    var mObj = makeMoment(m);
    if (isMoment(mObj))
        return mObj.format(format);
    return isDate(mObj) ? mObj === null || mObj === void 0 ? void 0 : mObj.toISOString().substring(0, 10) : mObj;
};
exports.date = date;
var iso = function (m) {
    var mObj = makeMoment(m);
    if (isMoment(mObj))
        return mObj.toISOString();
    return isDate(mObj) ? mObj === null || mObj === void 0 ? void 0 : mObj.toISOString() : "".concat(mObj);
};
exports.iso = iso;
exports.timestampFormat = process.env.REACT_APP_SIMPLE_TIMESTAMP_FORMAT || 'D/M/YY HH:mm';
exports.timestampEditFormat = exports.timestampFormat
    .replace(/(\W?)D+(\W?)/, '$1DD$2')
    .replace(/(\W?)M+(\W?)/, '$1MM$2')
    .replace(/(\W?)Y+(\W?)/, '$1YYYY$2');
var time = function (m) {
    var mObj = makeMoment(m);
    if (isMoment(mObj))
        return mObj.format(exports.timestampFormat);
    return isDate(mObj) ? mObj === null || mObj === void 0 ? void 0 : mObj.toISOString() : mObj;
};
exports.time = time;
var yearMonth = function (m) {
    var mObj = makeMoment(m);
    if (isMoment(mObj))
        return mObj.format('YYYY/MM');
    return isDate(mObj) ? mObj === null || mObj === void 0 ? void 0 : mObj.toISOString().substring(0, 7).replace(/-\/\\/, '/') : mObj;
};
exports.yearMonth = yearMonth;
