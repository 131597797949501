"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgDocument = (props) => (React.createElement("svg", Object.assign({ width: "16px", height: "20px", viewBox: "0 0 16 20", version: "1.1", xmlns: "http://www.w3.org/2000/svg" }, props),
    React.createElement("g", { id: "Page-1", stroke: "none", "stroke-width": "1", fill: "none", "fill-rule": "evenodd" },
        React.createElement("g", { id: "invoice", fill: "#000000", "fill-rule": "nonzero" },
            React.createElement("path", { d: "M2,0 C0.9,0 0.01,0.9 0.01,2 L0,18 C0,19.1 0.89,20 1.99,20 L14,20 C15.1,20 16,19.1 16,18 L16,6 L10,0 L2,0 Z M3,17 L3,18 L2,18 L2,17 L3,17 Z M15,17 L15,18 L4,18 L4,17 L15,17 Z M3,15 L3,16 L2,16 L2,15 L3,15 Z M11,15 L11,16 L4,16 L4,15 L11,15 Z M3,13 L3,14 L2,14 L2,13 L3,13 Z M13,13 L13,14 L4,14 L4,13 L13,13 Z M3,11 L3,12 L2,12 L2,11 L3,11 Z M10,11 L10,12 L4,12 L4,11 L10,11 Z M6,7 L6,8 L2,8 L2,7 L6,7 Z M9,1.5 L14.5,7 L9,7 L9,1.5 Z M8,5 L8,6 L2,6 L2,5 L8,5 Z M5,3 L5,4 L2,4 L2,3 L5,3 Z", id: "path-1" })))));
exports.default = SvgDocument;
