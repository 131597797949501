"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_model_1 = require("@adac/core-model");
function getIcon(iconList, name, path = 'ico.jsx') {
    var _a;
    const icons = (_a = path.split('.').reduce((accum, curr) => accum[curr], iconList)) !== null && _a !== void 0 ? _a : [];
    const key = (0, core_model_1.camelize)(`${path}-${name}`);
    if (icons && icons[key]) {
        return icons && icons[key];
    }
    if (name !== 'empty') {
        console.error('<Icon> not found per name', key);
    }
    return null;
}
exports.default = getIcon;
