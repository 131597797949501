"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatEstimationReport = exports.formatFullReport = exports.getReportValuesForBI = exports.createRow = exports.getFeedbackAnswersFromReport = exports.getMinuteStringFromJSON = void 0;
var __1 = require("..");
var datetime_1 = require("../config/datetime");
var file_1 = require("./file");
var getMinuteStringFromJSON = function (json) { return (json ? "".concat((+((json.seconds || 0) / 60) + +(json.minutes || 0) + +((json.hours || 0) * 60)).toFixed(2), " min") : null); };
exports.getMinuteStringFromJSON = getMinuteStringFromJSON;
var getFeedbackAnswersFromReport = function (feedback, fomatter) {
    if (fomatter === void 0) { fomatter = function (s) { return s; }; }
    if (feedback) {
        var feedbackJsonString = Buffer.isBuffer(feedback) ? Buffer.from(feedback).toString() : '';
        if (!feedbackJsonString)
            return [];
        var json = JSON.parse(feedbackJsonString);
        return json.map(function (feedbackItem) { return fomatter("".concat(feedbackItem.answer)); });
    }
    return new Array(5);
};
exports.getFeedbackAnswersFromReport = getFeedbackAnswersFromReport;
var sep = {
    row: '\n',
    cell: ';',
    text: '"',
};
function createStringCell(cell) {
    return "".concat(sep.text).concat("".concat(cell).replace(sep.cell, '')).concat(sep.text);
}
function createAnyCell(cell) {
    return createStringCell("".concat(cell));
}
function createRow(row, formatFields) {
    if (formatFields === void 0) { formatFields = Object.values; }
    return formatFields(row).map(function (cell) { return createAnyCell(cell); })
        .join(sep.cell);
}
exports.createRow = createRow;
function createHeader(header, separator) {
    if (separator === void 0) { separator = sep.cell; }
    return header.split(separator)
        .map(function (key) { return createStringCell((0, __1.__)(key)); })
        .join(sep.cell);
}
var extractBasicCaseReportInfo = function (rep) { return ([
    rep.id,
    rep.CommissioningReference,
    "".concat(rep.description).replace(/;/g, '.'),
    rep.companyName,
    rep.driverName,
    rep.FirstName,
    rep.FamilyName,
    rep.Address,
    rep.ZipCode,
    rep.City,
]); };
var extractExtraCaseReportInfo = function (rep) { return ([
    (0, exports.getMinuteStringFromJSON)(rep['Waiting time']),
    rep['Driver arrived at location'],
    rep.firstSignature,
    rep.lastSignature,
    (0, __1.__)("report:status:".concat(rep.lastStatus)),
    rep.price,
    (0, __1.__)("".concat(rep.Cancellation)).replace(/;/g, '.'),
    (0, __1.__)("".concat(rep['Cancellation reason'])).replace(/;/g, '.'),
    (0, __1.__)("report:".concat(rep.CancelledEvent)),
    (0, __1.__)("cancelledBy:".concat(rep.CancelledByUserRole)),
]); };
var getReportValuesForHumans = function (rep) {
    var _a;
    return (__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([
        (0, __1.__)("".concat(rep.ProductType)),
        (0, __1.__)("".concat(rep.ProductNumber)),
        (0, __1.__)("report:complexity:".concat(rep.complexity))
    ], extractBasicCaseReportInfo(rep), true), [
        (0, __1.__)("".concat(rep['Entry Channel'])),
        rep['Date of order entry'],
        rep['Time of order entry'],
        rep['User involved']
    ], false), extractExtraCaseReportInfo(rep), true), [
        rep.CancelledAt
    ], false), (0, exports.getFeedbackAnswersFromReport)(rep.feedback), true), [
        "".concat((0, file_1.urlifyFileName)((_a = rep.FinalReport) === null || _a === void 0 ? void 0 : _a.billing, 'pdf')),
    ], false));
};
var getReportValuesForBI = function (rep) {
    var _a;
    return (__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([
        rep.ProductType,
        rep.ProductNumber,
        (0, __1.__)("report:complexity:".concat(rep.complexity))
    ], extractBasicCaseReportInfo(rep), true), [
        (0, __1.__)("".concat(rep['Entry Channel'])),
        rep['Date of order entry'],
        rep['Time of order entry'],
        rep['User involved']
    ], false), extractExtraCaseReportInfo(rep), true), [
        (0, datetime_1.isDate)(rep.CancelledAt) ? rep.CancelledAt.toISOString() : "".concat(rep.CancelledAt)
    ], false), (0, exports.getFeedbackAnswersFromReport)(rep.feedback, __1.stripSpecialCharacters), true), [
        "".concat((0, file_1.urlifyFileName)((_a = rep.FinalReport) === null || _a === void 0 ? void 0 : _a.billing, 'pdf')),
    ], false));
};
exports.getReportValuesForBI = getReportValuesForBI;
var formatFullReport = function (report, format) {
    var reportRows = report.rows;
    var formatRow = format.match(/bi/i) ? exports.getReportValuesForBI : getReportValuesForHumans;
    var header = createHeader('ProductType,ProductNumber,DoorComplexity,ID,CommissioningReference,AdditionalNote,Company name,Driver Name,customerFirstname,customerFamilyName,customerAddressStreet,customerAddressZip,customerAddressCity,Entry Channel,Date of order entry,Time of entry,User involved,Waiting time,Driver arrived at location,First signature,Last signature,Last Status,Price,Cancelled,Reason,CancelledEvent,CancelledByUserRole,CancelledAt,Customer Feedback Question 1,Customer Feedback Question 2,Customer Feedback Question 3,Customer Feedback Question 4,Customer Feedback Open Text,FinalReport', ',');
    var rows = reportRows.map(function (row) { return createRow(row, formatRow); });
    var reportStr = rows.join(sep.row);
    var csv = header.concat(sep.row).concat(reportStr);
    return csv;
};
exports.formatFullReport = formatFullReport;
var formatEstimationReport = function (report) {
    var estimations = report.rows;
    var header = createHeader('Case Id,Company ID,Company name,Driver ID,createdAt,Month,Zeit bis zur Ankunft beim Kunden,actionType', ',');
    var rows = estimations.map(function (estimation) { return createRow(__assign(__assign({}, estimation), { action: (0, __1.__)("status:SND_TIMESUGGESTION_".concat(estimation.action.toUpperCase())) })); });
    var reportStr = rows.join(sep.row);
    var csv = header.concat(sep.row).concat(reportStr);
    return csv;
};
exports.formatEstimationReport = formatEstimationReport;
