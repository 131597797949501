"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminEntityEnum = void 0;
var AdminEntityEnum;
(function (AdminEntityEnum) {
    AdminEntityEnum["SND_COMPANY"] = "SndCompany";
    AdminEntityEnum["SND_DRIVER"] = "SndDriver";
    AdminEntityEnum["ZIP_CODE"] = "ZipCode";
    AdminEntityEnum["HOLIDAY"] = "Holiday";
    AdminEntityEnum["SETTINGS"] = "Settings";
    AdminEntityEnum["USER"] = "User";
    AdminEntityEnum["REPORT"] = "Report";
    AdminEntityEnum["CASE"] = "Case";
    AdminEntityEnum["CASELOG"] = "CaseLog";
})(AdminEntityEnum = exports.AdminEntityEnum || (exports.AdminEntityEnum = {}));
