"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decipherTokenIfNeeded = void 0;
var cipher_1 = require("./cipher");
function decipherTokenIfNeeded(token) {
    var actualToken = token;
    var companyId;
    if (token.substr(0, 1) === '+') {
        var decipherFunc = (0, cipher_1.decipher)(process.env.REACT_APP_COMPANY_ID_CIPHER || '07b03a43fce2bbb506ae8ce970e3bbf2');
        var decipheredString = decipherFunc(token.substr(1));
        var splitStrings = decipheredString.split(' ');
        companyId = splitStrings[0];
        actualToken = splitStrings[1] || '';
    }
    return [actualToken, companyId];
}
exports.decipherTokenIfNeeded = decipherTokenIfNeeded;
