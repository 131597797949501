"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgVonage = (props) => (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 225, height: 200, viewBox: "0 0 225 200", fill: "none" }, props),
    React.createElement("path", { fill: "#131415", d: "M40.341 0H-5l64.681 146.958c.494 1.123 2.091 1.112 2.57-.016l21.512-50.605L40.341 0ZM178.45 0s-69.494 159.156-78.802 173.833c-10.819 17.063-17.989 23.576-31.257 25.663a.253.253 0 0 0-.214.25c0 .14.114.254.254.254h41.524c17.988 0 30.96-15.021 38.149-28.286C156.274 156.637 224.59 0 224.59 0h-46.14Z" })));
exports.default = SvgVonage;
