"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCATION_NOT_FOUND = exports.SERVICE_UNAVAILABLE = exports.SERVER_ERROR = exports.VERSION_ERROR = exports.VALIDATION_ERROR = exports.PAGE_NOT_FOUND = exports.FORBIDDEN_ERROR = exports.UNAUTHORIZED_ERROR = exports.BAD_REQUEST = void 0;
exports.BAD_REQUEST = 'BadRequest';
exports.UNAUTHORIZED_ERROR = 'UnauthorizedError';
exports.FORBIDDEN_ERROR = 'ForbiddenError';
exports.PAGE_NOT_FOUND = 'PageNotFound';
exports.VALIDATION_ERROR = 'ValidationError';
exports.VERSION_ERROR = 'VersionError';
exports.SERVER_ERROR = 'ServerError';
exports.SERVICE_UNAVAILABLE = 'Service Unavailable';
exports.LOCATION_NOT_FOUND = 'LocationNotFound';
