"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.privacyLinks = exports.tacItems = exports.allTacItems = void 0;
var routes_1 = require("../networking/routes");
var Case_1 = require("../types/Case");
exports.allTacItems = [
    'acceptTAC', 'acceptWithdrawal', 'acceptPrivacyPolicy', 'confirmAuthorization',
];
exports.tacItems = (_a = {},
    _a[Case_1.ProductTypes.STANDARD] = ['acceptTAC', 'acceptWithdrawal', 'acceptPrivacyPolicy', 'confirmAuthorization'],
    _a[Case_1.ProductTypes.PREMIUM] = ['acceptPrivacyPolicy', 'confirmAuthorization'],
    _a[Case_1.ProductTypes.INSURANCE] = ['confirmAuthorization'],
    _a);
exports.privacyLinks = (_b = {},
    _b[Case_1.ProductTypes.STANDARD] = "".concat((0, routes_1.getCustomerRoutes)().staticAsset.pdfRoot, "/ADAC_Schluesselnotdienst_Datenschutz_PDF_PUB_03_24.pdf"),
    _b[Case_1.ProductTypes.PREMIUM] = 'https://www.adac.de/datenschutz/versicherungen/',
    _b[Case_1.ProductTypes.INSURANCE] = undefined,
    _b);
