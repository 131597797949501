"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.i18nTemplate = exports.defaultNullableI18n = void 0;
var i18n_helpers_1 = require("./i18n-helpers");
var localization = require("../config/i18n.json");
var defaultI18n = function (title, language, myLocalization) {
    if (language === void 0) { language = process.env.REACT_APP_LANGUAGE || ''; }
    if (myLocalization === void 0) { myLocalization = localization; }
    return (0, i18n_helpers_1.i18n)(title, language, myLocalization);
};
var defaultNullableI18n = function (title, language, myLocalization) {
    if (language === void 0) { language = process.env.REACT_APP_LANGUAGE || ''; }
    if (myLocalization === void 0) { myLocalization = localization; }
    return (0, i18n_helpers_1.nullableI18n)(title, language, myLocalization);
};
exports.defaultNullableI18n = defaultNullableI18n;
function i18nTemplate(errorMessage, templateValues) {
    var template = (0, exports.defaultNullableI18n)(errorMessage);
    if (!template)
        return errorMessage;
    var getValue = function (key) { return (typeof templateValues[key] === 'string' ? defaultI18n(String(templateValues[key])) : String(templateValues[key])); };
    var templatedTranslation = Object.keys(templateValues)
        .reduce(function (templateString, key) { return templateString.replace("{".concat(key, "}"), getValue(key)); }, template);
    return templatedTranslation;
}
exports.i18nTemplate = i18nTemplate;
exports.default = defaultI18n;
