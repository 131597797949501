import React from 'react';
import { __ } from '@adac/core-model';
import { Modal, Text, Title } from '@adac/core-view';
import styled from 'styled-components';

const InfoDescriptionText = styled(Text)`
  padding: 5px 10px;
`;

const ModalStyles = styled(Modal)`
  width: 80%;
`

interface InfoModalProps {
  visible: boolean;
  onClose: () => void;
}

export function InfoModal({ visible, onClose }: InfoModalProps) {
  return (
    <ModalStyles on={visible} close={onClose}>
      <Title>{__('Info')}</Title>
      <InfoDescriptionText>{__('Extra cost information')}</InfoDescriptionText>
    </ModalStyles>
  );
}
