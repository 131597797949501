"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCiphered = exports.cipherSndOfferLink = exports.cipherLink = exports.decipher = exports.cipher = void 0;
var routes_1 = require("../networking/routes");
var textToChars = function (text) { return text.split('').map(function (c) { return c.charCodeAt(0); }); };
var applySaltToChar = function (salt) { return function (code) { return textToChars(salt).reduce(function (a, b) { return (a ^ b); }, code); }; };
var cipher = function (salt) {
    var byteHex = function (n) { return ("0".concat(Number(n).toString(16))).substr(-2); };
    return function (text) { return textToChars(text)
        .map(applySaltToChar(salt))
        .map(byteHex)
        .join(''); };
};
exports.cipher = cipher;
var decipher = function (salt) { return function (encoded) { return (encoded.match(/.{1,2}/g) || [''])
    .map(function (hex) { return parseInt(hex, 16); })
    .map(applySaltToChar(salt))
    .map(function (charCode) { return String.fromCharCode(charCode); })
    .join(''); }; };
exports.decipher = decipher;
var cipherLink = function (companyId, token) {
    var cipherFunc = (0, exports.cipher)(process.env.COMPANY_ID_CIPHER || '07b03a43fce2bbb506ae8ce970e3bbf2');
    return "+".concat(cipherFunc("".concat(String(companyId), " ").concat(token)));
};
exports.cipherLink = cipherLink;
var cipherSndOfferLink = function (companyId, token) {
    var saltedLink = (0, exports.cipherLink)(companyId, token);
    return (0, routes_1.getApiRoutes)(undefined, process.env.REACT_APP_SND_DRIVER_PORTAL_URL).partner.case(saltedLink).offerDispatch(companyId);
};
exports.cipherSndOfferLink = cipherSndOfferLink;
var isCiphered = function (text) { return /\+[0-9a-f]+/.test(text); };
exports.isCiphered = isCiphered;
