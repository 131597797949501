import React, { useContext } from 'react';

import {
  FlexView, Title,
  useLocation,
  Button,
  Page,
  PostStore,
  sndRejectOffer,
} from '@adac/core-view';
import {
  CaseStatusList,
  __,
} from '@adac/core-model';

import * as yup from 'yup';

import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import styled from 'styled-components';
import StoreContext from '../../stores';
import CancellationForm from './CancellationForm';


export interface CancellationFormValues {
  reason: string;
  detailedReason: string;
}

export const CancellationSchema = yup.object().shape({
  reason: yup.string()
    .required(),
  detailedReason: yup.string().when('reason', {
    is: __('reason:Other'),
    then: schema => schema.min(10).required(),
    otherwise: schema => schema.optional(),
  }),
});

const HorizontalButtonContainer = styled(FlexView)`
  bottom: 0;
  width: 100%;
  & > * {
    margin-right: 8px;
    flex-grow: 1;
  }
`;

const RejectContainer = styled(Page)`
  padding: 14px;
  background: white;
`;

interface RejectCaseProps {
  onReject: (props: any) => Promise<any>;
  onCancel?: () => void;
}
const rejectReasons = [
  {
    id: __('reason:OutOfFittingOperationRessource'),
    title: __('reason:OutOfFittingOperationRessource'),
    value: 'OutOfFittingOperationRessource',
  },
  {
    id: __('reason:RoadAccessTimeGreater60'),
    title: __('reason:RoadAccessTimeGreater60'),
    value: 'RoadAccessTimeGreater60',
  },
  {
    id: __('reason:RoadAccessTimeGreater120'),
    title: __('reason:RoadAccessTimeGreater120'),
    value: 'RoadAccessTimeGreater120',
  },
];


export const useRejectCaseOnDispatching = () => {
  const { case: caseStore } = useContext(StoreContext);
  const { goTo, goBack } = useLocation();

  const onReject = async (onRejectProps: {reason: string}) => {
    const sndRejectOfferStore = new PostStore(sndRejectOffer);
    if (!caseStore.currentCompanyId) throw new Error(__('Company cannot be identified'));
    const item = await sndRejectOfferStore.postItem(caseStore.token, { ...onRejectProps, companyId: caseStore.currentCompanyId });
    const status = await caseStore.handleStoreAction(sndRejectOfferStore, item);
    if (status === `${CaseStatusList.CASE_COMPLETED}`) {
      goTo('/offer/rejected');
    }
  };

  return {
    onReject,
    onCancel: goBack,
  };
};


export const RejectCaseOnDispatching = () => {
  const { onReject, onCancel } = useRejectCaseOnDispatching();
  return (
    <RejectContainer>
      <Title center margin> {__('Why do you want to cancel the case?')}</Title>

      <Formik
        initialValues={{ reason: rejectReasons[0].value, detailedReason: '' }}
        isInitialValid
        validationSchema={CancellationSchema}
        onSubmit={async (values: CancellationFormValues, actions: FormikActions<CancellationFormValues>) => {
          await onReject({ reason: values.detailedReason || values.reason });
          actions.setSubmitting(false);
        }}
      >
        {({
          isValid, isSubmitting, errors, values,
        }: FormikProps<CancellationFormValues>) => {
          console.log(isValid, isSubmitting, errors, values);
          return (
            <CancellationForm cancellationReasons={rejectReasons}>
              <HorizontalButtonContainer end row>
                <Button isLoading={isSubmitting} disabled={!isValid || isSubmitting} title="Ja" info type="submit" />
                <Button title="Nein" cta onClick={onCancel} />
              </HorizontalButtonContainer>
            </CancellationForm>
          );
        }}
      </Formik>
    </RejectContainer>
  );
};


export const RejectCaseOnComissionOpening = ({ onReject, onCancel }: RejectCaseProps) => (
  <FlexView column center padding>
    <Title center margin>{__('areYouSureYouDecline')}</Title>
    <HorizontalButtonContainer center between row>
      <Button title="Ja" info onClick={onReject} />
      <Button title="Nein" cta onClick={onCancel} />
    </HorizontalButtonContainer>
  </FlexView>
);
