"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.footerLinks = void 0;
const core_model_1 = require("@adac/core-model");
exports.footerLinks = {
    nav: [],
    footer: [
        {
            id: 'footer-impressum',
            route: (0, core_model_1.getAdacLinks)().impressum,
            title: 'Impressum',
        },
        {
            id: 'footer-datenschutz',
            route: (0, core_model_1.getAdacLinks)().dataprivacy,
            title: 'Datenschutz',
        },
        {
            id: 'footer-cookie',
            route: (0, core_model_1.getAdacLinks)().cookie,
            title: 'Cookie Einstellungen',
        },
    ],
};
