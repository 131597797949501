import { CaseDTO, __, getApiRoutes, request } from "@adac/core-model";
import { useContext } from "react";

import StoreContext from '../stores';

export function useWorkflowCallCustomerAction<Data>() {
  const { case: caseStore } = useContext(StoreContext);
  
  return async (data: Data) => {
    if (!caseStore.currentCompanyId) throw new Error(__('Company cannot be identified'));
    await request<CaseDTO, Data>(getApiRoutes().partner.case(caseStore.token).action.callCustomer(), 'POST', {}, data);
  }
};
