"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFinalReportTypeFromPathname = exports.extractCaseComplexityProps = exports.extractCustomerContactProps = exports.extractConfirmedAddressObject = exports.extractConfirmedAddressProps = exports.extractCustomerAddressProps = exports.getCustomerZip = exports.extractCustomerPersonProps = exports.extractCommissioningReference = exports.extractLocationCoords = exports.extractAddressLocationNameContactObject = exports.extractAddressLocationNameObject = exports.extractIdObject = exports.extractContactObject = exports.extractPersonObject = exports.extractAddressObject = exports.createAddressString = exports.isCustomerProps = exports.isLocationProps = exports.getOfferText = void 0;
const core_model_1 = require("@adac/core-model");
const getOfferText = (date, prefix = '') => `Zeitpunkt der Beauftragung im aktuellen Fall:
  ${prefix} ${(0, core_model_1.formatLocaleTimestamp)(date)}`;
exports.getOfferText = getOfferText;
function isLocationProps(data) {
    return Object.keys(data).includes('zip');
}
exports.isLocationProps = isLocationProps;
function isCustomerProps(data) {
    return Object.keys(data).includes('customerAddressCity');
}
exports.isCustomerProps = isCustomerProps;
const isEmptyAddress = (customerData) => !customerData.zip && !customerData.street && !customerData.city && !customerData.houseNumber;
const createAddressString = (customerData) => {
    if (customerData) {
        if (isLocationProps(customerData) && isEmptyAddress(customerData))
            return '-';
        if (isLocationProps(customerData))
            return `${customerData.street || ''} ${customerData.houseNumber || ''}, ${customerData.zip || ''} ${customerData.place || ''}`;
        if (isCustomerProps(customerData))
            return `${customerData.customerAddressStreet || ''} ${customerData.customerAddressNumber || ''}, ${customerData.customerAddressZip || ''} ${customerData.customerAddressCity || ''}`;
    }
    return '';
};
exports.createAddressString = createAddressString;
const extractAddressObject = (caseMeta) => ({
    zip: (caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerAddressZip) ? `${caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerAddressZip}` : '',
    place: (caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerAddressCity) || '',
    street: (caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerAddressStreet) || '',
    houseNumber: (caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerAddressNumber) || '',
});
exports.extractAddressObject = extractAddressObject;
const extractPersonObject = (caseMeta) => ({
    title: (caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.title) || '',
    firstName: (caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerFirstName) || '',
    lastName: (caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerFamilyName) || '',
});
exports.extractPersonObject = extractPersonObject;
const extractContactObject = (caseMeta) => (Object.assign({ phone: (caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerPhone) || '' }, ((caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerEmail) ? { email: caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.customerEmail } : {})));
exports.extractContactObject = extractContactObject;
const extractIdObject = (caseMeta) => ({
    id: caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.id,
    token: caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.token,
    cypheredToken: caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.cypheredToken,
    commissioningReference: caseMeta === null || caseMeta === void 0 ? void 0 : caseMeta.commissioningReference,
});
exports.extractIdObject = extractIdObject;
const extractAddressLocationNameObject = (caseMeta) => (Object.assign(Object.assign(Object.assign({}, (0, exports.extractAddressObject)(caseMeta)), (0, exports.extractPersonObject)(caseMeta)), (0, core_model_1.extractLocationCoordsWithConfidence)(caseMeta)));
exports.extractAddressLocationNameObject = extractAddressLocationNameObject;
const extractAddressLocationNameContactObject = (caseMeta) => (Object.assign(Object.assign({}, (0, exports.extractAddressLocationNameObject)(caseMeta)), (0, exports.extractContactObject)(caseMeta)));
exports.extractAddressLocationNameContactObject = extractAddressLocationNameContactObject;
const extractLocationCoords = (props) => ({
    latitude: props.latitude,
    longitude: props.longitude,
});
exports.extractLocationCoords = extractLocationCoords;
const extractCommissioningReference = (props) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        commissioningReference: ((_b = (_a = props === null || props === void 0 ? void 0 : props.commissioningReference) === null || _a === void 0 ? void 0 : _a.split('/')) === null || _b === void 0 ? void 0 : _b[0]) || '',
        contactManagerId: `${(_d = (_c = props === null || props === void 0 ? void 0 : props.commissioningReference) === null || _c === void 0 ? void 0 : _c.split('/')) === null || _d === void 0 ? void 0 : _d[1]}/${(_f = (_e = props === null || props === void 0 ? void 0 : props.commissioningReference) === null || _e === void 0 ? void 0 : _e.split('/')) === null || _f === void 0 ? void 0 : _f[2]}` || '',
    });
};
exports.extractCommissioningReference = extractCommissioningReference;
function extractCustomerPersonProps({ title, firstName, lastName, }) {
    return {
        title: title || 'maleTitle',
        customerFirstName: firstName,
        customerFamilyName: lastName,
    };
}
exports.extractCustomerPersonProps = extractCustomerPersonProps;
const getCustomerZip = (zipCode) => zipCode || '';
exports.getCustomerZip = getCustomerZip;
function extractCustomerAddressProps({ zip, place, street, houseNumber, }) {
    return {
        customerAddressZip: (0, exports.getCustomerZip)(zip),
        customerAddressCity: place,
        customerAddressStreet: street,
        customerAddressNumber: houseNumber,
    };
}
exports.extractCustomerAddressProps = extractCustomerAddressProps;
function extractConfirmedAddressProps({ zip, place, street, houseNumber, addressConfirmed, }) {
    return {
        addressConfirmed,
        confirmedAddressZip: (0, exports.getCustomerZip)(zip),
        confirmedAddressCity: place,
        confirmedAddressStreet: street,
        confirmedAddressNumber: houseNumber,
    };
}
exports.extractConfirmedAddressProps = extractConfirmedAddressProps;
function extractConfirmedAddressObject(props) {
    if (!props)
        return undefined;
    const { addressConfirmed, confirmedAddressZip, confirmedAddressCity, confirmedAddressStreet, confirmedAddressNumber, } = props;
    return !addressConfirmed && confirmedAddressZip && confirmedAddressCity && confirmedAddressStreet && confirmedAddressNumber ? {
        zip: confirmedAddressZip,
        place: confirmedAddressCity,
        street: confirmedAddressStreet,
        houseNumber: confirmedAddressNumber,
    } : undefined;
}
exports.extractConfirmedAddressObject = extractConfirmedAddressObject;
function extractCustomerContactProps({ phone, email, }) {
    return Object.assign({ customerPhone: phone }, (email ? { customerEmail: email } : {}));
}
exports.extractCustomerContactProps = extractCustomerContactProps;
function extractCaseComplexityProps({ complexity, }) {
    return {
        complexity,
    };
}
exports.extractCaseComplexityProps = extractCaseComplexityProps;
const getFinalReportTypeFromPathname = (pathname) => { var _a; return (_a = pathname.match(/(partner)|(billing)|(customer)/gi)) === null || _a === void 0 ? void 0 : _a[0]; };
exports.getFinalReportTypeFromPathname = getFinalReportTypeFromPathname;
