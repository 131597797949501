import { useEffect } from 'react';
import { CaseStore, useParams } from '@adac/core-view';
import { CaseDTO } from '@adac/core-model';


export function useStatusListeners(caseStore: CaseStore, callback?: (data: CaseDTO) => void) {
  const params = useParams();

  useEffect(() => {
    caseStore.initSocketListeners(callback);
    return () => {
      caseStore.closeSocketListeners();
    };
  }, [callback, caseStore, params.root_path]);
}
