"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCancelPrice = exports.getBasePrice = exports.getBasePriceType = exports.getBasePriceDate = exports.getBasePriceTypeFromAmount = void 0;
var prices_1 = require("../config/prices");
var Price_1 = require("../types/Price");
var moment = require('moment');
var getBasePriceTypeFromAmount = function (amount) {
    if (prices_1.priceConfig.WEEKDAY.find(function (obj) { return obj.value === Number(amount); }))
        return Price_1.PriceTypes.WEEKDAY;
    if (prices_1.priceConfig.OTHER.find(function (obj) { return obj.value === Number(amount); }))
        return Price_1.PriceTypes.OTHER;
    throw new Error('Base price type not found');
};
exports.getBasePriceTypeFromAmount = getBasePriceTypeFromAmount;
var getBasePriceDate = function (startingTime) { return moment(startingTime); };
exports.getBasePriceDate = getBasePriceDate;
var getBasePriceType = function (allHolidays, regionId, startingTime) {
    var date = (0, exports.getBasePriceDate)(startingTime);
    var weekDay = date.isoWeekday();
    var hours = date.hours();
    var minutes = date.minutes();
    var seconds = date.seconds();
    if (weekDay === 6 || weekDay === 7 || (hours === 6 && minutes < 1) || hours < 6 || hours > 20 || (hours === 20 && (minutes > 0 || seconds > 0))) {
        return Price_1.PriceTypes.OTHER;
    }
    var year = date.year();
    var month = date.month() + 1;
    var day = date.date();
    var allHolidaysWithYear = allHolidays.map(function (holiday) { return (holiday.year ? holiday : __assign(__assign({}, holiday), { year: year })); });
    var cityHolidays = allHolidaysWithYear.filter(function (holiday) { return holiday.regionId; });
    var holidays = allHolidaysWithYear.filter(function (holiday) { return !holiday.regionId; });
    if (holidays.find(function (holiday) { return holiday.year === year && holiday.month === month && holiday.day === day; })) {
        return Price_1.PriceTypes.OTHER;
    }
    if (regionId !== undefined && cityHolidays.find(function (holiday) { return holiday.year === year
        && holiday.month === month
        && holiday.day === day
        && holiday.regionId === regionId; })) {
        return Price_1.PriceTypes.OTHER;
    }
    return Price_1.PriceTypes.WEEKDAY;
};
exports.getBasePriceType = getBasePriceType;
var getBasePrice = function (holidays, regionId, startingTime) {
    var prices = (0, prices_1.getPrices)(startingTime);
    return prices[(0, exports.getBasePriceType)(holidays, regionId, startingTime)];
};
exports.getBasePrice = getBasePrice;
var getCancelPrice = function (startingTime, compareTime, thresholdMins) {
    if (compareTime === void 0) { compareTime = new Date(Date.now()); }
    if (thresholdMins === void 0) { thresholdMins = Number(process.env.CANCEL_PRICE_TRESHOLD_MINUTES) || 10; }
    var prices = (0, prices_1.getPrices)(startingTime);
    var thresholdMsec = thresholdMins * 60 * 1000;
    if (!startingTime || !compareTime || compareTime.getTime() - startingTime.getTime() < thresholdMsec)
        return 0;
    return prices.CANCELFEE;
};
exports.getCancelPrice = getCancelPrice;
