"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.i18nTemplate = exports.defaultNullableI18n = exports.__ = exports.ShortIdPatternString = exports.ClientValidationError = exports.ValidationError = exports.HTTPError = exports.NetworkError = exports.isValidLandlinePhoneNumber = exports.isValidMobilePhoneNumber = exports.sanitizePhoneNumber = exports.validatePhoneWithSMSField = exports.reportSelectors = exports.axios = void 0;
var axios_1 = require("axios");
Object.defineProperty(exports, "axios", { enumerable: true, get: function () { return axios_1.default; } });
__exportStar(require("./helpers/string-helpers"), exports);
__exportStar(require("./helpers/i18n-helpers"), exports);
__exportStar(require("./helpers/localStorage"), exports);
__exportStar(require("./helpers/number-helpers"), exports);
__exportStar(require("./helpers/object-helpers"), exports);
var reportSelectors_1 = require("./helpers/reportSelectors");
Object.defineProperty(exports, "reportSelectors", { enumerable: true, get: function () { return reportSelectors_1.default; } });
__exportStar(require("./helpers/reportSelectors"), exports);
__exportStar(require("./helpers/cipher"), exports);
__exportStar(require("./helpers/getServicePrice"), exports);
__exportStar(require("./helpers/file"), exports);
__exportStar(require("./helpers/tokenHelpers"), exports);
var validatePhone_1 = require("./helpers/validatePhone");
Object.defineProperty(exports, "validatePhoneWithSMSField", { enumerable: true, get: function () { return validatePhone_1.validatePhoneWithSMSField; } });
Object.defineProperty(exports, "sanitizePhoneNumber", { enumerable: true, get: function () { return validatePhone_1.sanitizePhoneNumber; } });
Object.defineProperty(exports, "isValidMobilePhoneNumber", { enumerable: true, get: function () { return validatePhone_1.isValidMobilePhoneNumber; } });
Object.defineProperty(exports, "isValidLandlinePhoneNumber", { enumerable: true, get: function () { return validatePhone_1.isValidLandlinePhoneNumber; } });
__exportStar(require("./helpers/validateEmail"), exports);
__exportStar(require("./helpers/formatDuration"), exports);
__exportStar(require("./helpers/catchError"), exports);
__exportStar(require("./helpers/csv"), exports);
__exportStar(require("./helpers/settings-helpers"), exports);
__exportStar(require("./helpers/zip-helpers"), exports);
__exportStar(require("./helpers/company-helpers"), exports);
__exportStar(require("./helpers/socket-helpers"), exports);
__exportStar(require("./helpers/case-helpers"), exports);
__exportStar(require("./helpers/role-helpers"), exports);
__exportStar(require("./errors"), exports);
__exportStar(require("./types/User"), exports);
__exportStar(require("./types/I18NError"), exports);
__exportStar(require("./types/LocationError"), exports);
__exportStar(require("./types/LocationAPI"), exports);
__exportStar(require("./types/Location"), exports);
__exportStar(require("./types/Region"), exports);
__exportStar(require("./types/ZipCode"), exports);
__exportStar(require("./types/Company"), exports);
__exportStar(require("./types/NotificationCenter"), exports);
__exportStar(require("./types/UserRoles"), exports);
__exportStar(require("./types/Report"), exports);
__exportStar(require("./types/Settings"), exports);
__exportStar(require("./types/Entity"), exports);
__exportStar(require("./types/Socket"), exports);
__exportStar(require("./types/Method"), exports);
__exportStar(require("./types/AdminLog"), exports);
__exportStar(require("./types/Database"), exports);
__exportStar(require("./types/Document"), exports);
__exportStar(require("./types/Case"), exports);
__exportStar(require("./types/CustomerData"), exports);
__exportStar(require("./types/CaseLog"), exports);
__exportStar(require("./types/CaseComment"), exports);
__exportStar(require("./types/CaseDTO"), exports);
__exportStar(require("./types/CaseReportDTO"), exports);
__exportStar(require("./types/Person"), exports);
__exportStar(require("./types/Mfa"), exports);
var NetworkError_1 = require("./types/NetworkError");
Object.defineProperty(exports, "NetworkError", { enumerable: true, get: function () { return NetworkError_1.NetworkError; } });
__exportStar(require("./types/Message"), exports);
__exportStar(require("./types/Price"), exports);
__exportStar(require("./types/Holiday"), exports);
__exportStar(require("./types/Children"), exports);
__exportStar(require("./networking/common"), exports);
var HTTPError_1 = require("./networking/HTTPError");
Object.defineProperty(exports, "HTTPError", { enumerable: true, get: function () { return HTTPError_1.default; } });
__exportStar(require("./networking/location"), exports);
__exportStar(require("./networking/login"), exports);
__exportStar(require("./networking/resetPassword"), exports);
__exportStar(require("./networking/changePassword"), exports);
__exportStar(require("./networking/changePhone"), exports);
__exportStar(require("./networking/user"), exports);
__exportStar(require("./networking/driver"), exports);
__exportStar(require("./networking/routes"), exports);
var ValidationError_1 = require("./networking/ValidationError");
Object.defineProperty(exports, "ValidationError", { enumerable: true, get: function () { return ValidationError_1.default; } });
Object.defineProperty(exports, "ClientValidationError", { enumerable: true, get: function () { return ValidationError_1.ClientValidationError; } });
__exportStar(require("./networking/defaultCreateCustomError"), exports);
__exportStar(require("./networking/invoice"), exports);
__exportStar(require("./networking/notificationSettings"), exports);
__exportStar(require("./adac-states/case-events"), exports);
__exportStar(require("./adac-states/case-event-data"), exports);
exports.ShortIdPatternString = '([\\w-]+)';
__exportStar(require("./config"), exports);
var i18n_1 = require("./helpers/i18n");
Object.defineProperty(exports, "__", { enumerable: true, get: function () { return i18n_1.default; } });
Object.defineProperty(exports, "defaultNullableI18n", { enumerable: true, get: function () { return i18n_1.defaultNullableI18n; } });
Object.defineProperty(exports, "i18nTemplate", { enumerable: true, get: function () { return i18n_1.i18nTemplate; } });
