import { observable, action, computed } from 'mobx';
import { fileToBase64, resizeImage } from '@adac/core-view';
export interface PhotoUploadedByUser {
  file?: File;
  url: string;
  description?: string;
}
export interface PhotoFromBackend {
  image: string;
  text: string;
}

/**
 * The DoorPhotoStore class is responsible for managing photos uploaded by the user in the states: 
 * CommissionConfirm, CommissionExtraDamage, and FinalReport.
 *
 * Note: Avoid modifying the `photosForPost` `convertPhotos` and `convertPhoto` functions to prevent potential image compression bugs 
 * (see https://github.com/finnoconsult/adac-openhouse/pull/1652 for details).
 */
export default class DoorPhotoStore {
  @observable public photosUploadedByUser: PhotoUploadedByUser[] = [];
 
  @observable private photosFromBackend: PhotoFromBackend[] = [];
 
  @computed public get photosForPost() {
    return this.photosFromBackend;
  }

  @action public addDoorPhoto(doorPhoto: PhotoUploadedByUser) {
    this.photosUploadedByUser = [...this.photosUploadedByUser, doorPhoto];
    this.convertPhotos([doorPhoto]);
  }

  @action public addDoorPhotos(doorPhotos: PhotoUploadedByUser[]) {
    this.photosUploadedByUser = [...this.photosUploadedByUser, ...doorPhotos];
    this.convertPhotos(doorPhotos);
  }

  @action public deletePhotoAtIndex(index: number) {
    if (index >= 0 && index < this.photosUploadedByUser.length) {
      this.photosUploadedByUser = this.photosUploadedByUser.filter((_photo, i) => index !== i);
      this.photosFromBackend = this.photosFromBackend.filter((_photo, i) => index !== i);
    }
  }
  @action public setDescriptionAtIndex(index: number, description: string) {
    if (index >= 0 && index < this.photosUploadedByUser.length) {
      this.photosUploadedByUser = this.photosUploadedByUser.map((photo, i) => ({
        ...photo,
        ...(i === index && { description }),
      }));
      this.photosFromBackend = this.photosFromBackend.map((photo, i) => ({
        ...photo,
        ...(i === index && { text: description }),
      }));
    }
  }

  private convertPhoto = async (photo: PhotoUploadedByUser) => {
    if (!photo.file) {
      return {
        image: photo.url,
        text: photo.description || '',
      };
    }
    const resizedImage = await resizeImage(photo.file);
    const image = await fileToBase64(resizedImage);
    return {
      image,
      text: photo.description || '',
    };
  }

  private convertPhotos = async (photos: PhotoUploadedByUser[]) => {
    const convertedPhotos = await Promise.all(photos.map(async (photo) => this.convertPhoto(photo)));
    this.photosFromBackend = this.photosFromBackend.concat(convertedPhotos);
  };
}