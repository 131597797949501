import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import {
  // LoadingSpinner, Button, Icon,
  matchStatus, ErrorMessage, View,
} from '@adac/core-view';
import { CaseStatusList, ChildrenProps, __ } from '@adac/core-model';
import StoreContext from '../../stores';

interface HubbleCommissionFeedbackRetryProps extends ChildrenProps{

}

export const HubbleCommissionFeedbackRetry = observer(({ children }: HubbleCommissionFeedbackRetryProps) => {
  const stores = useContext(StoreContext);
  // const [retry, setRetry] = useState(false); // TODO: this is needed if we have automated solution.
  if (matchStatus(CaseStatusList.DRIVER_SELECTED, stores.case.status)) {
    return (
      // <LoadingSpinner> // TODO: this is needed if we have automated solution.
      <>
        <ErrorMessage fullWidth border="solid 1px green">
          <View fullWidth border="solid 1px green">
            {/* {!retry && (
            <> */}
            {__('Commission accept response was registered into OpenHome, ADAC confirmation failed, Company can force retry')}
            {/* <Button style={{ marginTop: '16px', display: 'flex' }} onClick={() => setRetry(true)}><Icon style={{ marginRight: '8px' }} name="share" size="10px" />{__('Retry accepting commission')}</Button>
            </>
          )} */}
            {/* {retry && children} */}
          </View>
        </ErrorMessage>
        {children}
      </>
      // {/* </LoadingSpinner> */}
    );
  }
  return null;
});
