"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientValidationError = void 0;
var i18n_1 = require("../helpers/i18n");
var ValidationError = (function (_super) {
    __extends(ValidationError, _super);
    function ValidationError(error, fieldName, limit, zipcodeList) {
        var _this = _super.call(this, error) || this;
        _this.error = error;
        _this.fieldName = fieldName;
        _this.limit = limit;
        _this.zipcodeList = zipcodeList;
        _this.toString = function () { return "Backend Error: ".concat(_this.fieldName, ": ").concat(_this.error); };
        _this.toLocalizedString = function () { return (0, i18n_1.i18nTemplate)(_this.error, { fieldName: _this.fieldName, limit: _this.limit || '', zipcodeList: JSON.stringify(_this.zipcodeList) || '' })
            || (0, i18n_1.defaultNullableI18n)(_this.error)
            || (0, i18n_1.default)('Server responded, but an error occured'); };
        return _this;
    }
    return ValidationError;
}(Error));
var ClientValidationError = (function (_super) {
    __extends(ClientValidationError, _super);
    function ClientValidationError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toString = function () { return _this.toLocalizedString(); };
        return _this;
    }
    return ClientValidationError;
}(ValidationError));
exports.ClientValidationError = ClientValidationError;
exports.default = ValidationError;
