import React, { useContext } from 'react';

import { Security, RouterContextContainer } from '@adac/core-view';
import { CipheredToken } from '@adac/core-model';
import { LayoutContainer } from './components/layout';
import StoresContext from './stores';


const {
  REACT_APP_SND_ADMIN_PORTAL_URL: badTokenUrl,
} = process.env;

const App = () => {
  const stores = useContext(StoresContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function setCaseToken(urlToken: string, _: any, companyId?: string) {
    if (companyId && !stores.case.currentCompanyId) stores.case.setCurrentCompanyId(companyId);
    const { token } = await stores.case.fetchByToken(urlToken as CipheredToken);
    // if there was a valid cipher in the url, we don't want to overwrite it
    if (urlToken.substr(0, 1) === '+' && token) return;
    if (!token && !window.location.pathname.match(new RegExp(`^${badTokenUrl}`, 'gi'))) {
      console.log('TODO: smarter redirect than location.href=', badTokenUrl, 'from ', window.location.pathname);
      window.location.href=`${badTokenUrl}`;
    } else if (urlToken !== token && token) {
      console.log('TODO: smarter redirect than location.href=/', token, 'from ', window.location.pathname);
      window.location.href=`/${token}${companyId ? `/${companyId}` : ''}`;
    }
  }

  return (
    <Security>
      <RouterContextContainer setCaseToken={setCaseToken}>
        <LayoutContainer />
      </RouterContextContainer>
    </Security>
  );
};

export default App;
