"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findMatchingStatusComponent = exports.matchStatus = void 0;
const matchStatus = (statuses, _status) => {
    const statuses0 = [];
    return statuses0.concat(statuses).includes(_status);
};
exports.matchStatus = matchStatus;
const findMatchingStatusComponent = (routes, status) => (routes.find(route => (0, exports.matchStatus)(route.props.onStatus, status)));
exports.findMatchingStatusComponent = findMatchingStatusComponent;
exports.default = ({ children }) => (children);
