"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgAdac = (props) => (React.createElement("svg", Object.assign({ width: 69, height: 24, viewBox: "0 0 69 24" }, props),
    React.createElement("g", { fill: "#191919", fillRule: "nonzero" },
        React.createElement("path", { d: "M18.84 23.69h6.883c6.906 0 9.584-5.545 9.584-12.097C35.307 4.788 32.03.504 25.022.504h-6.181v23.185zm5.707-19.154h.475c3.778 0 4.154 4.284 4.154 6.805 0 3.024 0 8.064-4.004 8.064h-.625V4.536zM34.631 23.69h4.755l1.001-4.79h5.28l.901 4.79h5.83L47.52.503h-7.533l-5.355 23.185zM43.14 5.291h.075l1.652 9.577h-3.68l1.953-9.577zM63.585 8.568c0-1.512-.4-4.284-2.452-4.284-2.403 0-2.753 3.528-2.753 7.813 0 4.788.225 7.56 2.877 7.56 2.103 0 2.578-1.764 2.703-4.536l4.93.504c0 5.04-2.953 8.316-7.883 8.316-6.907 0-8.784-5.796-8.784-11.844C52.223 6.3 54.075 0 61.007 0c5.456 0 7.883 3.78 7.883 8.568h-5.305M.097 23.69h4.605l.976-4.79h5.13l.85 4.79h5.656L12.61.503H5.277L.097 23.689zM8.355 5.291h.05l1.627 9.577H6.454l1.901-9.577z" }))));
exports.default = SvgAdac;
