import React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import {
  NameSchema, NameFormValues, AddressForm, NameForm, InputLayout, getBillingCoordsByAddress, BillingAddressSchema, isBillingAddressReady,
} from '@adac/core-view';
import { AddressFormValues, ChildrenProps, titles } from '@adac/core-model';

export const BillingAddressNameSchema = BillingAddressSchema.concat(NameSchema);

export interface BillingAddressDataValues extends AddressFormValues, NameFormValues { }

export const emptyDefaultAddress: BillingAddressDataValues = {
  zip: '', houseNumber: '', place: '', street: '', firstName: '', lastName: '', latitude: 0, longitude: 0, confidence: 0, title: titles[2],
};

interface BillingAddressProps extends ChildrenProps {
  id?: string;
  defaultValues: BillingAddressDataValues;
  onSubmit: (values: BillingAddressDataValues) => void;
  validateOnChange?: (b: boolean) => void;
  setterOnChange?: (values: BillingAddressDataValues) => void;
}

export const BillingAddressForm = ({
  defaultValues,
  validateOnChange,
  onSubmit,
  id,
  setterOnChange,
  children,
}: BillingAddressProps) => (
  <Formik
    initialValues={defaultValues}
    validationSchema={BillingAddressNameSchema}
    onSubmit={(values: BillingAddressDataValues) => {
      onSubmit(values);
    }}
  >
    {(props: FormikProps<BillingAddressDataValues>) => {
      if (validateOnChange) {
        validateOnChange(
          props.errors
              && Object.keys(props.errors).length === 0
              && Object.getPrototypeOf(props.errors) === Object.prototype,
        );
      }
      if (setterOnChange) setterOnChange(props.values);
      return (
        <Form id={id}>
          <InputLayout>
            <AddressForm
              showLocationBtn={false}
              showEditButton={false}
              isAddressReady={isBillingAddressReady}
              getCoordsByAddress={getBillingCoordsByAddress}
            />
            <NameForm />

            {/* render children with formik props */}
            {children && React.cloneElement(children as React.ReactElement<any>, {
              disabled: props.isSubmitting || !props.isValid,
              type: 'submit',
            })}
          </InputLayout>
        </Form>
      );
    }}
  </Formik>
);
