import React, {
  useState,
  useContext,
} from 'react';
import { observer } from 'mobx-react';

import { View, BasicDevTool } from '@adac/core-view';

import StoreContext from '../../stores';

export default observer(() => {
  const stores = useContext(StoreContext);
  const [open, setOpen] = useState(false);

  return (
    <BasicDevTool
      open={open}
      setOpen={setOpen}
      stores={stores}
    >
      <h1>Form input details</h1>
      <section>
        <View height="120px" fullWidth style={{ overflow: 'scroll', maxWidth: '300px' }}>
          <small>
            {stores.case.customerFullAddressString}<br />
            {stores.case.customerCoordsString}<br />
            Selected Company ID for case: {stores.case.selectedCompanyId}<br />
            Current Company: {stores.case.currentCompanyId}
          </small>
        </View>
      </section>

    </BasicDevTool>
  );
});
