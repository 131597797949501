import styled from 'styled-components';

import { View, Text } from '@adac/core-view';

export default styled(View)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: ${props => props.theme.colors.textWithHalfOpacity};

  padding: 16px;

  z-index: 1;
`;

export const FooterTitle = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.background};  
`;

export const FooterDescription = styled(Text)`
  color: ${props => props.theme.colors.background};  
`;
