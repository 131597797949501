"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CostFormSchema = void 0;
const core_model_1 = require("@adac/core-model");
const yup = __importStar(require("yup"));
const extraDamageTypes_1 = require("../../../content/extraDamageTypes");
const prices = (0, core_model_1.getPrices)();
const profilRequiredError = (0, core_model_1.__)('please enter the additional information about profilecylinder-special');
const maxCharacterError = (0, core_model_1.__)('must be at most 30 characters');
const RequiredProfileCylinderSchema = (schema) => schema.shape({
    manufacturer: yup.string().required(profilRequiredError).min(1).max(30, maxCharacterError),
    type: yup.string().required(profilRequiredError).min(1).max(30, maxCharacterError),
    width: yup.number().typeError(profilRequiredError).required(profilRequiredError).min(1, profilRequiredError),
    height: yup.number().typeError(profilRequiredError).required(profilRequiredError).min(1, profilRequiredError),
});
const RequiredDetailSchema = (schema, name) => schema.shape({
    manufacturer: yup.string()
        .required((0, core_model_1.__)(`please enter the additional information about ${name}`))
        .min(1)
        .max(30, maxCharacterError),
    type: yup.string()
        .required((0, core_model_1.__)(`please enter the additional information about ${name}`))
        .min(1)
        .max(30, maxCharacterError),
});
const OptionalDetailSchema = (schema) => schema.shape({
    manufacturer: yup.string(),
    type: yup.string(),
    width: yup.number(),
    height: yup.number(),
});
const RadioCostSchema = (name, minPrice) => yup.object({
    count: yup.number()
        .default(0),
    value: yup.number()
        .when('count', {
        is: (count) => count > 0,
        then: schema => schema
            .typeError(`${(0, core_model_1.__)('price must be greater than')} ${minPrice} € ${(0, core_model_1.__)('amount to')}.`)
            .min(minPrice, `${(0, core_model_1.__)('price must be greater than')} ${minPrice} € ${(0, core_model_1.__)('amount to')}.`),
        otherwise: schema => schema.optional(),
    }),
    details: yup.object()
        .when('count', {
        is: (count) => count > 0,
        then: name === extraDamageTypes_1.CostItemID.PROFILE_CYLINDER_SPECIAL
            ? schema => RequiredProfileCylinderSchema(schema)
            : schema => RequiredDetailSchema(schema, name),
        otherwise: OptionalDetailSchema,
    }),
});
exports.CostFormSchema = yup.object({
    [extraDamageTypes_1.CostItemID.PROFILE_CYLINDER_SPECIAL]: RadioCostSchema(extraDamageTypes_1.CostItemID.PROFILE_CYLINDER_SPECIAL, prices.DEFECTIVE),
    [extraDamageTypes_1.CostItemID.LOCK_SPECIAL]: RadioCostSchema(extraDamageTypes_1.CostItemID.LOCK_SPECIAL, prices.LOCK),
    [extraDamageTypes_1.CostItemID.SECURITY_FITTING_SPECIAL]: RadioCostSchema(extraDamageTypes_1.CostItemID.SECURITY_FITTING_SPECIAL, prices.SECURITY_FITTING),
});
