"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPrices = exports.priceConfig = exports.calcPrice = void 0;
var __1 = require("..");
function calcPrice(value, date) {
    var _a;
    var sorted = value.sort(function (a, b) { return b.validFrom.valueOf() - a.validFrom.valueOf(); });
    var foundRule = sorted.find(function (_a) {
        var validFrom = _a.validFrom;
        return validFrom <= date;
    });
    return (_a = foundRule === null || foundRule === void 0 ? void 0 : foundRule.value) !== null && _a !== void 0 ? _a : sorted[0].value;
}
exports.calcPrice = calcPrice;
var priceChanges = {
    2019: (0, __1.parseDate)('2019-01-01'),
    2020: (0, __1.parseDate)('2020-07-01'),
    2021: (0, __1.parseDate)('2021-10-26'),
    2022: (0, __1.parseDate)('2022-01-04T00:00:00.000+01:00'),
    2024: (0, __1.parseDate)('2024-01-04T00:00:00.000+01:00'),
};
exports.priceConfig = {
    WEEKDAY: [
        { validFrom: priceChanges[2019], value: 99 },
        { validFrom: priceChanges[2020], value: 95 },
    ],
    OTHER: [
        { validFrom: priceChanges[2019], value: 179 },
        { validFrom: priceChanges[2020], value: 169 },
    ],
    DEFECTIVE: [
        { validFrom: priceChanges[2019], value: 30 },
        { validFrom: priceChanges[2020], value: 29 },
        { validFrom: priceChanges[2021], value: 30 },
    ],
    LOCK: [
        { validFrom: priceChanges[2019], value: 30 },
        { validFrom: priceChanges[2020], value: 29 },
        { validFrom: priceChanges[2021], value: 30 },
    ],
    HARDWARE: [
        { validFrom: priceChanges[2019], value: 65 },
        { validFrom: priceChanges[2020], value: 63 },
        { validFrom: priceChanges[2021], value: 65 },
        { validFrom: priceChanges[2022], value: 0 },
    ],
    INCIDENTAL_ITEMS: [
        { validFrom: priceChanges[2019], value: 0 },
        { validFrom: priceChanges[2021], value: 5 },
    ],
    SECURITY_FITTING: [
        { validFrom: priceChanges[2024], value: 65 },
    ],
    OTHER_EXTRA: [
        { validFrom: priceChanges[2022], value: 10 },
    ],
    MILLING: [
        { validFrom: priceChanges[2019], value: 0 },
        { validFrom: priceChanges[2021], value: 10 },
    ],
    SNDFEE: [
        { validFrom: priceChanges[2019], value: 20 },
        { validFrom: priceChanges[2020], value: 19 },
        { validFrom: priceChanges[2021], value: 20 },
    ],
    CANCELFEE: [
        { validFrom: priceChanges[2019], value: 0 },
        { validFrom: priceChanges[2021], value: 30 },
    ],
};
function getPrices(date) {
    if (date === void 0) { date = new Date(Date.now()); }
    return Object.entries(exports.priceConfig)
        .reduce(function (prices, _a) {
        var _b;
        var key = _a[0], configs = _a[1];
        return (__assign(__assign({}, prices), (_b = {}, _b[key] = calcPrice(configs, date), _b)));
    }, {});
}
exports.getPrices = getPrices;
