import React from 'react';
import {
  Page, SubPage, Title, Text,
  // Button,
} from '@adac/core-view';
import { ChildrenProps, __ } from '@adac/core-model';

export const CaseCompleted = ({ children }: ChildrenProps) => (
  <Page>
    <SubPage>
      <Title margin>{children}</Title>
      <Text margin>{__('Thank you for your service, we sent the invoice to the customer.')}</Text>
    </SubPage>
  </Page>
);
