"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCaseDetailsForPartner = exports.handleResponseStatus = exports.postSurvey = exports.getDriversToDispatch = exports.sndRejectOffer = exports.sndAcceptOffer = exports.searchCaseByTokenForPartner = exports.searchCaseByTokenForCustomer = void 0;
const core_model_1 = require("@adac/core-model");
const searchCaseByTokenForCustomer = (token) => __awaiter(void 0, void 0, void 0, function* () { return (0, core_model_1.request)((0, core_model_1.getApiRoutes)().customer.case(token).status); });
exports.searchCaseByTokenForCustomer = searchCaseByTokenForCustomer;
const searchCaseByTokenForPartner = (token, options) => __awaiter(void 0, void 0, void 0, function* () { return (0, core_model_1.request)((0, core_model_1.getApiRoutes)().partner.case(token).status((options === null || options === void 0 ? void 0 : options.companyId) || -1)); });
exports.searchCaseByTokenForPartner = searchCaseByTokenForPartner;
function sndAcceptOffer(token, mappedData) {
    return __awaiter(this, void 0, void 0, function* () {
        return (0, core_model_1.request)((0, core_model_1.getApiRoutes)().partner.case(token).action.timeSuggestion.accept, 'POST', {}, mappedData);
    });
}
exports.sndAcceptOffer = sndAcceptOffer;
function sndRejectOffer(token, mappedData) {
    return __awaiter(this, void 0, void 0, function* () {
        return (0, core_model_1.request)((0, core_model_1.getApiRoutes)().partner.case(token).action.timeSuggestion.reject, 'POST', {}, mappedData);
    });
}
exports.sndRejectOffer = sndRejectOffer;
const getDriversToDispatch = (token) => __awaiter(void 0, void 0, void 0, function* () {
    if (!token)
        return [];
    const drivers = yield (0, core_model_1.request)((0, core_model_1.getApiRoutes)().partner.case(token).action.getDriverList);
    return drivers;
});
exports.getDriversToDispatch = getDriversToDispatch;
const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
const postSurvey = (caseToken, data) => __awaiter(void 0, void 0, void 0, function* () {
    const url = (0, core_model_1.getApiRoutes)().customer.case(caseToken).action(core_model_1.CaseActions.SEND_SURVEY);
    const res = yield (0, core_model_1.request)(url, 'POST', headers, data);
    return res;
});
exports.postSurvey = postSurvey;
function handleResponseStatus(response, _allowForbiddenToStayLoggedin = false) {
    return __awaiter(this, void 0, void 0, function* () {
        const { status } = response;
        if (status >= 200 && status < 300) {
            return response.json();
        }
        return null;
    });
}
exports.handleResponseStatus = handleResponseStatus;
const getCaseDetailsForPartner = (token, authToken) => __awaiter(void 0, void 0, void 0, function* () {
    const url = (0, core_model_1.getApiRoutes)().companyAdmin.getCaseByToken(token);
    const newHeaders = Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${authToken}` });
    const result = yield (0, core_model_1.request)(url, 'GET', newHeaders);
    return result;
});
exports.getCaseDetailsForPartner = getCaseDetailsForPartner;
