import { useCallback, useContext } from 'react';
import { FormikHelpers } from 'formik';
import { BillingAddressProps, CaseActions, extractLocationCoordsWithConfidence } from '@adac/core-model';
import {
  extractAddressLocationNameObject, extractCustomerAddressProps, extractCustomerPersonProps, useLocation,
} from '@adac/core-view';
import { FinalReportFormValues } from '../components/pages/FinalReport';
import { BillingAddressDataValues, emptyDefaultAddress } from '../components/pages/BillingAddressForm';
import StoreContext from '../stores';
import { useIsInsuranceProduct } from './useIsInsuranceProduct';
import { extractCustomerLocationAddressProps } from './useBillingAddressFormValues';
import { useWorkflowDriverAction } from './useWorkflowDriverAction';

const extractBillingAddress = (values: FinalReportFormValues): BillingAddressDataValues => ({
  ...extractCustomerLocationAddressProps(values),
  ...extractLocationCoordsWithConfidence(values),
  firstName: values.firstName,
  lastName: values.lastName,
  title: values.title,
});

const checkBillingAddressChange = (initialValues: BillingAddressDataValues, currentValues: BillingAddressDataValues): boolean => JSON.stringify(initialValues) !== JSON.stringify(currentValues);

export const useFinalReportSubmitHandler = (billingAddressFromServer: BillingAddressProps | null | undefined) => {
  const { case: caseStore, afterOpeningDoorPhotos: afterDoorStore } = useContext(StoreContext);
  const { goTo } = useLocation();
  const isInsurance = useIsInsuranceProduct();

  const completeDoorAction = useWorkflowDriverAction(CaseActions.COMPLETE_DOOR_OPENING);

  const handleSubmit = useCallback(async (values: FinalReportFormValues, actions: FormikHelpers<FinalReportFormValues>) => {
    const isbillingAddressChanged = checkBillingAddressChange((billingAddressFromServer && extractAddressLocationNameObject(billingAddressFromServer)) || emptyDefaultAddress, extractBillingAddress(values));

    const shouldSendDifferentBillingAddress = !isInsurance && isbillingAddressChanged;

    await completeDoorAction(
      {
        photos: afterDoorStore.photosForPost,
        differentBillingAddress: shouldSendDifferentBillingAddress ? ({
          ...extractCustomerAddressProps(values),
          ...extractCustomerPersonProps(values),
        }) : undefined,
        addressConfirmed: caseStore.addressConfirmed,
        confirmedAddressDetails: !caseStore.addressConfirmed ? {
          confirmedAddressZip: caseStore.confirmedAddressZip,
          confirmedAddressCity: caseStore.confirmedAddressCity,
          confirmedAddressStreet: caseStore.confirmedAddressStreet,
          confirmedAddressNumber: caseStore.confirmedAddressNumber,
        }: undefined,
        receiveByEmail: values.receiveByEmail,
        signature: values.signatureData,
        email: values.email || caseStore.customerEmail,
        notAccepted: values.notAccepted,
        reason: values.reason,
        productType: caseStore.productType,
      },
    );
    actions.setSubmitting(false);
    if (!caseStore.hasError) goTo('/');
  }, [afterDoorStore, billingAddressFromServer, caseStore, isInsurance, completeDoorAction, goTo]);

  return handleSubmit;
};
