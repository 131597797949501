import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
  View, SubPage, Text, base64ToFile,
} from '@adac/core-view';
import { isStringData, reportSelectors, __ } from '@adac/core-model';

import DoorPhoto from './photoCarousel/DoorPhoto';
import DoorPhotoStore, { PhotoFromBackend, PhotoUploadedByUser as Photo } from '../../stores/DoorPhotoStore';

interface Props {
  doorStore: DoorPhotoStore;
  reportPhotos?: PhotoFromBackend[] | null;
}

interface PhotosLoadedType {
  [key: string]: boolean;
}

const PhotoList = observer(({ doorStore, reportPhotos = [] }: Props) => {
  const [photos, setPhotos] = useState(doorStore.photosUploadedByUser);
  const [photosLoaded, setPhotosLoaded] = useState({} as PhotosLoadedType);

  const length = reportPhotos?.length;

  useEffect(() => {
    if (!reportPhotos) return;
    if (reportPhotos.length > 0) {
      const mappedPhotos: Promise<Photo>[] = reportPhotos.map(async (photo) => {
        if (!isStringData(photo.image)) {
          return {
            url: photo.image,
            file: undefined,
            description: photo.text,
          };
        }
        const { file, url } = await base64ToFile(photo.image);
        return {
          description: photo.text,
          file,
          url,
        };
      });
      if (mappedPhotos.length > 0) {
        Promise.all(mappedPhotos).then(setPhotos);
      }
    }
  }, [reportPhotos, length]);

  const newPhotoLoaded = (url: string) => setPhotosLoaded({
    ...photosLoaded,
    [url]: true,
  });

  const isAllLoaded = photos.length>0 && photos.filter(photo => photosLoaded[photo.url]).length === photos.length;

  if (!reportPhotos) return null;

  return (
    <View className={(isAllLoaded && reportSelectors.DOOR_PHOTO_SELECTOR) || ''}>
      {
        photos.map((ph, idx) => (
          <View key={ph.url}>
            <SubPage padding="24px" background="#f3f3f4">
              <DoorPhoto
                photo={ph}
                index={idx+1}
                // onFullScreen={console.log}
                onLoaded={() => newPhotoLoaded(ph.url)}
              />
            </SubPage>
            <SubPage>
              <Text bold>
                {__('damageOnPicture')}
                {idx+1}
              </Text>
              <Text>{ph.description}</Text>
            </SubPage>
          </View>
        ))
      }
    </View>
  );
});

export default PhotoList;
