"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRoleListCompany = exports.UserRoleListBackoffice = exports.isUserRoleReadOnly = exports.hasUserRole = exports.UserRole = void 0;
var UserRole;
(function (UserRole) {
    UserRole["MANAGER"] = "manager";
    UserRole["COMPANY"] = "company";
    UserRole["DISPATCHER"] = "dispatcher";
    UserRole["API"] = "api";
    UserRole["SYS"] = "sys";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
var hasUserRole = function (role, match) {
    if (match === void 0) { match = UserRole.SYS; }
    return role === match || (typeof role === 'object' && role.length === 1 && role.includes(match));
};
exports.hasUserRole = hasUserRole;
exports.isUserRoleReadOnly = exports.hasUserRole;
exports.UserRoleListBackoffice = [
    UserRole.MANAGER,
    UserRole.API,
    UserRole.SYS,
];
exports.UserRoleListCompany = [
    UserRole.COMPANY,
    UserRole.DISPATCHER,
];
