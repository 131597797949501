"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgSms = (props) => (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24" }, props),
    React.createElement("path", { fill: "#33A3DC", d: "M20 2c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H6l-4 4 .01-18c0-1.1.89-2 1.99-2h16zM6.516 13.064c1.016 0 1.776-.528 1.776-1.496 0-1.12-.752-1.264-1.4-1.592-.504-.192-.784-.296-.784-.632 0-.32.232-.504.728-.504.456 0 .912.144 1.168.264v-.928a3.664 3.664 0 00-1.248-.224c-1.008 0-1.736.456-1.736 1.424 0 .832.488 1.192 1 1.424.752.328 1.128.376 1.128.808 0 .368-.28.56-.832.56-.48 0-.944-.168-1.304-.336v.952c.384.168.88.28 1.504.28zM9.036 13h.976l.312-2.656c.032-.304.064-.8.08-1.016h.016c.032.216.12.632.2.936L11.348 13h1.208l.744-2.744a9.83 9.83 0 00.184-.92h.016c.024.256.048.64.088.968L13.916 13h1.048L14.3 8.016h-1.328l-.76 2.944c-.072.296-.152.712-.208 1.024h-.008a11.218 11.218 0 00-.208-1.032l-.768-2.936H9.684L9.036 13zm8.256.064c1.016 0 1.776-.528 1.776-1.496 0-1.12-.752-1.264-1.4-1.592-.504-.192-.784-.296-.784-.632 0-.32.232-.504.728-.504.456 0 .912.144 1.168.264v-.928a3.664 3.664 0 00-1.248-.224c-1.008 0-1.736.456-1.736 1.424 0 .832.488 1.192 1 1.424.752.328 1.128.376 1.128.808 0 .368-.28.56-.832.56-.48 0-.944-.168-1.304-.336v.952c.384.168.88.28 1.504.28z", fillRule: "evenodd" })));
exports.default = SvgSms;
