"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Driver = (props) => (React.createElement("svg", Object.assign({ height: 800, width: 800, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 299.044 299.044", xmlSpace: "preserve" }, props),
    React.createElement("path", { d: "M291.037 52.054h-19.889V16.965a6.709 6.709 0 0 0-6.709-6.709H136.054a6.709 6.709 0 0 0-6.709 6.709v35.088h-11.471c10.466 7.699 16.76 19.859 16.825 32.915l.414 82.514c.682.096.59.073 4.298.073v13.954c0 8.795 7.13 15.925 15.925 15.925 8.795 0 15.925-7.13 15.925-15.925v-13.954h57.968v13.954c0 8.795 7.13 15.925 15.925 15.925s15.925-7.13 15.925-15.925v-13.954h3.359a6.709 6.709 0 0 0 6.709-6.709v-47.03h19.889a6.709 6.709 0 0 0 6.709-6.709V58.763a6.707 6.707 0 0 0-6.708-6.709zm-144.366-4.993a4.011 4.011 0 0 1 4.011-4.011h99.129a4.011 4.011 0 0 1 4.011 4.011v45.523a4.011 4.011 0 0 1-4.011 4.011h-99.129a4.011 4.011 0 0 1-4.011-4.011V47.061zm10.675 108.305c-8.111 0-14.686-6.575-14.686-14.687 0-8.111 6.575-14.687 14.686-14.687s14.687 6.575 14.687 14.687c0 8.111-6.575 14.687-14.687 14.687zm85.8 0c-8.111 0-14.686-6.575-14.686-14.687 0-8.111 6.575-14.687 14.686-14.687s14.687 6.575 14.687 14.687c0 8.111-6.575 14.687-14.687 14.687zM61.818 49.226c13.554 0 24.613-11.003 24.613-24.613C86.431 11.019 75.411 0 61.818 0S37.205 11.019 37.205 24.613c0 13.644 11.092 24.613 24.613 24.613zM98.088 57.437c-3.156-.515 1.046-.365-68.249-.365-15.419 0-28.026 12.544-28.104 27.963l-.438 87.247c-.033 6.559 5.257 11.903 11.816 11.935h.061c6.531 0 11.842-5.278 11.875-11.816l.438-87.248v-.001a2.293 2.293 0 0 1 4.586.013l.005 199.627c0 7.871 6.38 14.251 14.251 14.251s14.251-6.38 14.251-14.251v-113.91h6.154v113.911c0 7.871 6.38 14.251 14.251 14.251s14.251-6.38 14.251-14.251c0-195.493-.253-74.027-.259-199.625a2.486 2.486 0 0 1 4.97-.014l.438 87.248c.033 6.539 5.344 11.816 11.875 11.816h.061c6.559-.033 11.849-5.377 11.816-11.935l-.438-87.248c-.061-13.683-10.098-25.392-23.611-27.598z" })));
exports.default = Driver;
