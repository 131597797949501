"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
class CompleteNetworkingStore {
    constructor(networkService) {
        this.item = null;
        this.items = [];
        this.postedItem = null;
        this.deletedItem = null;
        this.hasPutItem = null;
        this.errors = [];
        this.loading = false;
        this.loadingGetAll = false;
        this.loadingGet = false;
        this.loadingPost = false;
        this.loadingDelete = false;
        this.loadingPut = false;
        this.mapError = (error) => {
            this.errors = [error];
        };
        this.networkService = networkService;
    }
    getAllItems() {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.loadingGetAll = true;
            this.errors = [];
            try {
                this.items = yield this.networkService.getAll();
                this.loading = false;
                this.loadingGetAll = false;
            }
            catch (error) {
                this.mapError(error);
            }
        });
    }
    getItem(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.item = yield this.networkService.get(id);
                this.loading = false;
            }
            catch (error) {
                this.mapError(error);
            }
        });
    }
    postItem(item) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.postedItem = yield this.networkService.post(item);
                this.loading = false;
            }
            catch (error) {
                this.mapError(error);
            }
        });
    }
    deleteItem(item) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.deletedItem = yield this.networkService.delete(item.id);
                this.loading = false;
            }
            catch (error) {
                this.mapError(error);
            }
        });
    }
    putItem(item) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.errors = [];
            try {
                this.hasPutItem = yield this.networkService.put(item.id);
                this.loading = false;
            }
            catch (error) {
                this.mapError(error);
            }
        });
    }
}
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "item", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "items", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "postedItem", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "deletedItem", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "hasPutItem", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "errors", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "loading", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "loadingGetAll", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "loadingGet", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "loadingPost", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "loadingDelete", void 0);
__decorate([
    mobx_1.observable
], CompleteNetworkingStore.prototype, "loadingPut", void 0);
__decorate([
    mobx_1.action
], CompleteNetworkingStore.prototype, "getAllItems", null);
__decorate([
    mobx_1.action
], CompleteNetworkingStore.prototype, "getItem", null);
__decorate([
    mobx_1.action
], CompleteNetworkingStore.prototype, "postItem", null);
__decorate([
    mobx_1.action
], CompleteNetworkingStore.prototype, "deleteItem", null);
__decorate([
    mobx_1.action
], CompleteNetworkingStore.prototype, "putItem", null);
exports.default = CompleteNetworkingStore;
