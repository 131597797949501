"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var case_event_data_1 = require("../adac-states/case-event-data");
var case_events_1 = require("../adac-states/case-events");
var string_helpers_1 = require("../helpers/string-helpers");
var Company_1 = require("../types/Company");
var _a = process.env, INVOICE_UPLOAD_MISSING_AGE_IN_DAYS = _a.INVOICE_UPLOAD_MISSING_AGE_IN_DAYS, REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE = _a.REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE, INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES = _a.INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES, invoiceJobSchedule = _a.INVOICE_JOB_SCHEDULE, invoiceUploadFiletypes = _a.INVOICE_UPLOAD_FILETYPES, invoiceUploadReminderJobSchedule = _a.INVOICE_UPLOAD_REMINDER_JOB_SCHEDULE;
var invoiceUploadMissingAgeInDays = parseInt(INVOICE_UPLOAD_MISSING_AGE_IN_DAYS !== null && INVOICE_UPLOAD_MISSING_AGE_IN_DAYS !== void 0 ? INVOICE_UPLOAD_MISSING_AGE_IN_DAYS : '', 10);
var invoiceUpdateCorrectThresholdMinutes = parseInt(INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES !== null && INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES !== void 0 ? INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES : '', 10);
var invoiceUploadMandatoryBaseDate = REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE ? (0, string_helpers_1.parseDate)(REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE) : (0, string_helpers_1.parseDate)('2022-05-18T09:00:00+02:00');
var isActive = function (compare) {
    if (compare === void 0) { compare = "".concat(new Date(Date.now())); }
    return invoiceUploadMandatoryBaseDate.getTime() <= (0, string_helpers_1.parseDate)(compare).getTime();
};
exports.default = {
    INVOICE_UPLOAD_MISSING_AGE_IN_DAYS: Number.isNaN(invoiceUploadMissingAgeInDays) ? 14 : invoiceUploadMissingAgeInDays,
    INVOICE_UPDATE_CORRECT_THRESHOLD_MINUTES: Number.isNaN(invoiceUpdateCorrectThresholdMinutes) ? 30 : invoiceUpdateCorrectThresholdMinutes,
    REACT_APP_INVOICE_UPLOAD_MODULE_LAUNCH_DATE: invoiceUploadMandatoryBaseDate,
    INVOICE_JOB_SCHEDULE: invoiceJobSchedule || '0 */5 * * * *',
    INVOICE_UPLOAD_REMINDER_JOB_SCHEDULE: invoiceUploadReminderJobSchedule || '0 0 16 * * 5',
    INVOICE_UPLOAD_FILETYPES: invoiceUploadFiletypes || 'application/pdf, image/jpg , image/jpeg',
    isActive: isActive,
    isInvoiceUploadAllowed: function (_a, companyType) {
        var event = _a.event, data = _a.data, caseRecord = _a.case;
        return (event === case_events_1.CaseStatusList.CASE_COMPLETED
            && caseRecord && isActive("".concat(caseRecord.createdAt))
            && companyType === Company_1.CompanyTypes.EXT
            && (0, case_event_data_1.isInvoiceUploadAllowed)(data));
    },
};
