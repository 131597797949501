"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasError = exports.isMessageStatusDisabled = exports.StatusMapping = void 0;
exports.StatusMapping = {
    submitted: 'queued',
    delivered: 'delivered',
    rejected: 'failed',
    undeliverable: 'undelivered',
};
function isMessageStatusFailed(status) {
    return ['failed', 'undelivered', 'error'].includes(status);
}
function isMessageStatusDisabled(status) {
    return ['disabled'].includes(status);
}
exports.isMessageStatusDisabled = isMessageStatusDisabled;
function isMessageError(message) {
    var errorCode = message.errorCode;
    return errorCode !== undefined && errorCode !== 0 && errorCode !== 200;
}
function hasError(message) {
    return !message || isMessageError(message) || isMessageStatusFailed(message === null || message === void 0 ? void 0 : message.status) || isMessageStatusDisabled(message === null || message === void 0 ? void 0 : message.status);
}
exports.hasError = hasError;
