"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgSmiley2 = (props) => (React.createElement("svg", Object.assign({ width: 40, height: 40, viewBox: "0 0 40 40" }, props),
    React.createElement("g", { fill: "none", fillRule: "evenodd" },
        React.createElement("circle", { cx: 20, cy: 20, r: 20, fill: "#FC0" }),
        React.createElement("path", { fill: "#191919", d: "M14.762 20.952c-1.315 0-2.381-1.705-2.381-3.81 0-2.103 1.066-3.809 2.38-3.809 1.316 0 2.382 1.706 2.382 3.81s-1.066 3.81-2.381 3.81zm10.476 0c-1.315 0-2.38-1.705-2.38-3.81 0-2.103 1.065-3.809 2.38-3.809s2.381 1.706 2.381 3.81-1.066 3.81-2.38 3.81zM13.19 25.235l14.012 2.807a1 1 0 01-.392 1.96l-14.012-2.806a1 1 0 01.392-1.96z" }))));
exports.default = SvgSmiley2;
