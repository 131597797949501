"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initSentry = exports.beforeSend = void 0;
const Sentry = __importStar(require("@sentry/browser"));
const core_model_1 = require("@adac/core-model");
const BREADCRUMB_LIMIT = 64;
const filterBreadCrumbs = (crumb) => {
    if (crumb.data) {
        const newCrumb = crumb;
        const newEntries = Object.entries(newCrumb.data).map((entry) => {
            const key = entry[0];
            const value = entry[1];
            if (typeof value === 'string' && value.length > BREADCRUMB_LIMIT) {
                return [key, `${value.substring(0, BREADCRUMB_LIMIT)}...`];
            }
            return entry;
        });
        newCrumb.data = Object.fromEntries(newEntries);
        return newCrumb;
    }
    return crumb;
};
const beforeSend = (event) => {
    const { breadcrumbs } = event;
    const newBreadcrumbs = breadcrumbs.filter(filterBreadCrumbs);
    const newEvent = event;
    newEvent.breadcrumbs = newBreadcrumbs;
    return newEvent;
};
exports.beforeSend = beforeSend;
const initSentry = (envVarSuffix) => {
    const sentry = core_model_1.config.initSentry(envVarSuffix);
    if (sentry.sentryUrl) {
        Sentry.init({
            dsn: sentry.sentryUrl,
            beforeSend: exports.beforeSend,
        });
    }
};
exports.initSentry = initSentry;
