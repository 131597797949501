"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const SvgCamera = (props) => (React.createElement("svg", Object.assign({ width: 20, height: 20, viewBox: "0 0 20 20" }, props),
    React.createElement("path", { fill: "#191919", d: "M4.764 5l.703-2.595a1 1 0 01.965-.738h7.136a1 1 0 01.965.738L15.236 5H19a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V6a1 1 0 011-1h3.764zM10 15.833a5 5 0 100-10 5 5 0 000 10zm0-1.666A3.333 3.333 0 1110 7.5a3.333 3.333 0 010 6.667z", fillRule: "evenodd" })));
exports.default = SvgCamera;
