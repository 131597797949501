"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNewUUID = exports.stripSpecialCharacters = exports.bufferToString = exports.base64ImageRegex = exports.cronPattern = exports.urlPattern = exports.hostnameArrayPattern = exports.logLevelPattern = exports.zipListPattern = exports.makeArrayPattern = exports.isoDatePattern = exports.removeNonEnglishCharacters = exports.replaceVowelsToEnglishOnes = exports.adjustFuzzyChars = exports.stripHTML = exports.parseDate = exports.formatDate = exports.formatLocaleTimestamp = exports.camelize = exports.ucfirst = exports.lcfirst = exports.capitalize = void 0;
var uuid_1 = require("uuid");
function capitalize(str) {
    if (!str)
        return '';
    return str[0].toUpperCase() + str.slice(1);
}
exports.capitalize = capitalize;
var lcfirst = function (string) { return String(string).charAt(0).toLowerCase() + String(string).slice(1); };
exports.lcfirst = lcfirst;
var ucfirst = function (string) { return String(string).charAt(0).toUpperCase() + String(string).slice(1); };
exports.ucfirst = ucfirst;
var camelize = function (string, change1st) {
    if (change1st === void 0) { change1st = false; }
    return (String(string)
        .replace(/[+./_]/, '-')
        .split('-')
        .map(function (part, index) { return ((index || change1st) && (0, exports.ucfirst)(part)) || part; })
        .join(''));
};
exports.camelize = camelize;
var formatLocaleTimestamp = function (dateInput) { return (dateInput ? "".concat(new Date(dateInput).toLocaleDateString('DE'), " ").concat(new Date(dateInput).toTimeString().slice(0, 5), " Uhr") : '-'); };
exports.formatLocaleTimestamp = formatLocaleTimestamp;
var formatDate = function (value) { var _a, _b; return ((_a = new Date("".concat(value))) === null || _a === void 0 ? void 0 : _a.toLocaleDateString('DE')) || ((_b = "".concat(value)) === null || _b === void 0 ? void 0 : _b.substr(0, 10)) || ''; };
exports.formatDate = formatDate;
var parseDate = function (date) {
    var parsed = Date.parse(date);
    if (!isNaN(parsed)) {
        return new Date(parsed);
    }
    var arr = date.split(/[- :]/);
    var constructed = new Date(+arr[0], +arr[1] - 1, +arr[2], +arr[3], +arr[4], +arr[5]).valueOf();
    if (!isNaN(constructed)) {
        return new Date(constructed);
    }
    throw new Error("Date cannot be parsed! ".concat(date));
};
exports.parseDate = parseDate;
var stripTag = function (text, tag) {
    var re = new RegExp("<".concat(tag, "[^>]*>.*</").concat(tag, "[^>]*>"), 'gi');
    return text.replace(re, '');
};
var stripHTML = function (text, eliminateTags) {
    if (eliminateTags === void 0) { eliminateTags = ['h', 'footer']; }
    return eliminateTags
        .reduce(function (t, tag) { return stripTag(t, tag); }, (text || '').replace(/\n/gi, '[br]'))
        .replace(/\[br\]/gi, '<br>')
        .replace(/<br[^>]*>/gi, '\n')
        .replace(/<[^>]+>/gi, '');
};
exports.stripHTML = stripHTML;
var adjustFuzzyChars = function (str) { return str
    .replace(/[eéèë]/gi, 'e')
    .replace(/[aáâàä]/gi, 'a')
    .replace(/[òôoöóőõ]/gi, 'o')
    .replace(/[iíî]/gi, 'i')
    .replace(/[uûüűũ]/gi, 'u')
    .replace(/[^A-Za-z0-9]/gi, '\\W')
    .replace(/[e]/gi, '[eéèë]')
    .replace(/[a]/gi, '[aáâàä]')
    .replace(/[o]/gi, '[òôoöóőõ]')
    .replace(/[i]/gi, '[iíî]')
    .replace(/[u]/gi, '[uûüűũ]'); };
exports.adjustFuzzyChars = adjustFuzzyChars;
var replaceVowelsToEnglishOnes = function (str) { return str
    .replace(/[EÉÈË]/g, 'E')
    .replace(/[AÁÂÀÄ]/g, 'A')
    .replace(/[ÒÔOÖÓŐÕ]/g, 'O')
    .replace(/[IÍÎ]/g, 'I')
    .replace(/[UÚÛÜŰŨ]/g, 'U')
    .replace(/[eéèë]/g, 'e')
    .replace(/[aáâàä]/g, 'a')
    .replace(/[òôoöóőõ]/g, 'o')
    .replace(/[iíî]/g, 'i')
    .replace(/[uúûüűũ]/g, 'u'); };
exports.replaceVowelsToEnglishOnes = replaceVowelsToEnglishOnes;
var removeNonEnglishCharacters = function (str, keep) {
    if (keep === void 0) { keep = ''; }
    return str.replace(new RegExp("[^a-zA-Z._0-9".concat(keep, "\\-]"), 'g'), '');
};
exports.removeNonEnglishCharacters = removeNonEnglishCharacters;
exports.isoDatePattern = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/;
var makeArrayPattern = function (items, options) {
    var s = (options === null || options === void 0 ? void 0 : options.separator) || '\\s*,\\s*';
    var ib = (options === null || options === void 0 ? void 0 : options.itemBoundaries) || ['"', '"'];
    var ab = (options === null || options === void 0 ? void 0 : options.arrayBoundaries) || ['\\[', '\\]'];
    return new RegExp("^".concat(ab[0], "(").concat(ib[0]).concat(items).concat(ib[1], ")(").concat(s).concat(ib[0]).concat(items).concat(ib[1], ")*").concat(ab[1], "$"), 'i');
};
exports.makeArrayPattern = makeArrayPattern;
exports.zipListPattern = (0, exports.makeArrayPattern)('\\d{4,5}', { itemBoundaries: ['', ''], arrayBoundaries: ['', ''] });
exports.logLevelPattern = (0, exports.makeArrayPattern)('(error|warn|info|debug|verbose|silly)');
exports.hostnameArrayPattern = (0, exports.makeArrayPattern)('[\\w-]+(\\.[\\w-]+)+');
exports.urlPattern = /^https?:\/\/[\w-@]+(\.[\w-]+)+[/\w-.]+$/i;
exports.cronPattern = /^(((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7}$/i;
exports.base64ImageRegex = /^data:image\/(png|jpg|jpeg|gif|bmp|svg)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/;
var bufferToString = function (buffer) { return (Buffer.isBuffer(buffer) ? Buffer.from(buffer).toString() : buffer); };
exports.bufferToString = bufferToString;
var stripSpecialCharacters = function (input) { return (0, exports.removeNonEnglishCharacters)((0, exports.replaceVowelsToEnglishOnes)(input), ' !?,'); };
exports.stripSpecialCharacters = stripSpecialCharacters;
var getNewUUID = function () { return (0, uuid_1.v4)(); };
exports.getNewUUID = getNewUUID;
